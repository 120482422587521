<template>
  <div class="loading_block_outer" v-if="loading">
    <SingleLoading  />
  </div>
  
  
  <div v-else class="pb-80">
    <div class="error-text pt-2 text-center" v-if="apiError">
      Insurance Gateway not responding, please try again
    </div>
    <div class="position-relative" v-if="selectedPlan">
      <div class="inner-page-bg pb-4">
        <div
          class="
            breakcrum-section
            d-flex
            justify-content-between
            align-items-center
          "
        >
          <ul class="d-flex justify-content-between align-items-center">
            <li><router-link to="/">Home</router-link> <i class="icon-keyboard_arrow_right"></i></li>
            <li><router-link to="/policy-quote">Term Insurance</router-link> <i class="icon-keyboard_arrow_right"></i></li>
            <li class="active">Term Plan Quote</li>
          </ul>
          <a target="_blank" :href="selectedPlan.supportingData.Messages.brochureLink">
            <img src="~@/assets/images/file.png" alt="" class="mr-1" />
            Know Your Plan In 2 Minutes
          </a>
        </div>
        <div
          class="
            plan-details-section
            d-flex
            align-items-center
            justify-content-between
          "
        >
          <!-- <router-link to="/policy-quote"> -->
          <span @click="goBack" class="cursor-pointer" >
            <img src="~@/assets/images/left-arrow.png" alt=""/>
          </span>
          <!-- </router-link> -->
          <div class="">
            <span class="d-inline-block align-middle brand-img">
              <img
                :src="
                  getInsurerImgs(
                    selectedPlan['insuranceAndProducts']['insuranceCompanyCode']
                  )
                "
                alt=""
              />
            </span>
            <span class="d-inline-block align-middle brand-text">
              {{ selectedPlan.insuranceAndProducts.productName }}
            </span>
          </div>
          <div class="feature-drpdwn-wrap d-flex">
            <div class="feature-drpdwn-wrap d-flex flex-column">
              <select
                name="lifecover"
                id="lifecover"
                @change="onChangeAmt($event)"
                v-model="selectedPlan.individualDetails[0].sumAssured"
              >
                <option
                  v-for="(value, ind) in qAmt"
                  :key="ind"
                  v-bind:value="value"
                >
                  &#8377; <span> {{ convertInLakh(value) }} </span>
                </option>
              </select>
              <span>Policy Cover</span>
            </div>
            <div class="feature-drpdwn-wrap d-flex flex-column">
              <select
                name="age"
                id="age"
                v-model="selectedAge"
                @change="getCoverTillAge($event)"
              >
                <option
                  v-for="(val, ind) in coverForArr"
                  :key="ind"
                  :value="val"
                >
                  {{ val }}
                </option>
              </select>
              <span>Cover Till Age</span>
            </div>
            <div class="feature-drpdwn-wrap d-flex flex-column pr-4">
              <select
                name="age"
                id="age"
                @change="getPremiumPaymentTerm($event)"
                v-model="premiumPaymentTerm"
              >
                <option
                  v-for="(val, ind) in premiumPaymentTermArr"
                  :key="ind"
                  :value="val"
                >
                  {{ val }} yrs
                </option>
              </select>
              <span>Pay For</span>
            </div>
            <div class="feature-drpdwn-wrap d-flex flex-column">
              <select v-if="premiumPaymentFrequency == 'S'">
                <option value="S">Single Pay</option>
              </select>
              <select v-else
                name="age"
                id="age"
                @change="getPpf($event)"
                v-model="premiumPaymentFrequency"
              >
                <option v-for="(value, ind) in payModeList"
                  :key="ind"
                  v-bind:value="value">{{getPPF(value)}}</option>
              </select>
              <span>Mode of Payment</span>
            </div>
          </div>
          <div class="policy-price-amt">
            <b
              >&#8377;
              {{
                this.currency(selectedPlan.individualDetails[0].premiumDetails[0].totalPremiumValue)
              }}</b
            >/{{
              getPayMode(
                selectedPlan.individualDetails[0].premiumDetails[0].mode
              )
            }}
          </div>
          <div>
            <button class="buy-now-btn">
              Buy now <i class="icon-keyboard_arrow_right"></i>
            </button>
          </div>
        </div>
        <div class="mob-feature-drpdwn-wrap">
          <div class="d-flex">
            <div class="feature-drpdwn-wrap d-flex flex-column">
              <select
                name="lifecover"
                id="lifecover"
                @change="onChangeAmt($event)"
                v-model="selectedPlan.individualDetails[0].sumAssured"
              >
                <option
                  v-for="(value, ind) in qAmt"
                  :key="ind"
                  v-bind:value="value"
                >
                  &#8377; <span> {{ convertInLakh(value) }} </span>
                </option>
              </select>
              <span>Policy Cover</span>
            </div>
            <div class="feature-drpdwn-wrap d-flex flex-column">
              <select
                name="age"
                id="age"
                v-model="selectedAge"
                @change="getCoverTillAge($event)"
              >
                <option
                  v-for="(val, ind) in coverForArr"
                  :key="ind"
                  :value="val"
                >
                  {{ val }}
                </option>
              </select>
              <span>Cover Till Age</span>
            </div>
            <div class="feature-drpdwn-wrap d-flex flex-column">
              <select
                name="age"
                id="age"
                @change="getPremiumPaymentTerm($event)"
                v-model="selectedPlan.productDetails.premiumPaymentTerm"
              >
                <option
                  v-for="(val, ind) in premiumPaymentTermArr"
                  :key="ind"
                  :value="val"
                >
                  {{ val }} yrs
                </option>
              </select>
              <span>Pay For</span>
            </div>
            <div class="feature-drpdwn-wrap d-flex flex-column">
              <select
                name="age"
                id="age"
                @change="getPpf($event)"
                v-model="premiumPaymentFrequency"
              >
                <option v-for="(value, ind) in payModeList"
                  :key="ind"
                  v-bind:value="value">{{getPPF(value)}}</option>
              </select>
              <span>Mode of Payment</span>
            </div>
          </div>
        </div>
        <div class="row m-0 mt-4">
          <div class="tab-section col-md-8 pl-0">
            <div class="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
              <a
                :class="'nav-link ' + isBlock1Active"
                id="nav-about-plan"
                data-toggle="tab"
                href="#about-plan"
                role="tab"
                aria-controls="about-plan"
                :aria-selected="isBlock1Selected"
                @click="tab1Clicked"
              >
                <span class="inactive-icon"
                  ><img src="~@/assets/images/about_plan_inactive.svg" alt=""
                /></span>
                <span class="active-icon"
                  ><img src="~@/assets/images/about_plan.svg" alt="" /></span
                >{{ productData.about_product?.sectionName }}
              </a>
              <a
                :class="'nav-link ' + isBlock2Active"
                id="nav-upgrade-plan"
                data-toggle="tab"
                href="#upgrade-plan"
                role="tab"
                aria-controls="upgrade-plan"
                :aria-selected="isBlock2Selected"
                @click="tab2Clicked"
              >
                <span class="inactive-icon"
                  ><img src="~@/assets/images/upgrade-plan-inactive.svg" alt=""
                /></span>
                <span class="active-icon"
                  ><img src="~@/assets/images/upgrade-plan.svg" alt="" /></span
                >Upgrade Plan
              </a>
              <a
                :class="'nav-link ' + isBlock3Active"
                id="nav-riders"
                data-toggle="tab"
                href="#riders"
                role="tab"
                aria-controls="riders"
                :aria-selected="isBlock3Selected"
                @click="tab3Clicked"
              >
                <span class="inactive-icon"
                  ><img src="~@/assets/images/riders_inactive.svg" alt=""
                /></span>
                <span class="active-icon"
                  ><img src="~@/assets/images/riders.svg" alt="" /></span
                >Addons/Riders
              </a>
            </div>
            <div class="tab-content py-3 px-3" id="nav-tabContent">
              <div
                :class="'tab-pane fade ' + tabContent1Show"
                id="about-plan"
                role="tabpanel"
                aria-labelledby="nav-about-plan"
              >
                <ul v-if="productData.about_product">
                  <li v-for="(data, index) in productData.about_product.subSection" :key="index">
                    <h4> {{data.header}} </h4>
                    <div class="tab-text" v-for="(data2, index2) in data.data" :key="index2">
                      {{ data2.data }}
                    </div>
                  </li>
                </ul>
              </div>
              <div
                :class="'tab-pane fade ' + tabContent2Show"
                id="upgrade-plan"
                role="tabpanel"
                aria-labelledby="nav-upgrade-plan"
              >
                <div class="benefit-grid">
                  <div class="benefit-section rounded mb-4">
                    <div class="benefit-section-text">
                      <h5>Benefit Payout To Nominee</h5>
                      <p>In My Absence My Family Will Get</p>
                      <div >
                        <div class="benefit-grid">
                          <label
                            v-for="(value, ind) in optionSelectedArr"
                            :key="ind"
                          >
                            <input
                              type="radio"
                              name="bpn"
                              v-bind:value="value"
                              :checked="
                                value ==
                                selectedPlan.productDetails.DBPoption
                                  .optionSelected
                              "
                              v-on:click="getFwr"
                            />
                            <span class="border justify-content-center">
                              {{ getOptionSelectedVal(value) }}
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="
                      benefit-section
                      rounded
                      d-flex
                      justify-content-between
                      flex-column
                      position-relative
                      mb-4
                    "
                  >
                    <div class="benefit-section-text position-relative">
                      <h5>Pay Premium</h5>
                      <p>
                        Pay Premiums For A Few Years And Stay Covered For The
                        Entire Policy Duration
                      </p>
                      <div>
                        <div class="benefit-grid">
                          <label v-for="(value, ind) in ppoArr" :key="ind">
                            <input
                              type="radio"
                              name="pp"
                              v-bind:value="value"
                              :checked="
                                value ==
                                selectedPlan.productDetails.premiumPaymentOption
                              "
                              v-on:click="getPayPremium"
                            />
                            <span class="border justify-content-center">
                              {{ getPpoVal(value) }}
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- <div
                    class="
                      benefit-section
                      rounded
                      d-flex
                      justify-content-between
                      position-relative
                      flex-column
                      mb-4
                    "
                  >
                    <div class="benefit-section-text position-relative">
                      <h5>Return of Premium - Money Back</h5>
                      <p>
                        Get all your premium back in case nothing happens to
                        you.
                      </p>
                    </div>
                    <div>
                      <button
                        class="add-plcy-btn"
                        @click="addReturnOfPremium()"
                        v-if="returnOfPremiumPercentage == '0'"
                      >
                        Add
                      </button>
                      <button
                        class="remove-plcy-btn"
                        @click="removeReturnOfPremium()"
                        v-if="returnOfPremiumPercentage != '0'"
                      >
                        Remove
                      </button>
                    </div>
                  </div> -->
                  <div
                    class="
                      benefit-section
                      rounded
                      d-flex
                      justify-content-between
                      flex-column
                      position-relative
                      mb-4
                    "
                    v-if="
                      selectedPlan.supportingData.Messages.isWholeLifePlan ==
                      '1'
                    "
                  >
                    <div class="benefit-section-text position-relative" >
                      <h5>Whole Life</h5>
                      <div class="position-absolute">
                        <button
                          class="add-plcy-btn"
                          @click="addWholeLife()"
                          v-if="isWholeLifeSelected == '0'"
                        >
                          Add
                        </button>
                        <button
                          class="remove-plcy-btn"
                          @click="removeWholeLife()"
                          v-if="isWholeLifeSelected != '0'"
                        >
                          Remove
                        </button>
                      </div>
                      <p>
                        Choose To Get Cover Till 99 Years And Leave A Legacy For
                        Your Family.
                      </p>
                    </div>
                    
                  </div>
                </div>
              </div>
              <div
                :class="'tab-pane fade ' + tabContent3Show"
                id="riders"
                role="tabpanel"
                aria-labelledby="nav-riders"
              >
                <div class="d-flex flex-column">
                  <div class="benefit-grid" v-if="countAddonsRiders(this.addOnsList, this.riderList)">
                    <!-- Addon Section -->
                    <div class="flex-fill benefit-section rounded mb-4" v-for="(dt2, ind2) in addOnsList" :key="ind2">
                      <div class="benefit-section-text">
                        <h5>
                          {{dt2.optionName}} 
                          <span v-if="dt2.addOnSumAssured != null && checkAddOnSelection(dt2.optionSelected, this.selectedPlan.productDetails.AddOnSection.addOnSelected)"> 
                           ₹
                           {{ convertInLakh(getAddOnSelectionSA(dt2.optionSelected, this.selectedPlan.productDetails.AddOnSection.addOnSelected)) }}
                          </span>
                          <a href="#" v-if="dt2.addOnSumAssured != null && checkAddOnSelection(dt2.optionSelected, this.selectedPlan.productDetails.AddOnSection.addOnSelected)" @click="getSumAssuredValue(ind2, dt2.optionSelected, this.selectedPlan.productDetails.AddOnSection.addOnSelected)" data-toggle="modal" data-target="#editsumassured">
                            <img
                              src="~@/assets/images/edit-profile.png"
                              alt=""
                            />
                          </a>
                        </h5>
                        <div class="position-absolute" v-if="dt2.premiumIncludedFlag == 'No'">

                          <button class="remove-plcy-btn" v-if="checkAddOnSelection(dt2.optionSelected, this.selectedPlan.productDetails.AddOnSection.addOnSelected)" @click="removeAddOns(dt2.optionSelected)">
                            Remove
                          </button>
                          <button class="add-plcy-btn" v-if="!checkAddOnSelection(dt2.optionSelected, this.selectedPlan.productDetails.AddOnSection.addOnSelected)" @click="addAddOns(dt2.optionSelected)">
                            Add
                          </button>
                        </div>
                        <p>
                          {{ dt2.optionDesc }}
                        </p>
                      </div>
                      <div
                        class="price border-top d-flex justify-content-between"
                      >
                        <b>Price</b>
                        <span v-if="checkAddOnSelection(dt2.optionSelected, this.selectedPlan.productDetails.AddOnSection.addOnSelected) && dt2.premiumIncludedFlag == 'No'"> <b>₹ {{ this.currency(this.getAddOnPremium(dt2.optionSelected, this.selectedPlan.productDetails.AddOnSection.addOnSelected))}} </b>/ {{ getPPF(dt2.premiumDetails[0].mode) }}</span>
                        <span v-else-if="dt2.premiumIncludedFlag == 'No'"> <b> {{ dt2.temp_price }} </b></span>
                        <span v-if="dt2.premiumIncludedFlag == 'Yes'"> <b> Free </b></span>
                      </div>
                      
                    </div>

                    <!-- Rider Section -->
                    <div class="flex-fill benefit-section rounded mb-4" v-for="(dt3, ind3) in riderList" :key="ind3">
                      <div class="benefit-section-text">
                        <h5>
                          {{dt3.riderName}}
                          <span v-if="checkRiderSelection(dt3.ridercode, this.selectedPlan.individualDetails[0].rider)"> 
                            ₹ {{ convertInLakh(getRiderSelectionSA(dt3.ridercode, this.selectedPlan.individualDetails[0].rider)) }}
                          </span>
                          <!-- <span v-else>
                            ₹ {{ convertInLakh(dt3.riderSA) }}
                          </span> -->
                        </h5>
                        <div class="position-absolute">
                          <button class="add-plcy-btn" v-if="checkRiderSelection(dt3.ridercode, this.selectedPlan.individualDetails[0].rider) != true" @click="addRider(dt3.ridercode)">
                            Add
                          </button>
                          <button v-else class="remove-plcy-btn" @click="removeRider(dt3.ridercode)">
                            Remove
                          </button>
                          <a href="#" @click="getRiderSumAssuredValue(dt3.ridercode, dt3.riderName, this.selectedPlan.individualDetails[0].rider)" data-toggle="modal" data-target="#editridersumassured" v-if="checkRiderSelection(dt3.ridercode, this.selectedPlan.individualDetails[0].rider)">
                            <img
                              src="~@/assets/images/edit-profile.png"
                              alt=""
                            />
                          </a>
                        </div>
                        <p>
                          {{ dt3.riderFeatures }}
                        </p>
                      </div>
                      <div
                        class="price border-top d-flex justify-content-between"
                      >
                        <b>Price</b>
                        <span v-if="checkRiderSelection(dt3.ridercode, this.selectedPlan.individualDetails[0].rider)"> <b> ₹ {{ this.currency(getRiderSelectionPremium(dt3.ridercode, this.selectedPlan.individualDetails[0].rider)) }} </b></span>
                        <!-- <span v-else> <b> ₹ {{ this.currency(getRiderPriceByPPF(dt3.premiumDetails)) }} </b></span> -->
                        <span v-else> <b> {{dt3.temp_price}} </b></span>
                      </div>
                    </div>
                    <!-- ./ Rider Section-->
                  </div>
                  <div class="benefit-grid2" v-else>
                    No addon/Rider for the selected policy
                  </div>
                </div>
              </div>
            </div>
            <div class="mobile-slider" v-if="productData.key_feature">
              <div
                id="carouselExampleIndicators"
                class="carousel slide"
                data-ride="carousel"
              >
                <ol class="carousel-indicators">
                  <li v-for="(data, index) in productData.key_feature?.sectionName" :key="index"
                    data-target="#carouselExampleIndicators"
                    :data-slide-to="index"
                    :class="(index=='0')?'active':'test'"
                  ></li>
                </ol>
                <div class="carousel-inner">
                  <div class="rounded-top">
                    <h4>{{ productData.key_feature?.sectionName }}</h4>
                  </div>
                  <div v-for="(data, index) in productData.key_feature?.subSection" :key="index" :class="'carousel-item ' + getCarousel(index)">
                    <div class="prdct-fture-text">
                      <div class="media">
                        <img
                          class="mb-3"
                          :src="data.icon"
                          alt=""
                        />
                        <div class="media-body">
                          <h5 class="mt-0">{{ data.header }}</h5>
                          <div v-for="(data2, index2) in data.data" :key="index2">
                            {{ data2.data }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="plan-conditions-section mb-5">
              <h4>Boundary Conditions for this plan</h4>
              <div class="d-flex">
                <div class="orange-bg flex-fill">
                  <span>
                    <img src="~@/assets/images/life-cover.svg" alt="" />
                  </span>
                  <h5>Life Cover Amount</h5>
                  <div class="d-flex">
                    <div
                      class="
                        d-flex
                        flex-column
                        covering-limit
                        flex-fill
                        justify-content-start
                        align-items-start
                      "
                    >
                      Minimum
                      <b
                        v-if="
                          selectedPlan.supportingData.validRanges.SumAssured
                            .min != 'null'
                        "
                        >&#8377;
                        {{
                          convertInLakh(
                            selectedPlan.supportingData.validRanges.SumAssured
                              .min
                          )
                        }}</b
                      >
                      <b
                        v-if="
                          selectedPlan.supportingData.validRanges.SumAssured
                            .min == 'null'
                        "
                      >
                        Not Applicable</b
                      >
                    </div>
                    <div
                      class="
                        d-flex
                        flex-column
                        covering-limit
                        flex-fill
                        justify-content-start
                        align-items-start
                      "
                    >
                      Maximum
                      <b
                        v-if="
                          selectedPlan.supportingData.validRanges.SumAssured
                            .max != 'null'
                        "
                        >&#8377;
                        {{
                          convertInLakh(selectedPlan.supportingData.validRanges.SumAssured.max)
                        }}
                      </b>
                      <b
                        v-if="
                          selectedPlan.supportingData.validRanges.SumAssured
                            .max == 'null'
                        "
                      >
                        No Limit
                      </b>
                    </div>
                  </div>
                </div>
                <div class="orange-bg flex-fill">
                  <span>
                    <img src="~@/assets/images/cover-yourself.svg" alt="" />
                  </span>
                  <h5>Cover yourself Upto</h5>
                  <div class="d-flex">
                    <div
                      class="
                        d-flex
                        flex-column
                        covering-limit
                        flex-fill
                        justify-content-start
                        align-items-start
                      "
                    >
                      Minimum
                      <b
                        >{{
                          parseInt(selectedPlan.individualDetails[0].age) +
                          parseInt(
                            selectedPlan.supportingData.validRanges.PolicyTerm
                              .min
                          )
                        }}
                        years</b
                      >
                      <i
                        >(Policy Term
                        {{
                          //parseInt(this.selectedPlan.individualDetails[0].age) + parseInt(selectedPlan.supportingData.validRanges.PolicyTerm.min)
                          parseInt(selectedPlan.supportingData.validRanges.PolicyTerm.min)
                        }}
                        Yrs)</i
                      >
                    </div>
                    <div
                      class="
                        d-flex
                        flex-column
                        covering-limit
                        flex-fill
                        justify-content-start
                        align-items-start
                      "
                    >
                      Maximum
                      <b>{{
                          getMaxMaturityAge(selectedPlan.individualDetails[0].age, selectedPlan.supportingData.validRanges.PolicyTerm.max, selectedPlan.insuranceAndProducts.productCode)
                        }} years</b>
                      <i>
                        (Policy Term
                        {{
                          selectedPlan.supportingData.validRanges.PolicyTerm.max
                        }}
                        Yrs)
                      </i>
                    </div>
                  </div>
                </div>
                <div class="orange-bg flex-fill">
                  <span>
                    <img src="~@/assets/images/payment.svg" alt="" />
                  </span>
                  <h5>Frequency of payment</h5>
                  <div class="d-flex">
                    <div
                      class="
                        d-flex
                        flex-column
                        covering-limit
                        flex-fill
                        justify-content-start
                        align-items-start
                        mb-2
                      "
                      v-for="(value, ind) in selectedPlan.individualDetails[0]
                        .premiumDetails"
                      :key="ind"
                    >
                      {{ getPPF(value["mode"]) }}
                      <b>&#8377; {{ this.currency(value["totalPremiumValue"]) }}</b>
                    </div>
                  </div>

                  <!-- <p>
                    (save Rupee Icon ₹10,962 in lifetime by choosing Yearly)
                  </p> -->
                </div>
              </div>
            </div>
            <div id="plan-condition-slider" class="carousel slide plan-conditions-section" data-ride="carousel">
              <div class="carousel-inner">
                <div class="carousel-item active">
                  <div class="orange-bg flex-fill">
                  <span>
                    <img src="~@/assets/images/life-cover.svg" alt="" />
                  </span>
                  <h5>Life Cover Amount</h5>
                  <div class="d-flex">
                    <div
                      class="
                        d-flex
                        flex-column
                        covering-limit
                        flex-fill
                        justify-content-start
                        align-items-start
                      "
                    >
                      Minimum
                      <b
                        v-if="
                          selectedPlan.supportingData.validRanges.SumAssured
                            .min != 'null'
                        "
                        >&#8377;
                        {{
                          convertInLakh(
                            selectedPlan.supportingData.validRanges.SumAssured
                              .min
                          )
                        }}</b
                      >
                      <b
                        v-if="
                          selectedPlan.supportingData.validRanges.SumAssured
                            .min == 'null'
                        "
                      >
                        Not Applicable</b
                      >
                    </div>
                    <div
                      class="
                        d-flex
                        flex-column
                        covering-limit
                        flex-fill
                        justify-content-start
                        align-items-start
                      "
                    >
                      Maximum
                      <b
                        v-if="
                          selectedPlan.supportingData.validRanges.SumAssured
                            .max != 'null'
                        "
                        >&#8377;
                        {{
                          convertInLakh(selectedPlan.supportingData.validRanges.SumAssured.max)
                        }}
                      </b>
                      <b
                        v-if="
                          selectedPlan.supportingData.validRanges.SumAssured
                            .max == 'null'
                        "
                      >
                        No Limit
                      </b>
                    </div>
                  </div>
                  </div>
                </div>
                <div class="carousel-item">
                  <div class="orange-bg flex-fill">
                  <span>
                    <img src="~@/assets/images/cover-yourself.svg" alt="" />
                  </span>
                  <h5>Cover yourself Upto</h5>
                  <div class="d-flex">
                    <div
                      class="
                        d-flex
                        flex-column
                        covering-limit
                        flex-fill
                        justify-content-start
                        align-items-start
                      "
                    >
                      Minimum
                      <b
                        >{{
                          parseInt(selectedPlan.individualDetails[0].age) +
                          parseInt(
                            selectedPlan.supportingData.validRanges.PolicyTerm
                              .min
                          )
                        }}
                        years</b
                      >
                      <i
                        >(Policy Term
                        {{
                          selectedPlan.supportingData.validRanges.PolicyTerm.min
                        }}
                        Yrs)</i
                      >
                    </div>
                    <div
                      class="
                        d-flex
                        flex-column
                        covering-limit
                        flex-fill
                        justify-content-start
                        align-items-start
                      "
                    >
                      Maximum
                      <b>{{
                          getMaxMaturityAge(selectedPlan.individualDetails[0].age, selectedPlan.supportingData.validRanges.PolicyTerm.max, selectedPlan.insuranceAndProducts.productCode)
                        }} years</b>
                      <i
                        >(Policy Term
                        {{
                          selectedPlan.supportingData.validRanges.PolicyTerm.max
                        }}
                        Yrs)</i
                      >
                    </div>
                  </div>
                  </div>
                </div>
                <div class="carousel-item">
                  <div class="orange-bg flex-fill">
                  <span>
                    <img src="~@/assets/images/payment.svg" alt="" />
                  </span>
                  <h5>Frequency of payment</h5>
                  <div class="d-flex">
                    <div
                      class="
                        d-flex
                        flex-column
                        covering-limit
                        flex-fill
                        justify-content-start
                        align-items-start
                        mb-2
                      "
                      v-for="(value, ind) in selectedPlan.individualDetails[0]
                        .premiumDetails"
                      :key="ind"
                    >
                      {{ getPPF(value["mode"]) }}
                      <b>&#8377; {{ this.currency(value["totalPremiumValue"]) }}</b>
                    </div>
                  </div>

                  <!-- <p>
                    (save Rupee Icon ₹10,962 in lifetime by choosing Yearly)
                  </p> -->
                  </div>
                </div>
              </div>
              <ol class="carousel-indicators">
                <li data-target="#plan-condition-slider" data-slide-to="0" class="active"></li>
                <li data-target="#plan-condition-slider" data-slide-to="1"></li>
                <li data-target="#plan-condition-slider" data-slide-to="2"></li>
              </ol>
            </div>
            <div class="pay-premium">
              <h5>
                Pay premiums for a few years and stay covered for the entire
                policy duration
              </h5>
              <h6>Choose from 5 to 20 years</h6>
              <div class="d-flex">
                <div class="d-flex pay-premium-div flex-column flex-fill mr-5">
                  <span>
                    <img src="~@/assets/images/life-cover.svg" alt="" />
                  </span>
                  <h6>Regular Pay</h6>
                  <p>You pay premiums throughout the policy duration</p>
                </div>
                <div class="d-flex pay-premium-div flex-column flex-fill mr-5">
                  <span>
                    <img src="~@/assets/images/primium-pay.svg" alt="" />
                  </span>
                  <h6>Limited Pay</h6>
                  <p>
                    Pay Premiums For A Few Years And Stay Covered For The Entire
                    Policy Duration. Choose From 5, 10, 12 And 35 Years
                  </p>
                </div>
                <div class="d-flex pay-premium-div flex-column flex-fill">
                  <span>
                    <img src="~@/assets/images/primium-pay.svg" alt="" />
                  </span>
                  <h6>Single Pay</h6>
                  <p>
                    You Pay Premium Only Once And Stay Covered For The Entire
                    Policy Duration.
                  </p>
                </div>
              </div>
            </div>
            <div
              id="pay-premium-slider"
              class="carousel slide"
              data-ride="carousel"
            >
              <ol class="carousel-indicators">
                <li
                  data-target="#pay-premium-slider"
                  data-slide-to="0"
                  class="active"
                ></li>  
                <li data-target="#pay-premium-slider" data-slide-to="1"></li>
                <li data-target="#pay-premium-slider" data-slide-to="2"></li>
              </ol>
              <div class="carousel-inner">
                <div class="">
                  <h5>
                    Pay premiums for a few years and stay covered for the entire
                    policy duration
                  </h5>
                  <h6>Choose from 5 to 20 years</h6>
                  <div class="carousel-item active">
                    <div class="d-flex pay-premium-div flex-column flex-fill">
                      <span>
                        <img src="~@/assets/images/life-cover.svg" alt="" />
                      </span>
                      <h6>Regular Pay</h6>
                      <p>You pay premiums throughout the policy duration</p>
                    </div>
                  </div>
                  <div class="carousel-item">
                    <div class="d-flex pay-premium-div flex-column flex-fill">
                      <span>
                        <img src="~@/assets/images/primium-pay.svg" alt="" />
                      </span>
                      <h6>Limited Pay</h6>
                      <p>
                        Pay premiums for a few years and stay covered for the
                        entire policy duration. Choose from 5 , 10, 12 and 35
                        years
                      </p>
                    </div>
                  </div>
                  <div class="carousel-item">
                    <div class="d-flex pay-premium-div flex-column flex-fill">
                      <span>
                        <img src="~@/assets/images/primium-pay.svg" alt="" />
                      </span>
                      <h6>Single Pay</h6>
                      <p>
                        You pay premium only once and stay covered for the
                        entire policy duration.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="product-feature-section col-md-4 pr-0">
            <div class="prdct-fture-head rounded-top">
              <h4> {{productData.key_feature?.sectionName}} </h4>
            </div>
            <div class="prdct-fture-text position-relative rounded-bottom" v-if="productData.key_feature">
              <hr />
              <div class="media" v-for="(data, index) in productData.key_feature.subSection" :key="index">
                <img class="mr-3" :src="data.icon" alt="" />
                <div class="media-body">
                  <h5 class="mt-0">{{ data.header }}</h5>
                  <div v-for="(data2, index2) in data.data" :key="index2">
                    {{ data2.data }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="
          footer-total-premium
          d-flex
          justify-content-between
          align-items-center
        "
      >
        <div class="total-premium">
          <b>Total Premium: </b>
          &#8377;
          <strong>{{
            this.currency(selectedPlan.individualDetails[0].premiumDetails[0].totalPremiumValue)
          }}</strong
          >/{{
            getPayMode(selectedPlan.individualDetails[0].premiumDetails[0].mode)
          }}
          <span class="feature-name">
            <img src="~@/assets/images/info-button.png" alt=""/>
            <div class="tooltip-sec">
              <table>
                <tr>
                  <td>  
                    Premium:
                  </td>
                  <td>₹ 
                    {{
                    this.currency(selectedPlan.individualDetails[0].premiumDetails[0].premiumValue)
                    }}
                  </td>
                </tr>
                  <tr>
                  <td class="text-left">  
                    Taxes:
                  </td>
                  <td>₹  
                    {{this.currency(selectedPlan.individualDetails[0].premiumDetails[0].tax[0].taxValue)}}
                  </td>
                </tr>
              </table>
            </div>
          </span>
          <span class="premium-tax">Premium (Including Taxes)</span>
        </div>
        <!-- <div class="qt-link">
          <a href="">
            <img src="~@/assets/images/whatsapp.png" alt="" >
            <span>Send Quotes To Customer</span>
          </a>
        </div>
        <div class="qt-link">
          <a href="">
            <img src="~@/assets/images/whatsapp.png" alt="" >
            <span>Send Product Information To Customer</span>
          </a>
        </div> -->
        <div class="bttn-grp d-flex">
          <!-- <router-link to="/compareplan"> -->
            <button type="button" class="back-btn mr-3" @click="goBack">
              <i><img src="~@/assets/images/back-btn.png" /></i>Back
            </button>
          <!-- </router-link> -->
          <!-- <router-link to="/checkout"> -->
            <button type="button" class="next-btn" @click="goCheckout">
              Next <i><img src="~@/assets/images/next-arrow.png" /></i>
            </button>
          <!-- </router-link> -->
        </div>
      </div>
    </div>


    <div class="modal fade" id="editsumassured" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">
              {{ getOptSelectedText(this.addOnUpdateTitle) }}
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <h4>
              Enter your sum assured value
            </h4>
            <div class="benefit-input form-control1">
              <input type="text" v-model.trim="addOnUpdateSumAssured"/>
              <span class="blue-bg-span"> {{ convertInLakh(addOnUpdateSumAssured) }} </span>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" @click="updateAddOnSumAssured()">Update</button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="editridersumassured" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">
              {{ (this.riderUpdateTitle) }}
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <h4>
              Enter your sum assured value
            </h4>
            <div class="benefit-input form-control1">
              <input type="text" v-model.trim="riderUpdateSumAssured"/>
              <span class="blue-bg-span"> {{ convertInLakh(riderUpdateSumAssured) }} </span>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" @click="updateRiderSumAssured()">Update</button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="addridersumassured" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">
              {{ (this.riderUpdateTitle) }}
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <h4>
              Enter your sum assured value
            </h4>
            <div class="benefit-input form-control1">
              <input type="text" v-model.trim="riderUpdateSumAssured"/>
              <span class="blue-bg-span"> {{ convertInLakh(riderUpdateSumAssured) }} </span>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" @click="addRiderSumAssured()">Add</button>
          </div>
        </div>
      </div>
    </div>

    <!-- Lumpsum Payout Percent  -->
    <div class="modal fade" id="lumpsumPayoutPercent" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">
              Lumpsum Payout Percent
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <h4>
              Enter Lumpsum Payout Percent
            </h4>
            <div class="benefit-input form-control1">
              <input type="text" min="1" maxlength="2" v-model.trim="lumpsumPayoutPercent" @keypress="isNumber($event)"/>
              <p class="error-text" v-if="lumpsumPayoutPercentErr">
                {{ lumpsumPayoutPercentErr }}
              </p>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" @click="lumpsumPayoutPercent1()">Submit</button>
          </div>
        </div>
      </div>
    </div>

  </div>
  
</template>


<script>
import $ from "jquery";
import SingleLoading from "@/components/single-loading.vue";
import AlertPage from "../alert/alertpage.vue"
import {getPolicyCover,getInsuranceImages,getOPTSelected,getDBPMaster,getPPOMaster,getMaxPolicyAge,getPlanData,getRider,getPayModeList,getAddOnsList} from '@/mixins/common';
export default {
  name: "policydetails",
  components: {
    SingleLoading,
    AlertPage
  },
  mixins:[getPolicyCover,getInsuranceImages,getOPTSelected,getDBPMaster,getPPOMaster,getMaxPolicyAge,getPlanData,getRider,getPayModeList,getAddOnsList],
  mounted() {
    this.filterFlag = 0;
    
    // sessionStorage.removeItem("isWholeLifeSelected");
    //this.loading = true;
    //console.log('policy details mounted called');
    // Getting session data
    let quote_request = JSON.parse(sessionStorage.getItem("quote_request"));
    let selected_plan_data = JSON.parse(sessionStorage.getItem("selected_plan_data"));
    console.log('quote_request: ', quote_request)
    console.log('selected_plan_data: ', selected_plan_data)
    this.apiReqData = JSON.parse(sessionStorage.getItem("quote_request"));
     
    this.selectedData = JSON.parse(sessionStorage.getItem("selected_plan_data"));
    console.log("selected data5: ", this.selectedData);
    this.selectedPlan2 = this.selectedData;

    // End session data

    this.userData = JSON.parse(localStorage.getItem("userProfile"));
    //this.qAmt = getPolicyCover();

    // Ct here
    
    //console.log('startCoverFor', startCoverFor, 'endCoverFor', endCoverFor );

    // console.log('ppt arr: ', this.premiumPaymentTermArr);

    // Setting Mandatory Fields for Single Quote
    this.apiReqData.typeOfQuote = "Single Quote";


    if(this.selectedPlan2.insuranceAndProducts.insuranceCompanyCode != 'TATA') {
      this.apiReqData.personalInformation.individualDetails[0].quoteAmount = this.selectedPlan2.individualDetails[0].sumAssured;
      this.apiReqData.product.premiumPaymentTerm = this.selectedPlan2.productDetails.premiumPaymentTerm;
      this.apiReqData.product.policyTerm = this.selectedPlan2.productDetails.policyTerm;
    }
    
    this.apiReqData.product.insuranceAndProducts = [
      {
        insuranceCompanyCode:
          this.selectedPlan2.insuranceAndProducts.insuranceCompanyCode,
        productCode: [this.selectedPlan2.insuranceAndProducts.productCode],
      },
    ];
    this.apiReqData.product.DBPoption.OptionSelected =
      this.selectedPlan2.productDetails.DBPoption.optionSelected;

    if(this.selectedPlan2.productDetails.DBPoption.lumpsumPayoutPercent) {
      this.apiReqData.product.DBPoption.lumpsumPayoutPercent = this.selectedPlan2.productDetails.DBPoption.lumpsumPayoutPercent;
    }

    this.apiReqData.product.coverOption.OptionSelected =
      this.selectedPlan2.productDetails.CoverOption.optionSelected;
    this.apiReqData.product.premiumPaymentOption =
      this.selectedPlan2.productDetails.premiumPaymentOption;
    this.apiReqData.product.premiumPaymentFrequency =
      this.selectedPlan2.productDetails.premiumPaymentFrequency;
    
    let selectedAddOns = this.getAddOns();

    console.log('selectedAddOns 888: ', selectedAddOns);
    console.log('this.selectedAddOnsIds: ', this.selectedAddOnsIds);
    let tmpPlanId = this.getPlanId(this.selectedData.insuranceAndProducts.productCode, this.selectedAddOnsIds);
    console.log('temp plan id: ', tmpPlanId);
    //this.apiReqData.product.
    if(tmpPlanId > 0) {
      this.apiReqData.product.planOption.OptionSelected = tmpPlanId;
    } else {
      //this.apiReqData.product.planOption.OptionSelected = this.selectedPlan2.productDetails.planOption.optionSelected;

      if(this.selectedPlan2.insuranceAndProducts.insuranceCompanyCode == 'HDFC') {
        this.apiReqData.product.planOption.OptionSelected = this.selectedPlan2.productDetails.planOption.optionSelected;
      } else {
        this.apiReqData.product.planOption.OptionSelected = "";
      }
      
    }
      
    this.apiReqData.product.AddOnSection.AddOns = this.getAddOns();
    //this.apiReqData.product.AddOnSection.returnOfPremium.returnOfPremiumPercentage = 0;

    // Adding Riders
    this.apiReqData.personalInformation.individualDetails[0].riderDetails = this.getRiders();

    // Removing spouse array
    console.log('sssss: ',this.selectedAddOnsIds)
    let indSpouse = this.selectedAddOnsIds.indexOf('17');

    if(indSpouse > -1) {
      console.log('found spouse');
      this.apiReqData.personalInformation.individualDetails[1].quoteAmount = (this.selectedPlan2.individualDetails[0].sumAssured / 2);
    } else {
      console.log('spouse not found');  
      this.apiReqData.personalInformation.individualDetails.splice(1, 1);
      console.log('apppppp: ', this.apiReqData.personalInformation.individualDetails);
    }
    
    console.log('this.apiReqData kkk: ', this.apiReqData);
    // End Setting up request values for Single Quote

    // Upgrade plan code from checlout page
    if (localStorage.getItem("updatedePlanInfoClicked")) {
      let ckk = JSON.parse(localStorage.getItem("updatedePlanInfoClicked"));
      if(ckk == '2') {
        this.tab2Clicked();
      } else if(ckk == '3') {
        this.tab3Clicked();
      }
      localStorage.removeItem('updatedePlanInfoClicked');
    }
    
    //console.log("policy details page", this.selectedPlan);
    //console.log("request data: ", this.apiReqData);

    

    //console.log("isWholeLifeSelected", this.isWholeLifeSelected);

    //console.log('in mounted section: ', this.apiReqData.product.AddOnSection.returnOfPremium.returnOfPremiumPercentage)

    this.getProductInfo(this.selectedPlan2.insuranceAndProducts.insuranceCompanyCode, this.selectedPlan2.insuranceAndProducts.productCode);
    
    this.getPolicy(this.apiReqData);

    // test data related function
    this.productData = {
      "insuranceCompanyCode":"ICICI",
      "productCode":"T40",
      "about_product": {
          "sectionName":"About Product",
          "subSection":[
            {
                "header":"The product in a nutshell",
                "icon":"",
                "data":[
                  {
                      "data":"Death Benefit: In the unfortunate event of death of the life covered (Life Assured) during the term of the policy, the nominee shall receive the Death Benefit",
                      "icon":""
                  },
                  {
                      "data":"Accidental Death Benefit available with this plan.",
                      "icon":""
                  }
                ]
            },
            {
                "header":"Paid benefits",
                "icon":"",
                "data":[
                  {
                      "data":"Accidental Death Benefit - An amount equal to the Sum Assured chosen by you (subject to a maximum of 2 Crores) will be paid only in the unfortunate death of the Life Assured due to an accident .",
                      "icon":""
                  }
                ]
            }
          ],
          "disclaimers":[
            "",
            ""
          ]
      },
      "key_feature":{
          "sectionName":"Key Product Features",
          "subSection":[
            {
                "header":"Tax Benefits",
                "icon":"",
                "data":[
                  {
                      "data":"Premiums paid are tax-free under section 80 C.",
                      "icon":""
                  },
                  {
                      "data":"When you opt for Critical Illness benefit you can get additional tax benefit under section 80D.",
                      "icon":""
                  },
                  {
                      "data":"Claim amount received by you or your nominee is tax free under section 10(10D).",
                      "icon":""
                  }
                ]
            },
            {
                "header":"Mode of Premium Payment: Single premium.",
                "icon":""
            }
          ],
          "disclaimers":[
            "",
            ""
          ]
      },
      "varSection":[
          {
            "sectionCode":"",
            "sectionName":"",
            "subSection":[
                {
                  "header":"",
                  "icon":"",
                  "data":[
                      {
                        "data":"",
                        "icon":""
                      },
                      {
                        "data":"",
                        "icon":""
                      }
                  ]
                }
            ],
            "disclaimers":[
                "",
                ""
            ]
          }
      ],
      "insCompanyLogo":"",
      "productLogo":""
    }; 

    // console.log('this.testData: ', this.testData);
    // End test data related function


  },
  data() {
    return {
      alertID:"",
      errorFlag:false,
      errorMsg:"",
      errorMsgList:[],
      backUrl:"",
      loading: false,
      currentTab: 1,
      selectedAddOnsIds:[],
      isBlock1Selected: "true",
      isBlock1Active: " active",
      tabContent1Show: " active show",
      isBlock2Selected: "false",
      isBlock2Active: "",
      tabContent2Show: "",
      isBlock3Selected: "false",
      isBlock3Active: "",
      tabContent3Show: "",
      selectedData: "",
      selectedPlan: "",
      selectedPlan2:"",
      filterFlag: 0,
      apiReqData: {},
      qAmt: [],
      premiumPaymentFrequency: "",
      optionSelectedArr: [],
      DBPOptionSelected: "",
      ppoArr: [],
      premiumPayTermBlock: false,
      premiumPaymentTermArr: [],
      coverForArr: [],
      selectedAge: "",
      returnOfPremiumPercentage: 0,
      isWholeLifeSelected: 0,
      indexUpdateAddOn: "",
      addOnUpdateTitle: "",
      addOnUpdateSumAssured: 0,
      productData: "",
      userData: "",
      uiQuoteId: "",
      quoteRequestID: "",
      wholeLifeAge: "",
      lumpsumPayoutPercent: "",
      lumpsumPayoutPercentErr: "",
      payPremium: "",
      premiumPaymentTerm: "",
      riderList: "",
      indexUpdateRider: "",
      riderUpdateTitle: "",
      riderUpdateSumAssured: "",
      payModeList: "",
      addOnsList: "",
      riderCodeToAdd: "",
    };
  },
  methods: {
    callByAlertGoBack()
    {
        this.errorFlag=false
        this.errorMsgList=[]
    },
    saveQuote(apiData) {
      console.log('save quote called', apiData);
      var  headers = {
        Authorization: "Basic " + window.btoa(process.env.VUE_APP_DATA_PER_USERNPASS),
        "Content-Type": "application/json",
      };
      //console.log('this.apiData ll ', this.apiData);
      let qData = {
        "life_assured_name": this.userData.fullname,
        "mobile_number": this.userData.mobile,
        "dob": this.userData.dob,
        "gender": this.userData.gender,
        "zipcode": this.userData.zipcode,
        "annual_income": this.userData.annualincome,
        "employee_type": this.userData.employmenttype,
        "education": this.userData.education,
        "smoker": this.userData.smoke,
        "type_of_quote": this.apiReqData.typeOfQuote,
        "rm_code": this.apiReqData.distributor.agentID,
        "rm_info_json": JSON.stringify(this.apiReqData.distributor),
        "quote_request_json": JSON.stringify(this.apiReqData)
      };
      //console.log('qData: ', qData);
      this.backendApi("saveQuote", qData, headers)
        .then((response) => {
          //console.log('save quote response: ', response);
          if(response == 'error') {
            self.apiError = true;

            //   let msg=['We are facing some issue while saving quote data']

            // this.$router.push({ path: '/alert', query: {backUrl:'policy-details', msg: msg }});
          }
          else if(response.status=="error")
          {
            let msg=['We are facing some issue while saving quote data']

            this.$router.push({ path: '/networkalert', query: {backUrl:'policy-details', msg: msg }});
          }
          else if(response && response.data){
            
            this.uiQuoteId = response.data.data.ui_quote_id;
            sessionStorage.setItem("uiQuoteId", JSON.stringify(this.uiQuoteId));
            if (sessionStorage.getItem('proposal_ui_id')) {
              sessionStorage.removeItem('proposal_ui_id');
            }
            console.log('before calling getQuotationApi - ', apiData);
            this.getQuotationApi(apiData)
            console.log('ui quote id: ', this.uiQuoteId)
          }
        })
        .catch((error) => {
          console.log('error msg block dp', error);
          // alert("Unable to save Quote please refresh the page")
              let msg=['We are facing some issue from backend while saving quote data']

            this.$router.push({ path: '/networkalert', query: {backUrl:'policy-details', msg: msg }});
        });
    },
    saveQuoteResponse(quoteResData) {
      var  headers={
        Authorization: "Basic " + window.btoa(process.env.VUE_APP_DATA_PER_USERNPASS),
        "Content-Type": "application/json",
      };
      this.backendApi("saveQuoteResponse", quoteResData, headers)
        .then((response) => {
          //console.log('save quote response: ', response);
           if(response == 'error') {
            self.apiError = true;
            // let msg=['We are facing some issue while saving quote response data']

            // this.$router.push({ path: '/alert', query: {backUrl:'policy-details', msg: msg }});
          }
          // else if(response.status=="error")
          // {
          //   let msg=['We are facing some issue while saving quote response data']

          //   this.$router.push({ path: '/alert', query: {backUrl:'policy-details', msg: msg }});
          // }
          else if(response && response.data) {
            //this.uiQuoteId = response.data.data.ui_quote_id;
            //console.log('ui quote id: ', this.uiQuoteId)
            let logData = {
              "ui_quote_id": this.uiQuoteId,
              "quote_request_id": this.quoteRequestID,
              "mobile_number": this.userData.mobile,
              "dob": this.userData.dob,
              "page_name": "policydetails",
              "tag_name": "",
              "data": JSON.stringify(this.selectedData)
            };
            this.saveLogData(logData);
          }
        })
        .catch((error) => {
          console.log('error msg block dp', error);
          // let msg=['We are facing some issue from backend']

          // this.$router.push({ path: '/alert', query: {backUrl:'policy-details', msg: msg }});
          // alert("Unable to save Quote Response please refresh the page")
        });
    },
    saveLogData(logData) {
      console.log('save log data called');
      var  headers = {
        Authorization: "Basic " + window.btoa(process.env.VUE_APP_DATA_PER_USERNPASS),
        "Content-Type": "application/json",
      };
      //console.log('logData: ', logData);
      this.backendApi("saveLogData", logData, headers)
        .then((response) => {
          //console.log('save quote response: ', response);
           if(response == 'error') {
            //self.apiError = true;

          }
          else if(response.status=="error")
          {
            console.log("Log not saved")
          }
           else if(response && response.data){
            console.log('log data saved');
          }
        })
        .catch((error) => {
          console.log('error msg block dp', error);

          // alert("Unable to save Log data please refresh the page")
        });
    },
    getProductInfo(insurerCode, productCode) {
      var self = this;
      console.log("get product info called");
      // var headers = {
      //   Authorization: "Basic " + window.btoa(process.env.VUE_APP_USERNPASS),
      //   "Content-Type": "application/json",
      // };
      let headers = {};
      let reqData = [insurerCode, productCode];
      this.backendApi("getProductUiDataApi", reqData, headers, "get")
        .then((response) => {
          //this.loading = false;
          if(response == 'error') {
            //self.apiError = true;
            console.log('api error: block');
          } else if(response && response.data) {
            this.productData = response.data.data;
          } 
        })
        .catch((error) => {
          console.log('error msg block product info', error);
          self.loading = false;
          self.apiError = true;
        });
    },
    getQuotationApi(apiData)
    {
      if (sessionStorage.getItem("quote_request") && sessionStorage.getItem("quote_request_checkout_flag") == 1){
        apiData = JSON.parse(sessionStorage.getItem("quote_request"));
        this.apiReqData = JSON.parse(sessionStorage.getItem("quote_request"))
        sessionStorage.removeItem('quote_request_checkout_flag');
      }

      // Setting riderPremiumPaymentTerm
      apiData.personalInformation.individualDetails[0].riderDetails.forEach(
        (element, index) => {
          apiData.personalInformation.individualDetails[0].riderDetails[index].riderPremiumPayingTerm = apiData.product.premiumPaymentTerm;
          apiData.personalInformation.individualDetails[0].riderDetails[index].riderTerm = apiData.product.policyTerm;
        }
      );

      //sessionStorage.setItem("quote_request", JSON.stringify(apiData));
      sessionStorage.setItem("quote_request_flag", JSON.stringify(1));
      var self = this;
      console.log("get policy called");
      
      // New Format
      this.loading = true;
      let reqData = {"data":apiData};
      let headers = {};
      this.backendApi("quotationApiGet", reqData, headers)
        .then((response) => { 
      
          this.loading = false;
          this.apiError = false;
          if(response == 'error') {
            self.apiError = true;
            this.loading = false;
          }
            else if(response.data.errors.length>0)
          {
            let msg=[]
            this.errorMsgList=[]
              for(let i=0;i<response.data.errors.length;i++)
              {
                  msg.push(response.data.errors[i]["errorMessage"])
              }
              this.errorMsg="We are facing some issue while fetching quotation Api"
              // this.errorFlag=true
             
            this.$router.push({ path: '/alert', query: {backUrl:'policy-details',msgError:this.errorMsg, reqId:response.data.reqId,  msg:msg }});

          }
          else if(response && response.data.data.quote){ 
            console.log(response);
            this.loading = false;
            this.apiError = false;
            this.selectedPlan = response.data.data.quote[0];
            this.quoteRequestID = response.data.reqId;
            sessionStorage.setItem("quote_request", JSON.stringify(response.data.data.request));
            sessionStorage.setItem("selectedPlanForCheckout", JSON.stringify(this.selectedPlan));

            this.selectedPlan.productDetails.AddOnSection.addOnSelected.forEach(
              (element, index) => {
                this.selectedPlan.productDetails.AddOnSection.addOnSelected[index]['checked'] = true;
              }
            );

            this.selectedPlan.individualDetails[0].rider.forEach(
              (element, index) => {
                this.selectedPlan.individualDetails[0].rider[index]['checked'] = true;
              }
          );
            
            sessionStorage.setItem("selected_plan_data", JSON.stringify(this.selectedPlan));
            sessionStorage.setItem("quoteRequestID", JSON.stringify(response.data.reqId));
            this.riderList = this.getRiderList(this.selectedPlan.insuranceAndProducts.productCode);
            this.addOnsList = this.getAddOnsList(this.selectedPlan.insuranceAndProducts.productCode);

            console.log('this.addOnsList: ', this.addOnsList)
            

            // ########################### Setting Required Values ###########################
            
            this.payModeList = this.getPayModeList(this.selectedPlan.insuranceAndProducts.insuranceCompanyCode);
            console.log('this.payModeList: ', this.payModeList)
            this.wholeLifeAge = this.selectedPlan.supportingData.Messages.wholeLifeAge;
            this.payPremium = this.selectedPlan.productDetails.premiumPaymentOption;
            console.log('whole life age: ', this.wholeLifeAge);

            if(this.selectedPlan.productDetails.premiumPaymentOption == '3') {
              this.premiumPaymentTermArr = this.getPaymentTermValues();
            } else {
              this.premiumPaymentTermArr = [
                this.selectedPlan.productDetails.premiumPaymentTerm,
              ];
            }
            
            this.selectedAge =
              parseInt(this.selectedPlan.individualDetails[0].age) +
              parseInt(this.selectedPlan.productDetails.policyTerm);

              
            // Setting Premium Payment Term
            this.premiumPaymentTerm = this.selectedPlan.productDetails.premiumPaymentTerm;

            // Setting Premium Payment Frequency
            this.premiumPaymentFrequency = this.selectedPlan.productDetails.premiumPaymentFrequency;

            this.optionSelectedArr = this.getFwrConfig(
              this.selectedPlan.insuranceAndProducts.productCode
            );

            this.ppoArr = this.getPpoConfig(
              this.selectedPlan.insuranceAndProducts.productCode
            );

            this.returnOfPremiumPercentage = this.apiReqData.product.AddOnSection.returnOfPremium.returnOfPremiumPercentage;
            if (
              this.selectedPlan.productDetails.policyTerm ==
              this.wholeLifeAge - parseInt(this.selectedPlan.individualDetails[0].age)
            ) {
              //console.log("if block executed");
              this.isWholeLifeSelected = 1;
            } else {
              //console.log("else block executed");
              this.isWholeLifeSelected = 0;
            }

            this.includeAddOnsPremium();
            this.includeRidersPremium();

            this.qAmt = self.getPolicyCover2(this.selectedPlan.supportingData.validRanges.SumAssured);

            // Get Cover for Values
            if(this.isWholeLifeSelected == 1) {
              this.setCoverFor(1);
            } else {
              this.setCoverFor();
            }

            // ########################### End Setting Required Values ###########################

            // Quote response api section
            let qResData = {
              "ui_quote_id": this.uiQuoteId,
              "quote_response_id": response.data.reqId,
              "annual_income": this.userData.annualincome,
              "quote_response_data": JSON.stringify(response.data.data.quote),
            };

            this.saveQuoteResponse(qResData);   
          }
          

         
        })
        .catch((error) => {
          console.log('error msg', error);
          this.loading = false;
          this.apiError = false;
           let msg=['We are facing some issue from backend while fetching quotation Api']

          this.$router.push({ path: '/networkalert', query: {backUrl:'policy-details', msg: msg }});
        });
    },
    getPolicy(apiData) {
      this.saveQuote(apiData);
      
      
    },
    goBack() {
      console.log("go back called: ", this.selectedPlan.insuranceAndProducts.insuranceCompanyCode);
      //router.go(-1);
      //localStorage.setItem("filterFlag", JSON.stringify(1));
      //this.$router.back();
      if(this.selectedPlan.insuranceAndProducts.insuranceCompanyCode == 'TATA'||this.selectedPlan.insuranceAndProducts.insuranceCompanyCode == 'HDFC') {
        this.$router.push('/termplanqte');
      } else {
        this.$router.push('/policy-quote');
      }
     
    },
    tab1Clicked() {
      this.currentTab = 1;
      this.isBlock2Selected = "false";
      this.isBlock2Active = "";
      this.tabContent2Show = "";

      this.isBlock3Selected = "false";
      this.isBlock3Active = "";
      this.tabContent3Show = "";

      this.isBlock1Selected = "true";
      this.isBlock1Active = "active";
      this.tabContent1Show = "active show";
    },
    tab2Clicked() {
      this.currentTab = 2;
      this.isBlock1Selected = "false";
      this.isBlock1Active = "";
      this.tabContent1Show = "";

      this.isBlock3Selected = "false";
      this.isBlock3Active = "";
      this.tabContent3Show = "";

      this.isBlock2Selected = "true";
      this.isBlock2Active = "active";
      this.tabContent2Show = "active show";
    },
    tab3Clicked() {
      this.currentTab = 3;
      this.isBlock1Selected = "false";
      this.isBlock1Active = "";
      this.tabContent1Show = "";

      this.isBlock2Selected = "false";
      this.isBlock2Active = "";
      this.tabContent2Show = "";

      this.isBlock3Selected = "true";
      this.isBlock3Active = "active";
      this.tabContent3Show = "active show";
    },
    goCheckout() {
      if(this.currentTab == 1) {
        this.tab2Clicked();
      } else if(this.currentTab == 2) {
        this.tab3Clicked();
      } else { 
        
        if(this.premiumPaymentTermArr.length > 1) {
          //console.log('limited number of years selected');
          //console.log('selectedPlan.productDetails.premiumPaymentTerm: ', this.selectedPlan.productDetails.premiumPaymentTerm)
          //console.log('this.apiReqData.product.premiumPaymentTerm: ', this.apiReqData.product.premiumPaymentTerm)
          if(this.apiReqData.product.premiumPaymentTerm != 0) {
            this.$router.push('/checkout');
            
          } else {
            alert('Please Select Pay For Value First, Then Proceed');
          }
        } else {
          this.$router.push('/checkout');
          // this.$router.push('/networkalert');
        }
        
      }
    },
    getInsurerImgs(value) {
      let img = getInsuranceImages();
      return require("../../assets/images/" + img[value]);
    },
    getMaxAge(value) {
      let ages = getMaxPolicyAge();
      return ages[value];
    },
    convertInLakh(amt) {
      if (amt < 10000000) {
        return amt / 100000 + "L";
      } else {
        return amt / 10000000 + "Cr.";
      }
    },
    getPPF(val) {
      if (val == "M") {
        return "Monthly";
      } else if (val == "Q") {
        return "Quarterly";
      } else if (val == "HY") {
        return "Half Yearly";
      } else if (val == "Y") {
        return "Yearly";
      }
    },
    getPayMode(val) {
      if (val == "M") {
        return "Month";
      } else if (val == "Q") {
        return "Quarter";
      } else if (val == "HY") {
        return "Half Year";
      } else if (val == "Y") {
        return "Year";
      } else if (val == "S") {
        return "Single Pay";
      }
    },
    getAddOns() {
      console.log('getAddOns called: ')
      let addOnsArr = [];
      this.selectedAddOnsIds = [];
      this.selectedPlan2.productDetails.AddOnSection.addOnSelected.forEach(
        (element, index) => {
          let tempArr = {
            OptionSelected: element.optionSelected,
            addOnSumAssured: element.addOnSumAssured,
            varFields: [
              {
                fieldName: "",
                fieldValue: "",
              },
            ],
          };

          if(element.checked == true || element.premiumIncludedFlag == 'Yes') {
            addOnsArr.push(tempArr);
            this.selectedAddOnsIds.push(element.optionSelected);
          }
        }
      );
      return addOnsArr;
    },
    getRiders() {
      let addRiderArr = [];
      this.selectedPlan2.individualDetails[0].rider.forEach(
          (element, index) => {
              let tempArr = {
                  riderCode: element.ridercode,
                  riderTerm: parseInt(element.riderTerm),
                  riderSA: parseInt(element.riderSA),
                  riderPremiumPayingTerm: parseInt(element.riderPremiumPayingTerm)
              }

              if(element.checked == true) {
                  addRiderArr.push(tempArr);
              }
          }
      );
      return addRiderArr;
    },
    getPlanId(productCode, selectedAdOns) {
      console.log('pcode: ', productCode, 'selectedAdOns: ', selectedAdOns)
      //let policyArr = getPlanData();
      //console.log('policy array: 99', policyArr);
      let productArray = getPlanData()[productCode];
      console.log('selected productArray: ', productArray);
      var planid = 0;
      for(var key in productArray) {
        let tempArr = productArray[key];
        if(tempArr.sort().join(',')=== selectedAdOns.sort().join(',')){
          console.log('matched: Key => ', key);
          planid = key;
        }
      }
      return planid;
    },
    onChangeAmt(event) {
      //console.log(event.target.value);
      this.getPreviousRequest();
      this.apiReqData.personalInformation.individualDetails[0].quoteAmount =
        parseInt(event.target.value);
      //this.apiReqData.product.AddOnSection.
      //console.log(this.apiReqData);
      this.getPolicy(this.apiReqData);
    },
    getConfigrations() {
      //console.log("get configrations called");
    },
    getPpf(event) {
      console.log(event.target.value);
      this.getPreviousRequest();
      this.premiumPaymentFrequency = event.target.value;
      this.apiReqData.product.premiumPaymentFrequency = this.premiumPaymentFrequency;
      this.getPolicy(this.apiReqData);
    },
    getOptionSelectedVal(val) {
      let data = getDBPMaster();
      return data[val];
    },
    getFwr(event) {
      //console.log(event.target.value);
      this.getPreviousRequest();
      this.DBPOptionSelected = event.target.value;
      //console.log(this.DBPOptionSelected);
      if(this.DBPOptionSelected == 6) {
        $("#lumpsumPayoutPercent").modal("show");
        //this.apiReqData.product.DBPoption.lumpsumPayoutPercent = 50;
      } else {
        this.apiReqData.product.DBPoption.lumpsumPayoutPercent = 0;
        this.apiReqData.product.DBPoption.OptionSelected = this.DBPOptionSelected;
        //console.log(this.apiReqData);
        this.getPolicy(this.apiReqData);
      }
      
    },
    getPpoVal(val) {
      let data = getPPOMaster();
      return data[val];
    },
    getPayPremium(event) {
      //console.log(event.target.value);
      this.payPremium = event.target.value;
      this.getPreviousRequest();
      if (event.target.value == 3) {
        console.log('limited no. called', event.target.value);
        this.apiReqData.product.premiumPaymentOption = event.target.value;
        console.log('apireqdata :gg ',this.apiReqData )
        sessionStorage.setItem("quote_request", JSON.stringify(this.apiReqData));
        this.premiumPaymentTermArr = this.getPaymentTermValues();
        this.apiReqData.product.premiumPaymentTerm = 0;
        if(this.apiReqData.product.premiumPaymentFrequency == 'S') {
          this.apiReqData.product.premiumPaymentFrequency = 'M';
        }
      } else if(event.target.value == 1) {
        this.apiReqData.product.premiumPaymentOption = event.target.value;
        this.apiReqData.product.premiumPaymentTerm = parseInt(event.target.value);
        this.apiReqData.product.premiumPaymentFrequency  = 'S';
        this.getPolicy(this.apiReqData);
      } else {
        console.log('for entire policy term called');
        this.apiReqData.product.premiumPaymentOption = event.target.value;
        this.apiReqData.product.premiumPaymentTerm = this.selectedPlan.productDetails.policyTerm;
        if(this.apiReqData.product.premiumPaymentFrequency == 'S') {
          this.apiReqData.product.premiumPaymentFrequency = 'M';
        }
        this.premiumPaymentTermArr = [
          this.selectedPlan.productDetails.premiumPaymentTerm,
        ];
        this.getPolicy(this.apiReqData);
      }
      
    },
    getCoverTillAge(event) {
      console.log('cover till age called')
      console.log(event.target.value);
      this.getPreviousRequest();
      if(this.payPremium == 3) {
        this.apiReqData.product.policyTerm =
        parseInt(event.target.value) -
        parseInt(this.selectedPlan.individualDetails[0].age);
        this.premiumPaymentTerm = "";
        sessionStorage.setItem("quote_request", JSON.stringify(this.apiReqData));
        console.log('inside 3');
        this.premiumPaymentTermArr = this.getPaymentTermValues();
      } else {
        this.apiReqData.product.policyTerm =
        parseInt(event.target.value) -
        parseInt(this.selectedPlan.individualDetails[0].age);
        this.apiReqData.product.premiumPaymentTerm =
          parseInt(event.target.value) -
          parseInt(this.selectedPlan.individualDetails[0].age);
        this.getPolicy(this.apiReqData);
      }
      
    },
    getPremiumPaymentTerm(event) {
      //console.log(event.target.value);
      this.getPreviousRequest();
      //this.apiReqData.product.premiumPaymentTerm = parseInt(event.target.value) - parseInt(this.selectedPlan.individualDetails[0].age);
      this.apiReqData.product.premiumPaymentTerm = parseInt(event.target.value);
      this.getPolicy(this.apiReqData);
    },
    addReturnOfPremium() {
      this.returnOfPremiumPercentage = 100;
      this.apiReqData.product.AddOnSection.returnOfPremium.returnOfPremiumPercentage =
        this.returnOfPremiumPercentage;
      this.getPolicy(this.apiReqData);
    },
    removeReturnOfPremium() {
      this.returnOfPremiumPercentage = 0;
      this.apiReqData.product.AddOnSection.returnOfPremium.returnOfPremiumPercentage =
        this.returnOfPremiumPercentage;
      this.getPolicy(this.apiReqData);
    },
    addWholeLife() {
      this.isWholeLifeSelected = 1;
      this.getPreviousRequest();
      sessionStorage.setItem("isWholeLifeSelected", JSON.stringify(this.isWholeLifeSelected));
      this.apiReqData.product.policyTerm =
        this.wholeLifeAge - parseInt(this.selectedPlan.individualDetails[0].age);
      if(this.apiReqData.product.premiumPaymentOption == 3) {
        this.premiumPaymentTermArr = this.getPaymentTermValues();
        this.premiumPaymentTerm = "";
        this.getPolicy(this.apiReqData);
      } else {
        this.apiReqData.product.premiumPaymentTerm =
        this.wholeLifeAge - parseInt(this.selectedPlan.individualDetails[0].age);
        this.getPolicy(this.apiReqData);
      } 
    },
    removeWholeLife() {
      this.getPreviousRequest();
       if(this.apiReqData.product.insuranceAndProducts[0].insuranceCompanyCode=="HDFC")
       {
        this.apiReqData.product.premiumPaymentOption="2"
       }
      this.apiReqData.product.policyTerm =
        65 - parseInt(this.selectedPlan.individualDetails[0].age);
      this.apiReqData.product.premiumPaymentTerm =
        65 - parseInt(this.selectedPlan.individualDetails[0].age);
      this.isWholeLifeSelected = 0;
      sessionStorage.removeItem("isWholeLifeSelected");
      //this.coverForArr.pop();
      //this.setCoverFor();
      this.getPolicy(this.apiReqData);
    },

    // Configrations
    getFwrConfig(pCode) {
      // Based on DBPMaster
      //console.log('getFwrConfig called', pCode)
      let tempArr = [];
      if (pCode == "T50" || pCode == "T51") {
        tempArr = [4, 5, 6, 7];
      } else if (pCode == "T52") {
        tempArr = [4];
      } else if (pCode == "T53") {
        tempArr = [4];
      } else if (pCode == "40038") {
        tempArr = [4, 5, 6];
      } else if (pCode == "12023") {
        tempArr = [4, 5, 6];
      } else if (pCode == "12013") { // copy of 12023 for testing purpose only
        tempArr = [4, 5, 6];
      } else if (pCode == "T54") {
        tempArr = [4];
      } else if (pCode == "T38") {
        tempArr = [4];
      } else if (pCode == "T41") {
        tempArr = [4];
      } else if (pCode == "T39") {
        tempArr = [4];
      } else if (pCode == "T40") {
        tempArr = [4];
      } else if (pCode == "T55") {
        tempArr = [4, 5, 6, 7];
      } else if (pCode == "T56") {
        tempArr = [4, 5, 6 , 7];
      } else if (pCode == "T43") {
        tempArr = [4];
      } else if (pCode == "T58") {
        tempArr = [4];
      } else if (pCode == "T59") {
        tempArr = [4];
      } else if (pCode == "T60") {
        tempArr = [4];
      } else if (pCode == "T61") {
        tempArr = [4];
      } else if (pCode == "P1C2PL") {
        tempArr = [4];
      } else if (pCode == "12003") {
        tempArr = [4, 6, 8];
      } else if (pCode == "12013") {
        tempArr = [4, 5, 7];
      } else if (pCode == "SamRakSp") {
        tempArr = [4];
      } else if (pCode == "25") {
        tempArr = [4, 6];
      } 
      return tempArr;
    },
    getPpoConfig(pCode) {
      //productMaster table
      //console.log("getppocalled: ", pCode);
      let tempArr = [];
      if (pCode == "T50") {
        tempArr = [1];
      } else if (pCode == "T51") {
        tempArr = [2, 3];
      } else if (pCode == "T52") {
        tempArr = [1];
      } else if (pCode == "T53") {
        tempArr = [2, 3];
      } else if (pCode == "40038") {
        tempArr = [2, 3];
      } else if (pCode == "T54") {
        tempArr = [2];
      } else if (pCode == "T38") {
        tempArr = [2];
      } else if (pCode == "T41") {
        tempArr = [2];
      } else if (pCode == "T39") {
        tempArr = [1];
      } else if (pCode == "T40") {
        tempArr = [1];
      } else if (pCode == "T55") {
        tempArr = [1];
      } else if (pCode == "T56") {
        tempArr = [2, 3];
      } else if (pCode == "T43") {
        tempArr = [2];
      } else if (pCode == "T58") {
        tempArr = [1];
      } else if (pCode == "T59") {
        tempArr = [2, 3];
      } else if (pCode == "T60") {
        tempArr = [1];
      } else if (pCode == "T61") {
        tempArr = [2, 3];
      } else if (pCode == "P1C2PL") {
        tempArr = [1, 2, 3];
      } else if (pCode == "12003") {
        tempArr = [2, 3];
      } else if (pCode == "12013") {
        tempArr = [1, 2, 3];
      } else if (pCode == "SamRakSp") {
        tempArr = [1, 2, 3];
      } else if (pCode == "25") {
        tempArr = [1, 3];
      }
      return tempArr;
    },
    getPaymentTermValues() {
      //productMaster table

      let pCode = this.selectedPlan.insuranceAndProducts.productCode;
      let policyTerm = this.apiReqData.product.policyTerm;
      let age = this.selectedPlan.individualDetails[0].age;

      //console.log('pCodeL ', pCode)
      //console.log('policyTerm ', policyTerm)
      //console.log('age ', age);
      //console.log('getPaymentTermValues called: ')
      let tempArr = [];
      if (pCode == "T51") {
        //console.log('selectedAge: ', this.selectedAge);
        //console.log('isWholeLifeSelected: ', this.isWholeLifeSelected);
        //console.log('payPremium: ', this.payPremium);
        if(this.isWholeLifeSelected == 1) {
          tempArr = [10, 60 - age];
        } else {
          if(this.selectedAge < 65) {
            tempArr = [5, 7, 10, policyTerm - 5];
          } else {
            tempArr = [5, 7, 10, policyTerm - 5, 60 - age];
          }
        }
        
      } else if (pCode == "T53") {
        tempArr = [policyTerm - 5];
      } else if (pCode == "40038") {
        tempArr = [60 - (age +1)];
      } else if (pCode == "T56") {
        tempArr = [5, 10, policyTerm-5];
      } else if (pCode == "T59") {
        tempArr = [5, 10];
      } else if (pCode == "T60") {
        tempArr = [];
      } else if (pCode == "T61") {
        tempArr = [5, 10, policyTerm-5];
      } else if (pCode == "P1C2PL") {
        tempArr = [];
        for(var i = 5; i< policyTerm; i++) {
          tempArr.push(i);
        }
      } else if (pCode == "12003") {
        tempArr = [10];
      } else if (pCode == "12023") {
        tempArr = [5, 10, 15, 20, (60-age)];
      } else if (pCode == "12013") {
        tempArr = [15, policyTerm];
      } else if (pCode == "SamRakSp") {
        //tempArr = [5, 82];
        if(this.selectedPlan.productDetails.AddOnSection.returnOfPremium.returnOfPremiumPercentage == '100') {
          tempArr = [5, 10, 12];
        } else {
          //let endVal = this.getMinOfTwo(this.selectedPlan.productDetails.policyTerm, 81);
          let endVal = this.getMinOfTwo(this.apiReqData.product.policyTerm, 81);
          //endCoverFor = parseInt(this.selectedPlan.individualDetails[0].age) + this.getMinOfTwo(100 - parseInt(this.selectedPlan.individualDetails[0].age), 82);
          console.log('inside ellll: ', endVal);
          for(var i=10; i <= endVal; i++) {
            tempArr.push(i);
          }
        }
        
      } else if (pCode == "25") {
        tempArr = [5, 47];
      }
      console.log('mp arr:', tempArr)

      tempArr.sort(function(a, b) {
        return a - b;
      });
      
      let tmpArr2 = [];
      // this.selectedPlan.productDetails.policyTerm
      tempArr.forEach(
        (element, index) => {
        if(element < this.apiReqData.product.policyTerm && element != 0) {
          tmpArr2.push(element);
        }
      });

      var uniqueValues = tmpArr2.filter(this.onlyUnique);
      console.log('uniqueValues: ', uniqueValues)
      return uniqueValues;
    },
    onlyUnique(value, index, self) {
      return self.indexOf(value) === index;
    },
    getOptSelectedText(value) {
      let optSelected = getOPTSelected();
      return optSelected[value];
    },
    convertInLakh(amt) {                                   
      if (amt < 10000000) {
        return amt / 100000 + "L";
      } else {
        return amt / 10000000 + "Cr.";
      }
    },
    getCarousel(val) {
      if(val == '0') {
        return 'active';
      }
      return '';
    },
    getSumAssuredValue(ind, optionselected, selectedAddOnsArray) {
      //console.log('index: ', ind, 'option selected: ', optionselected, 'sumassured: ', sumassured);
      this.indexUpdateAddOn = this.getAddSelectionIndex(optionselected, selectedAddOnsArray);
      this.addOnUpdateTitle = optionselected;
      this.addOnUpdateSumAssured = this.getAddOnSelectionSA(optionselected, selectedAddOnsArray);
    },
    updateAddOnSumAssured() { 
      //this.selectedData.productDetails.AddOnSection.addOnSelected[this.indexUpdateAddOn].addOnSumAssured = this.addOnUpdateSumAssured;
      $("#editsumassured").modal("hide");
      this.apiReqData.product.AddOnSection.AddOns[this.indexUpdateAddOn].addOnSumAssured = parseInt(this.addOnUpdateSumAssured);
      this.getPolicy(this.apiReqData);
    },
    getRiderSumAssuredValue(riderCode, riderText, riderArray) {
      this.indexUpdateRider = this.getRiderSelectionIndex(riderCode, this.apiReqData.personalInformation.individualDetails[0].riderDetails);
      this.riderUpdateTitle = riderText;
      this.riderUpdateSumAssured = this.getRiderSelectionSA(riderCode, riderArray);
    },
    updateRiderSumAssured() {
      $("#editridersumassured").modal("hide");
      this.apiReqData.personalInformation.individualDetails[0].riderDetails[this.indexUpdateRider].riderSA = parseInt(this.riderUpdateSumAssured);
      this.getPolicy(this.apiReqData);
    },
    currency(amt) {
      return new Intl.NumberFormat().format(amt)
    },
    getAddOns2(adOnId) {
      let addOnsArr = [];
      this.selectedAddOnsIds = [];
      console.log('addOnId to remove: ', adOnId);
      //this.selectedPlan2.productDetails.AddOnSection.addOnSelected.forEach(
      this.selectedPlan.productDetails.AddOnSection.addOnSelected.forEach(
        (element, index) => {
          let tempArr = {
            OptionSelected: element.optionSelected,
            addOnSumAssured: element.addOnSumAssured,
            varFields: [
              {
                fieldName: "",
                fieldValue: "",
              },
            ],
          };

          //if(element.checked == true || element.premiumIncludedFlag == 'Yes') {
            if(adOnId != element.optionSelected) {
              addOnsArr.push(tempArr);
              this.selectedAddOnsIds.push(element.optionSelected);
            }
          //}
        }
      );

      console.log('addOnsArr: ', addOnsArr);
      return addOnsArr;
    },
    removeAddOns(adOnId) {
      this.apiReqData.product.AddOnSection.AddOns = this.getAddOns2(adOnId);
      let tmpPlanId = this.getPlanId(this.selectedPlan.insuranceAndProducts.productCode, this.selectedAddOnsIds);
      console.log('temp plan id: ', tmpPlanId);

      if(tmpPlanId > 0) {
        this.apiReqData.product.planOption.OptionSelected = tmpPlanId;
      } 
      this.getPolicy(this.apiReqData);
    },
    addAddOns(adOnId) {
      console.log('adOnId called');
      let itemIndex = this.addOnsList.findIndex(item => item.optionSelected == adOnId);
      if(itemIndex > -1) {
        let tmp = this.addOnsList[itemIndex];
        let tempArr = {
          OptionSelected: tmp.optionSelected,
          addOnSumAssured: tmp.addOnSumAssured,
          varFields: [
            {
              fieldName: "",
              fieldValue: "",
            },
          ],
        };
        this.apiReqData.product.AddOnSection.AddOns.push(tempArr);
        this.selectedAddOnsIds.push(tmp.optionSelected);
        
        //-------------
        let tmpPlanId = this.getPlanId(this.selectedData.insuranceAndProducts.productCode, this.selectedAddOnsIds);
        console.log('temp plan id: ', tmpPlanId);

        if(tmpPlanId > 0) {
          this.apiReqData.product.planOption.OptionSelected = tmpPlanId;
        } else {
          if(this.selectedPlan2.insuranceAndProducts.insuranceCompanyCode == 'HDFC') {
            this.apiReqData.product.planOption.OptionSelected = this.selectedPlan2.productDetails.planOption.optionSelected;
          } else {
            this.apiReqData.product.planOption.OptionSelected = "";
          }
          
        }
        //-------------
        
        this.getPolicy(this.apiReqData);
      }
    },
    includeAddOnsPremium() {
      let addOnsAmt = 0;
      let addOnsAmtTotal = 0;
      let addOnsTax = 0;
      this.selectedPlan.productDetails.AddOnSection.addOnSelected.forEach(
        (element, index) => {
          if( element.premiumIncludedFlag == 'No') {
            addOnsAmt += element.premiumDetails[0].premiumValue;
            addOnsTax += element.premiumDetails[0].tax[0].taxValue;
            addOnsAmtTotal += element.premiumDetails[0].totalPremiumValue;
          }
        }
      );
      this.selectedPlan.individualDetails[0].premiumDetails[0].premiumValue += addOnsAmt;
      this.selectedPlan.individualDetails[0].premiumDetails[0].tax[0].taxValue += addOnsTax;
      this.selectedPlan.individualDetails[0].premiumDetails[0].totalPremiumValue += addOnsAmtTotal;
    },
    includeRidersPremium() {
      let riderAmt = 0;
      let riderAmtTotal = 0;
      let riderTax = 0;
      this.selectedPlan.individualDetails[0].rider.forEach(
        (element, index) => {
          riderAmt += element.premiumDetails[0].riderPremiumValue;
          riderTax += element.premiumDetails[0].tax[0].taxValue;
          riderAmtTotal += element.premiumDetails[0].totalRiderPremiumValue;
        }
      );
      this.selectedPlan.individualDetails[0].premiumDetails[0].premiumValue += riderAmt;
      this.selectedPlan.individualDetails[0].premiumDetails[0].tax[0].taxValue += riderTax;
      this.selectedPlan.individualDetails[0].premiumDetails[0].totalPremiumValue += riderAmtTotal;
    },
    lumpsumPayoutPercent1() {
      console.log('lumpsumPayoutPercent called');
      this.lumpsumPayoutPercentErr = "";
      if(this.lumpsumPayoutPercent > 0) {
        $("#lumpsumPayoutPercent").modal("hide");
        this.apiReqData.product.DBPoption.OptionSelected = this.DBPOptionSelected;
        this.apiReqData.product.DBPoption.lumpsumPayoutPercent = parseInt(this.lumpsumPayoutPercent);	
        this.getPolicy(this.apiReqData);
      } else {
        this.lumpsumPayoutPercentErr = 'Please enter lumpsum payout percent';
      }

    },
    // Numbers only
    isNumber: function(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      console.log(charCode);
      //if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode == 46) {
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    getPolicyCover2(data) {
      let arr = [];
      for(var i = parseInt(data.min); i <= parseInt(data.max); i = i + parseInt(data.multipleOf)) {
        arr.push(i);
      }
      return arr;
    },
    setCoverFor(val = "") {
      console.log('setCoverFor called: ', val);
      this.coverForArr = [];
      if(val == 1) {
        //console.log('inside 1: ', this.coverForArr);
        this.coverForArr.push(parseInt(this.wholeLifeAge));
      } else {
        // Setting Cover For
        console.log('inside else 1')
        let startCoverFor = parseInt(this.selectedPlan.individualDetails[0].age) +  parseInt(this.selectedPlan.supportingData.validRanges.PolicyTerm.min);
        if(this.selectedPlan.insuranceAndProducts.productCode == 'SamRakSp') {
            console.log('inside SamRakSp pay');
          // Specially for TATA (SamRakSp)
          let endCoverFor;
          if(parseInt(this.selectedPlan.individualDetails[0].age)<60)
          {
            if(this.selectedPlan.productDetails.AddOnSection.returnOfPremium.returnOfPremiumPercentage == '100') { // if ROP
              if(this.selectedPlan.productDetails.premiumPaymentOption == '3') { // Limited Pay
                endCoverFor = parseInt(this.selectedPlan.individualDetails[0].age) + this.getMinOfTwo(100 - parseInt(this.selectedPlan.individualDetails[0].age), 50);
              } else { // Regular Pay and Single Pay
                console.log('inside regular pay');
                endCoverFor = parseInt(this.selectedPlan.individualDetails[0].age) + this.getMinOfTwo(100 - parseInt(this.selectedPlan.individualDetails[0].age), 40);
                console.log('endCoverFor irp: ', endCoverFor);
              }
            } else { // Non ROP
              endCoverFor = parseInt(this.selectedPlan.individualDetails[0].age) + this.getMinOfTwo(100 - parseInt(this.selectedPlan.individualDetails[0].age), 82);
            }
            console.log('start cover for: ', startCoverFor, 'end cover for: ', endCoverFor)
            for (var i = startCoverFor; i <= endCoverFor; i++) {
              this.coverForArr.push(i);
            }
            this.coverForArr.push(parseInt(this.wholeLifeAge));
          }
          else{
            if(this.selectedPlan.productDetails.AddOnSection.returnOfPremium.returnOfPremiumPercentage == '100') { // if ROP
              if(this.selectedPlan.productDetails.premiumPaymentOption == '3') { // Limited Pay
                endCoverFor = parseInt(this.selectedPlan.individualDetails[0].age) + this.getMinOfTwo(85 - parseInt(this.selectedPlan.individualDetails[0].age), 50);
              } else { // Regular Pay and Single Pay
                console.log('inside regular pay');
                endCoverFor = parseInt(this.selectedPlan.individualDetails[0].age) + this.getMinOfTwo(85 - parseInt(this.selectedPlan.individualDetails[0].age), 40);
                console.log('endCoverFor irp: ', endCoverFor);
              }
            } else { // Non ROP
              endCoverFor = parseInt(this.selectedPlan.individualDetails[0].age) + this.getMinOfTwo(85 - parseInt(this.selectedPlan.individualDetails[0].age), 82);
            }
            console.log('start cover for: ', startCoverFor, 'end cover for: ', endCoverFor)
            for (var i = startCoverFor; i <= endCoverFor; i++) {
              this.coverForArr.push(i);
            }
          }

          
          
        } else {
          let endCoverFor = this.getMaxMaturityAge(this.selectedPlan.individualDetails[0].age, this.selectedPlan.supportingData.validRanges.PolicyTerm.max, this.selectedPlan.insuranceAndProducts.productCode);
          //console.log('PPP startCoverFor', startCoverFor, 'endCoverFor', endCoverFor)
          for (var i = startCoverFor; i <= endCoverFor; i++) {
            this.coverForArr.push(i);
          }
          this.coverForArr.push(parseInt(this.wholeLifeAge));
        }
        
        
      }

      this.coverForArr = this.coverForArr.filter(this.onlyUnique);

    },
    getMaxMaturityAge(age, policyTerm, productCode) {

      //console.log('getMaxAge called: age: ', age, 'policyTerm: ', policyTerm, 'productCode: ', productCode);
      let tmp;
      let maximumMaturityAge = getMaxPolicyAge()[productCode];
      if((parseInt(age) + parseInt(policyTerm)) < parseInt(maximumMaturityAge)) {
        //console.log('getMaxAge if: ');
        tmp = parseInt(age) + parseInt(policyTerm);
      } else {
        //console.log('getMaxAge else: ');
        tmp = parseInt(maximumMaturityAge);
      }
      //console.log('tmp: ', tmp)
      return tmp;
    },
    getRiderList(pCode) {
      return getRider()[pCode];
    },
    checkAddOnSelection(addOnID, selectedAddOnsArray) {
      //console.log('checkAddOnSelection called: ', addOnID, selectedAddOnsArray);
      let itemIndex = selectedAddOnsArray.findIndex(item => item.optionSelected == addOnID);
      console.log("itemIndex--------",itemIndex)
      if(itemIndex > -1) {
        return true;
      } else {
        return false;
      }
    },
    getAddOnPremium(addOnID, selectedAddOnsArray) {
      console.log('getAddOnPremium called:');
      let itemIndex = selectedAddOnsArray.findIndex(item => item.optionSelected == addOnID);
      if(itemIndex > -1) {
        return selectedAddOnsArray[itemIndex]['premiumDetails'][0]['totalPremiumValue'];
      } else {
        return false;
      }
    },
    checkRiderSelection(riderCode, selectedRidersArray) {
      let itemIndex = selectedRidersArray.findIndex(item => item.ridercode == riderCode);
      if(itemIndex > -1) {
        return true;
      } else {
        return false;
      }
    },
    getRiderSelectionSA(riderCode, selectedRidersArray) {
      let itemIndex = selectedRidersArray.findIndex(item => item.ridercode == riderCode);
      if(itemIndex > -1) {
        return selectedRidersArray[itemIndex].riderSA;
      }
    },
    getAddOnSelectionSA(addOnID, selectedAddOnsArray) {
      let itemIndex = selectedAddOnsArray.findIndex(item => item.optionSelected == addOnID);
      if(itemIndex > -1) {
        return selectedAddOnsArray[itemIndex].addOnSumAssured;
      }
    },
    getRiderSelectionPremium(riderCode, selectedRidersArray) {
      let itemIndex = selectedRidersArray.findIndex(item => item.ridercode == riderCode);
      if(itemIndex > -1) {
        return selectedRidersArray[itemIndex].premiumDetails[0].totalRiderPremiumValue;
      }
    },
    getRiderSelectionIndex(riderCode, selectedRidersArray) {
      let itemIndex = selectedRidersArray.findIndex(item => item.riderCode == riderCode);
      if(itemIndex > -1) {
        return itemIndex;
      }
    },
    getAddSelectionIndex(addOnID, selectedAddOnsArray) {
      let itemIndex = selectedAddOnsArray.findIndex(item => item.optionSelected == addOnID);
      if(itemIndex > -1) {
        return itemIndex;
      }
    },
    getRiderPriceByPPF(premiumDetails) {
      let itemIndex = premiumDetails.findIndex(item => item.mode == this.premiumPaymentFrequency);
      if(itemIndex > -1) {
        return premiumDetails[itemIndex].totalRiderPremiumValue;
      } else {
        return 0;
      }
    },
    addRider(riderCode) {
      this.riderCodeToAdd = riderCode;
      $("#addridersumassured").modal("show");
    },
    addRiderSumAssured(){
      $("#addridersumassured").modal("hide");
      console.log('add rider called: ', this.riderCodeToAdd);
      let itemIndex = this.riderList.findIndex(item => item.ridercode == this.riderCodeToAdd);
      if(itemIndex > -1) {
        let tmp1 = this.riderList[itemIndex];
        let tmp = {
          riderCode: tmp1['ridercode'],
          //riderPremiumPayingTerm: parseInt(tmp1['riderPremiumPayingTerm']),
          //riderSA: parseInt(tmp1['riderSA']),
          riderSA: parseInt(this.riderUpdateSumAssured),
          //riderTerm: parseInt(tmp1['riderTerm']),
        }
        // Reset riderUpdateSumAssured
        this.riderUpdateSumAssured = 0;
        this.apiReqData.personalInformation.individualDetails[0].riderDetails.push(tmp);
        this.getPolicy(this.apiReqData);
      } 
    },
    removeRider(riderCode) {
      console.log('remove rider called: ',riderCode,  this.apiReqData);
      let itemIndex = this.apiReqData.personalInformation.individualDetails[0].riderDetails.findIndex(item => item.riderCode == riderCode);
      if(itemIndex > -1) {
        this.apiReqData.personalInformation.individualDetails[0].riderDetails.splice(itemIndex, 1);
        this.getPolicy(this.apiReqData);
      } 
    },
    getPayModeList(insurerCode){
      return getPayModeList()[insurerCode];
    },
    getMinOfTwo(val1, val2) {
      if(val1 > val2) {
        return val2;
      }
      return val1;
    },
    getPreviousRequest() {
      this.apiReqData = JSON.parse(sessionStorage.getItem("quote_request"));
    },
    getAddOnsList(pCode) {
      return getAddOnsList()[pCode];
    },
    countAddonsRiders(list1, list2) {
      console.log('list : ', list1 );
      console.log('list2 : ', list2 );
      let len1 = 0;
      let len2 = 0;
      if(list1) {
        len1 = list1.length;
      }
      if(list2) {
        len2 = list2.length;
      }
      return (len1 + len2);
    }
  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/scss/components/policy-details.scss";
</style>