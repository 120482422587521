<template>
  <div class="loading_block_outer" v-if="loading">
    <Loading3 :msg='loadingMessage'/>
    <div class="snippet" data-title=".dot-spin">
      <div class="stage">
        <div class="dot-spin"></div>
      </div>
    </div>
  </div>
<div v-else>
  <div class="inner-page-bg">
    <div class="checkout-container">
      <!--div>
        <router-link to="/proposal" class="back-to-link">
          <img src="~@/assets/images/back-arrow.png" alt="" />
          Back to Proposal
        </router-link>
      </div-->
      <!-- <div class="text-light bg-danger mb-3" v-if="spDetailsError">SP details not available, please login first!</div> -->
      

      <div class="policyname" v-if="selectedPlan">
        <div class="policyname-text d-flex align-items-center">
          <router-link to="/proposal" class="back-arrow">
            <img src="~@/assets/images/left-arrow-grey.png" alt="">
          </router-link>
          <span class="mr-4">
           <img
              :src="
                getInsurerImgs(
                  selectedPlan['insuranceAndProducts']['insuranceCompanyCode']
                )
              "
              alt=""
            />
          </span>
          <h6>{{ selectedPlan['insuranceAndProducts']['productName'] }}</h6>
        </div>
      </div>
      <div class="chckout-table rounded position-relative">
        <h5>Please review below details before proceeding ahead.</h5>
        <span class="subtext d-block text-center">These cannot be changed at a later stage</span>
        <div class="accordion" id="checkoutaccordion">
          <div class="card z-depth-0" v-for="(value, ind) in formInfo.fieldGroups" :key="ind">
            <div class="" v-if="ind != 'traceInfo' && ind != 'distributor' &&ind != 'ACR'">
              <div class="card-header" :id="'headingOne_' + ind" v-if="!value.visibility?true:checkVisibility(value.visibility)">
                <button class="btn btn-link" type="button" data-toggle="collapse" :data-target="'#collapseOne_' + ind" aria-expanded="true" :aria-controls="'collapseOne_' + ind">
                  <!-- <h6>{{ convertSentenceCase(value["name"]) }}</h6> -->
                  <h6>{{ convertSentenceCase(value["label"]) }}</h6>
                </button>
                <!--div class="d-flex cursor-pointer" @click="editDetails(ind)">
                  <img src="~@/assets/images/edit-profile.png">
                  Edit
                </div-->
              </div>
              
              <div :id="'collapseOne_' + ind" :class="'collapse ' + getShow(ind)" :aria-labelledby="'headingOne_' + ind" data-parent="#checkoutaccordion" v-if="!value.visibility?true:checkVisibility(value.visibility)">
                <div class="card-body">
                  <div class="without-fieldset">
                    <!-- If Fields -->
                    <div class="pro-details">
                      <template class="" v-for="(value2, ind2) in value.fields" :key="ind2"> 
                        <div class="mb-4" v-if="value2.type == 'static'">
                          <div>
                            <h6>{{ value2["label"] }}</h6>
                          </div>
                        </div>
                        <div class="mb-4" v-if="value2.input != ''">
                          <div v-if="value2.type == 'single-select'">
                            <h6>{{ value2["label"] }}</h6>
                            <b>{{ getSelectValue(value2.value, value2["input"]) }}</b>
                          </div>
                          <div v-else-if="value2.type == 'multi-select'">
                            <h6>{{ value2["label"] }}</h6>
                            <!-- <b>{{ getMultiSelectValue(value2.value, value2["input"]) }}</b> -->
                            <b>{{ value2["input"].toString() }}</b>
                          </div>
                          <div v-else-if="value2.type == 'boolean'">
                            <h6>{{ value2["label"] }}</h6>
                            <b>{{ (value2["input"] == '1')?'Yes':'No' }}</b>
                          </div>
                          <div v-else-if="value2.type == 'date'">
                            <h6>{{ value2["label"] }}</h6>
                            <b>{{ dateconvert(value2["input"]) }}</b>
                          </div>
                          <div v-else-if="value2.type == 'file'">
                            <h6>{{ value2["label"] }}</h6>
                            <button type="button" class="btn btn-primary eyeicon-btn" data-toggle="modal" data-target="#imageModal" v-if="formInfo.fieldGroups[ind].fields[ind2].input" @click="previewFiles(formInfo.fieldGroups[ind].fields[ind2].input)">
                              <img src="~@/assets/images/eye.png" alt="" />
                            </button>
                          </div>
                          <div v-else>
                            <h6>{{ value2["label"] }}</h6>
                            <b>{{  value2["input"] }}</b>
                          </div>
                        </div>
                      </template>
                    </div>
                  </div>
                  <template v-for="(value2, ind2) in value.fieldGroups" :key="ind2">
                    <fieldset v-if="!value2.visibility?true:checkVisibility(value2.visibility)">
                      <!-- <legend>{{ convertSentenceCase(value2["name"]) }}</legend> -->
                      <legend>{{ convertSentenceCase(value2["label"]) }}</legend>
                      <div class="pro-details">
                        <template class="" v-for="(value3, ind3) in value2.fields" :key="ind3">
                          <div class="mb-4" v-if="value3.type == 'static'">
                            <div>
                              <h6>{{ value3["label"] }}</h6>
                            </div>
                          </div>
                          
                          <div class="mb-4" v-if="value3.input != ''">
                            <div v-if="value3.type == 'single-select'">
                              <h6>{{ value3["label"] }}</h6>
                              <b>{{ getSelectValue(value3.value, value3["input"]) }}</b>
                            </div>
                            <div v-else-if="value3.type == 'multi-select'">
                              <h6>{{ value3["label"] }}</h6>
                              <!-- <b>{{ getMultiSelectValue(value3.value, value3["input"]) }}</b> -->
                              <b>{{ value3["input"].toString() }}</b>
                            </div>
                            <div v-else-if="value3.type == 'boolean'">
                              <h6>{{ value3["label"] }}</h6>
                              <b>{{ (value3["input"] == '1')?'Yes':'No' }}</b>
                            </div>
                            <div v-else-if="value3.type == 'date' && value3.input">
                              <h6>{{ value3["label"] }}</h6>
                              <b>{{ dateconvert(value3["input"]) }}</b>
                            </div>
                            <div v-else-if="value3.type == 'file'">
                              <h6>{{ value3["label"] }}</h6>
                              <button type="button" class="btn btn-primary eyeicon-btn" data-toggle="modal" data-target="#imageModal" v-if="formInfo.fieldGroups[ind].fieldGroups[ind2].fields[ind3].input" @click="previewFiles(formInfo.fieldGroups[ind].fieldGroups[ind2].fields[ind3].input)">
                                <img src="~@/assets/images/eye.png" alt="" />
                              </button>
                            </div>
                            <div v-else>
                              <h6>{{ value3["label"] }}</h6>
                              <b>{{ value3["input"] }}</b>
                            </div>
                          </div>
                        </template>
                      </div>
                      <!-- 333333 -->
                      <template v-for="(value3, ind3) in value.fieldGroups[ind2].fieldGroups" :key="ind3">
                        <fieldset v-if="!value3.visibility?true:checkVisibility(value3.visibility)">
                          <legend>{{ convertSentenceCase(value3["label"]) }}</legend>
                          <div class="pro-details">
                            <template class="" v-for="(value4, ind4) in value3.fields" :key="ind4">
                              <div class="mb-4" v-if="value4.type == 'static'">
                                <div>
                                  <h6>{{ value4["label"] }}</h6>
                                </div>
                              </div>
                              <div class="mb-4" v-if="value4.input != ''">
                                <div v-if="value4.type == 'single-select'">
                                  <h6>{{ value4["label"] }}</h6>
                                  <b>{{ getSelectValue(value4.value, value4["input"]) }}</b>
                                </div>
                                <div v-else-if="value4.type == 'multi-select'">
                                  <h6>{{ value4["label"] }}</h6>
                                  <!-- <b>{{ getMultiSelectValue(value4.value, value4["input"]) }}</b> -->
                                  <b>{{ value4["input"].toString() }}</b>
                                </div>
                                <div v-else-if="value4.type == 'boolean'">
                                  <h6>{{ value4["label"] }}</h6>
                                  <b>{{ (value4["input"] == '1')?'Yes':'No' }}</b>
                                </div>
                                <div v-else-if="value4.type == 'date' && value4.input">
                                  <h6>{{ value4["label"] }}</h6>
                                  <b>{{ dateconvert(value4["input"]) }}</b>
                                </div>
                                <div v-else-if="value4.type == 'file'">
                                  <h6>{{ value4["label"] }}</h6>
                                  <button type="button" class="btn btn-primary eyeicon-btn" data-toggle="modal" data-target="#imageModal" v-if="formInfo.fieldGroups[ind].fieldGroups[ind2].fieldGroups[ind3].fields[ind4].input" @click="previewFiles(formInfo.fieldGroups[ind].fieldGroups[ind2].fieldGroups[ind3].fields[ind4].input)">
                                    <img src="~@/assets/images/eye.png" alt="" />
                                  </button>
                                </div>
                                <div v-else>
                                  <h6>{{ value4["label"] }}</h6>
                                  <b>{{ value4["input"] }}</b>
                                </div>
                              </div>
                            </template>
                          </div>
                        </fieldset>
                      </template>
                      <!-- 333333 -->
                    </fieldset>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="otp-section" >
          <div class="text-center" v-if="!sendOtp">
            <button class="next-btn mb-4" @click="getOTP()">Get OTP</button>
          </div>
          <div class="form-control1" v-if="sendOtp">
            <input type="text" v-model="inputedOtp">
            <p class="error-text" v-if="error.inputedOtp">
              {{ error.inputedOtp }}
            </p>
          </div>
          <div v-if="sendOtp" class="d-flex justify-content-between align-items-center">
            <button class="next-btn mb-4" @click="resendOTP()">Resend OTP</button> &nbsp;&nbsp;
            <button class="next-btn mb-4" @click="submitOTP()">Submit OTP</button>
          </div>
          <div>
            <span class="text-success" v-if="otpSuccess">OTP Verified Successfully.</span>
            <span class="text-danger" v-if="otpFailed">Invalid OTP Entered. Please Provide Valid OTP.</span>
          </div>
        </div> -->
        <!-- <div  class="button wrap d-flex justify-content-center">
          <button type="submit" class="next-btn mb-4" @click="buyPolicy()">
            Proceed <i><img src="~@/assets/images/next-arrow.png" /></i>
          </button>
        </div> -->
        <div class="error-msg" :innerHTML="errorMsgs">
          <!-- {{ errorMsgs }} -->
        </div>
        <!--div class="button wrap d-flex justify-content-center">
          <button type="submit" class="next-btn mb-4" @click="buyPolicy()">
            Proceed <i><img src="~@/assets/images/next-arrow.png" /></i>
          </button>
        </div-->
        <div class = "button wrap d-flex justify-content-center">
            <button type="submit" class="next-btn mb-4" id="accept" data-toggle="modal" data-target="#otpModal" v-on:click="generateOTP()">
                Accept 
            </button>&nbsp;&nbsp;
            <button type="submit" class="next-btn mb-4" data-toggle="modal" data-target="#exampleModal">
                Reject 
            </button>
        </div>
        <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Reason for Rejection</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <textarea class="form-control" id="exampleFormControlTextarea1" placeholder="Please mention your reasons here in less than 500 words" v-model="message" :maxlength=500 rows="3"></textarea>
            </div>
            <div class="modal-footer justify-content-center" >
                <button type="button" class="next-btn mb-4" data-dismiss="modal" style="width:100px">Close</button>
                <button type="button" class="next-btn mb-4" data-dismiss="modal" style="width:100px" @click="rejectedProposal()">Submit</button>
            </div>
            </div>
        </div>
        </div>        
        <div class="modal fade" id="otpModal" tabindex="-1" role="dialog" aria-labelledby="otpModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Validate OTP</h5>
                <button type="button" class="close" @click="otpModalReset()" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <input class="form-control" id="exampleFormControlTextarea2" placeholder="Please enter your 6 digit OTP" v-model="otp" :maxlength=6 />
                <span v-if="otpSent != null" style="font-size:13px"> {{ otpSent }} </span>
                <label class = "p-2" v-if="otpMessage != 'No'" for="exampleFormControlTextarea2"> {{ otpValid }} </label>
                <a v-if="!disabledResend">
                    <button class="cursor-pointer p-2" @click="generateOTP()" style="color:red; border:none; background-color:white; float: right;">Resend</button>
                </a>
                <a v-else>
                    <span v-if="otpSent != null" class="p-2" style="text-align: center; font-weight: bold; color: #0a3f88; float: right;"> {{timerMinutes}}:{{timerSeconds}}</span>
                </a>
            </div>
            <div class="modal-footer justify-content-center">
                <button type="button" class="next-btn mb-4" data-dismiss="modal" @click="otpModalReset()" style="width:100px" >Close</button>
                <button type="button" class="next-btn mb-4" @click="validateOTP()" style="width:100px" >Submit</button>
            </div>
            </div>
        </div>
        </div>
      </div>
      
    </div>
  </div>

  
	<!-- Modal -->
    <div class="modal fade" id="imageModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Selected File</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                  <div v-if="!fileToDisplay" class="error_msg text-center doc_upload_block2">No Document Selected</div>
                  <div v-else>
                    <img v-if="typeToDisplay == 'image'" class="image1" :src="fileToDisplay">
                    <iframe v-else width="100%" height="400px" :src="fileToDisplay"></iframe>
                  </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
</div>
</div>
</template>


<script>
import moment from 'moment';
import $ from "jquery";
import {getPolicyCover, getConpareData, getInsuranceImages, getDistributer} from '@/mixins/common'
import Loading3 from "@/components/loading3.vue";
export default {
  name: 'proposaldetailscheckdiy',
  mixins:[getPolicyCover, getConpareData, getInsuranceImages, getDistributer],
  components: {
    Loading3
  }, 
  mounted() {

      this.checkProposalStatus(this.$route.query.proposalId)

      let data = this.$route.query.response;
      if(typeof(data) != "undefined")
      {
        this.loadingMessage = "Application submission is in progress";
      }
      else
      {
          this.loadingMessage = "Redirecting to payment gateway";
      }
      this.loading = true;

    this.getProposalById(this.$route.query.proposalId,this.$route.query.transactionId);

    
    
    
    // Moved code from here 
   
  },
  data() {
    return {
      proposalJson:{},
      selectedPlan: "",
      formInfo: "",
      spDetails: "",
      loading: false,
      apiError: false,
      spDetailsError: false,
      thankYouUrl: "",
      proposalUiId: "",
      uiQuoteId: "",
      distributorID: "",
      otp: "",
      otpSent: null,
      otpTransactionId: "",
      otpMessage: null,
      otpValid:null,
      sendOtp: false,
      resendOtp: false,
      submitOtp: false,
      inputedOtp: "",
      otpSuccess: false,
      otpFailed: false,
      randOtp: "",
      error: [],
      errorMsgs: "",
      typeToDisplay: "",
      fileToDisplay: "",
      message: "",
      otpExpiryTime:"",
      timerMinutes: "",
      timerSeconds: "",
      disabledResend:true,
      lastresponse:"",
      loadingMessage: "Fetching your Application Details",
      quoteRequestID:""
    } 
  },
  methods: {

    getProposal() {
      var self = this;
      this.selectedPlan = JSON.parse(sessionStorage.getItem("selectedPlanForCheckout"));
      this.quoteRequestID = JSON.parse(sessionStorage.getItem("quoteRequestID"));
      this.insurerCode = this.selectedPlan.insuranceAndProducts.insuranceCompanyCode;
      this.productCode = this.selectedPlan.insuranceAndProducts.productCode;
      var headers = {};
      this.loading = true;
      let reqData = [this.productCode, this.insurerCode, 1];
      this.backendApi("getProposal", reqData, headers, "get")
        .then((response) => {
          console.log('success proposal');
          self.loading = false;
          self.apiError = false;
          if (response == 'error') {
            self.apiError = true;
        //  this.$router.push({ path: '/alert', query: { status: 'Error',backUrl:"proposal-page", msg: 'We are facing some issue while fetching proposal details' }});

          }
          else if(response.data.errors.length>0) 
          {
            
            let msg=[]
              for(let i=0;i<response.data.errors.length;i++)
              {
                  msg.push(response.data.errors[i]["errorIdentifier"])
              }
              
            this.$router.push({ path: '/networkalert', query: {backUrl:'proposal-page',msgError:"We are facing some issue while fetching proposal data",reqId:response.data.reqId, msg:msg  }});

          }
          else if (response && response.data) {
            self.loading = false;
            this.formInfo = response.data.data;
            // console.log(this.formInfo);
            // Setting up previously filled data
            let filledData = JSON.parse(sessionStorage.getItem("filledData"));
          
            if (filledData) {
              if (this.productCode == filledData.productId) {
                for (var key in filledData.fieldGroups) {
                  // if (key != "traceInfo" && key != "distributor") {
                    let data = filledData['fieldGroups'][key];

                    if (data.fields) {
                      for (var key2 in data.fields) {
                        let data2 = filledData['fieldGroups'][key]['fields'][key2];
                        if (data2.type == 'multi-select') {
                          this.formInfo['fieldGroups'][key]['fields'][key2]['input'] = filledData['fieldGroups'][key]['fields'][key2]['input'];
                        } else if (data2.type == 'boolean') {
                          this.formInfo['fieldGroups'][key]['fields'][key2]['input'] = filledData['fieldGroups'][key]['fields'][key2]['input'];
                        }  else if (data2.type == 'number') {
                          this.formInfo['fieldGroups'][key]['fields'][key2]['input'] = parseInt(filledData['fieldGroups'][key]['fields'][key2]['input']);
                        } else {
                          this.formInfo['fieldGroups'][key]['fields'][key2]['input'] = filledData['fieldGroups'][key]['fields'][key2]['input'];
                        }
                      }
                    }

                    // fieldGroups
                    if (data.fieldGroups) {
                      for (var key2 in data.fieldGroups) {
                        let data2 = filledData['fieldGroups'][key]['fieldGroups'][key2];
                        for (var key3 in data2.fields) {
                          let data3 = filledData['fieldGroups'][key]['fieldGroups'][key2]['fields'][key3];
                          if (data3.type == 'multi-select') {
                            try {
                              this.formInfo['fieldGroups'][key]['fieldGroups'][key2]['fields'][key3]['input'] = filledData['fieldGroups'][key]['fieldGroups'][key2]['fields'][key3]['input'];
                            } catch (err) {
                              console.log('error during assignment:', err);
                              console.log("this.formInfo['fieldGroups'][" + key + "]['fieldGroups'][" + key2 + "]['fields'][" + key3 + "][input]: " + filledData['fieldGroups'][key]['fieldGroups'][key2]['fields'][key3]['input']);
                            }
                          } else if (data3.type == 'boolean') {
                            try {
                              this.formInfo['fieldGroups'][key]['fieldGroups'][key2]['fields'][key3]['input'] = filledData['fieldGroups'][key]['fieldGroups'][key2]['fields'][key3]['input'];
                            }
                            catch (err) {
                              console.log('error during assignment:', err);
                              console.log("this.formInfo['fieldGroups'][" + key + "]['fieldGroups'][" + key2 + "]['fields'][" + key3 + "][input]: " + filledData['fieldGroups'][key]['fieldGroups'][key2]['fields'][key3]['input']);
                            }
                          } else if (data3.type == 'number') {
                            try {
                              this.formInfo['fieldGroups'][key]['fieldGroups'][key2]['fields'][key3]['input'] = parseInt(filledData['fieldGroups'][key]['fieldGroups'][key2]['fields'][key3]['input']);
                            }
                            catch (err) {
                              console.log('error during assignment:', err);
                              console.log("this.formInfo['fieldGroups'][" + key + "]['fieldGroups'][" + key2 + "]['fields'][" + key3 + "][input]: " + filledData['fieldGroups'][key]['fieldGroups'][key2]['fields'][key3]['input']);
                            }
                          } else {
                            try {
                              this.formInfo['fieldGroups'][key]['fieldGroups'][key2]['fields'][key3]['input'] = filledData['fieldGroups'][key]['fieldGroups'][key2]['fields'][key3]['input'];
                            }
                            catch (err) {
                              console.log('error during assignment:', err);
                              console.log("this.formInfo['fieldGroups'][" + key + "]['fieldGroups'][" + key2 + "]['fields'][" + key3 + "][input]: " + filledData['fieldGroups'][key]['fieldGroups'][key2]['fields'][key3]['input']);
                            }
                          }
                        }

                        // 333333
                        // fieldGroups.fieldGroups
                        if (data2.fieldGroups) {
                          for (var key3 in data2.fieldGroups) {
                            let data3 = filledData['fieldGroups'][key]['fieldGroups'][key2]['fieldGroups'][key3];
                            for (var key4 in data3.fields) {
                              let data4 = filledData['fieldGroups'][key]['fieldGroups'][key2]['fieldGroups'][key3]['fields'][key4];
                              if (data4.type == 'multi-select') {
                                try {
                                  this.formInfo['fieldGroups'][key]['fieldGroups'][key2]['fieldGroups'][key3]['fields'][key4]['input'] = filledData['fieldGroups'][key]['fieldGroups'][key2]['fieldGroups'][key3]['fields'][key4]['input'];
                                } catch (err) {
                                  console.log('error during assignment:', err);
                                  console.log("this.formInfo['fieldGroups'][" + key + "]['fieldGroups'][" + key2 + "]['fields'][" + key3 + "]['fields'][" + key4 + "][input]" + filledData['fieldGroups'][key]['fieldGroups'][key2]['fieldGroups'][key3]['fields'][key4]['input']);
                                }
                              } else if (data4.type == 'boolean') {
                                try {
                                  this.formInfo['fieldGroups'][key]['fieldGroups'][key2]['fieldGroups'][key3]['fields'][key4]['input'] = filledData['fieldGroups'][key]['fieldGroups'][key2]['fieldGroups'][key3]['fields'][key4]['input'];
                                } catch (err) {
                                  console.log('error during assignment:', err);
                                  console.log("this.formInfo['fieldGroups'][" + key + "]['fieldGroups'][" + key2 + "]['fields'][" + key3 + "]['fields'][" + key4 + "][input]" + filledData['fieldGroups'][key]['fieldGroups'][key2]['fieldGroups'][key3]['fields'][key4]['input']);
                                }
                              } else if (data4.type == 'number') {
                                try {
                                  this.formInfo['fieldGroups'][key]['fieldGroups'][key2]['fieldGroups'][key3]['fields'][key4]['input'] = parseInt(filledData['fieldGroups'][key]['fieldGroups'][key2]['fieldGroups'][key3]['fields'][key4]['input']);
                                } catch (err) {
                                  console.log('error during assignment:', err);
                                  console.log("this.formInfo['fieldGroups'][" + key + "]['fieldGroups'][" + key2 + "]['fields'][" + key3 + "]['fields'][" + key4 + "][input]" + filledData['fieldGroups'][key]['fieldGroups'][key2]['fieldGroups'][key3]['fields'][key4]['input']);
                                }
                              } else {

                                try {
                                  this.formInfo['fieldGroups'][key]['fieldGroups'][key2]['fieldGroups'][key3]['fields'][key4]['input'] = filledData['fieldGroups'][key]['fieldGroups'][key2]['fieldGroups'][key3]['fields'][key4]['input'];
                                } catch (err) {
                                  console.log('error during assignment:', err);
                                  console.log("this.formInfo['fieldGroups'][" + key + "]['fieldGroups'][" + key2 + "]['fields'][" + key3 + "]['fields'][" + key4 + "][input]" + filledData['fieldGroups'][key]['fieldGroups'][key2]['fieldGroups'][key3]['fields'][key4]['input']);
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  // }
                }
              } else {
                // Removing previous data
                localStorage.removeItem('filledData');
                localStorage.removeItem('filledDataTab');
                sessionStorage.removeItem('filledData');
                sessionStorage.removeItem('filledDataTab');
              }
            }
            // End setting previously filled data
            this.mountedCode();
            //############################./End Setting up fieldGroups and other data ###########################

          }
        })
        .catch((error) => {
          console.log('error msg block proposal', error);
          let msg=['We are facing some issue from backend while fetching proposal data']

          this.$router.push({ path: '/networkalert', query: {backUrl:'proposal-page', msg: msg }});
        });


      

    },



    checkProposalStatus(proposalUiId)
    {
        let qData = [proposalUiId];
        var headers = {
            Authorization: "Basic " + window.btoa(process.env.VUE_APP_DATA_PER_USERNPASS),
            "Content-Type": "application/json",
        };
        this.backendApi("getProposalStatus", qData, headers, "get")
        .then((response) => {
          if(response.data.status=='error')
          {
            console.log("proposal status unable to fetch")
            // let msg=['We are facing some issue while fetching proposal satus']
            //   this.$router.push({ path: '/alert-diy', query: {msg: msg }});
          }
          else if(response.data && response.data.data){
              sessionStorage.setItem("proposalStatusValue", JSON.stringify(response.data.data.value));

              
          }
          // console.log("response",response)
          // console.log("response",response.data.data.value)
        })
        .catch((error) => {
          console.log("error")
        })
    },
  	convertSentenceCase(value) {
      const result = value.replace(/([A-Z])/g, " $1");
      const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
      return finalResult;
    },
    getShow(tab) {
      if(tab == 'proposerDetails') {
        return "show";
      } else {
        return '';
        }
    },
    mountedCode(){
      
      this.selectedPlan = JSON.parse(sessionStorage.getItem("selectedPlanForCheckout"));
      // Getting previously filled data
      // this.formInfo = JSON.parse(sessionStorage.getItem("filledData"));
      if(!this.formInfo) {
        // Redirecction Logic
        
      } else {
        //console.log(this.formInfo)
        // console.log('this.form info iii: ', this.formInfo);
        //let containerObject = new JSONObject(this.formInfo);
        //has method
        if (this.formInfo.fieldGroups.otpDetails) {
          //get Value of video
          console.log('otp present');
        } else {
          console.log('otp not present');
        }
      }

      if (sessionStorage.getItem("sp_details_data")) {
        this.spDetails = JSON.parse(sessionStorage.getItem("sp_details_data"));
      }

      if(sessionStorage.getItem('proposal_ui_id')) {
        this.proposalUiId = JSON.parse(sessionStorage.getItem('proposal_ui_id'));
      }

      if(sessionStorage.getItem('uiQuoteId')) {
        this.uiQuoteId = JSON.parse(sessionStorage.getItem('uiQuoteId'));
        console.log('this.uiQuoteId: ', this.uiQuoteId);
      }

      // Setting Distributer ID
      this.distributorID = getDistributer()["distributerID"];

      let thanksURL= process.env.VUE_APP_URL_RMD
      this.thankYouUrl=thanksURL+"/v1/paymentStatus?proposal_ui_id=" + this.proposalUiId;

      let data = this.$route.query.response;
       
      if(typeof(data) != "undefined")
      {
        data = data.replace(/'/g, '"');
       data = JSON.parse(data);
       this.formInfo.fieldGroups.traceInfo.fields.paymentID.input = data.transactionId;
        // Call payment details api
        //  this.saveProposal()
        
       let resumeUrlTATA=window.location.href
        this.updateResumeJourneyURL(resumeUrlTATA)
        let req = {
          "proposal_id": this.formInfo.fieldGroups.traceInfo.fields.manuApplicationNo.input,
          "proposal_ui_id": this.proposalUiId,
          "transaction_id": data.transactionId,
          "payment_ammount": this.selectedPlan.individualDetails[0].premiumDetails[0].totalPremiumValue,
          "payment_status": data.status,
          "payment_remark": data.statusDescription
        }
        this.savePaymentDtailsTATA(req);


        // if(data.status == 'success')
        // {
        //   this.updateProposalStatus(13);
        //   this.buyPolicy();
        // }
        // else
        // {
        //   this.updateProposalStatus(14);
        //   //redirect to thank you page
        //   this.thankYouUrl = new URL(location).origin+"/#/payment?transactionId=" + data.transactionId;
        //   window.location.href = this.thankYouUrl; 
        // }        
      }
    },
    
    savePaymentDtailsTATA(req){

      var headers = {
        Authorization: "Basic " + window.btoa(process.env.VUE_APP_DATA_PER_USERNPASS),
        "Content-Type": "application/json",
      };
      
      //console.log('qData: ', qData);
      this.backendApi("savePaymentData", req, headers)
        .then((response) => {
           if(response == 'error') {
            self.apiError = true;
              // let msg=['We are facing some issue while saving payment data']
              // this.$router.push({ path: '/alert-diy', query: {msg: msg }});
          }
          else if(response.status=='error')
          {
              //  let msg=['We are facing some issue while saving payment data']
              // this.$router.push({ path: '/alert-diy', query: {msg: msg }});
          }
          else if(response && response.data){
            if(response.data.payment_status == 'success')
            {
              this.updateProposalStatus(13);
              
              this.buyPolicy();
            }
            else
            {
              this.updateProposalStatus(14);
              //redirect to thank you page
              this.thankYouUrl = new URL(location).origin+"/#/payment?transactionId=" + response.data.transaction_id;
              window.location.href = this.thankYouUrl; 
            }
          }
        })
        .catch((error) => {
          console.log('error msg block savePaymentData', error);
          // let msg=['We are facing some issue from backend while saving payment data']
          // this.$router.push({ path: '/alert-diy', query: {msg: msg }});
        });
    },

    getProposalById(proposalUiId,rmId) {
        let qData = [proposalUiId];
        var headers = {
            Authorization: "Basic " + window.btoa(process.env.VUE_APP_DATA_PER_USERNPASS),
            "Content-Type": "application/json",
        };
        this.backendApi("getProposalDetails", qData, headers, "get")
        .then((response) => {
            if(response.status == "error")
            {
              this.loading = false;
              // alert("Network Error");
              let msg=['We are facing some issue while fetching proposal details']
              this.$router.push({ path: '/alert-diy', query: {msg: msg }});

              //redirect to page with msg
            }
            else
            {

              if(response.data.status == "failed")
              {
                this.loading = false;
                  let msg=['We are facing some issue while fetching proposal details']
              this.$router.push({ path: '/alert-diy', query: {msg: msg }});
                // alert("Proposal data is not available");
              }
              // console.log('proposal details: ', response.data.data[0]);
              this.proposalDetails = response.data.data[0];
              this.uiQuoteId = this.proposalDetails.ui_quote_id;

              localStorage.setItem("filledData", JSON.parse(this.proposalDetails.proposal_json));
              sessionStorage.setItem("filledData", this.proposalDetails.proposal_json);
              sessionStorage.setItem("proposal_ui_id", JSON.stringify(this.proposalDetails.proposal_ui_id));
              sessionStorage.setItem("uiQuoteId", JSON.stringify(this.proposalDetails.ui_quote_id));

              //Need rdmid here not code
              let qData = [rmId];
              let headers = {};
              if(this.proposalDetails["rm_code"]) {
                this.backendApi("getRmDetailsDIY", qData, headers, "get")
                .then((response) => {
                  // console.log(response)
                  if(response == 'error') {
                    // this.$router.push({ path: '/alert-diy', query: { status: 'Error', msg: 'We are facing some issue while fetching RM details, please contact to RM' }});

                    // alert('Data not fetched, please contact your RM');
                  }
                  else if(response.status=="error")
                  {
                    //  this.$router.push({ path: '/alert-diy', query: { status: 'Error', msg: 'We are facing some issue while fetching RM details, please contact to RM' }});

                  }
                   else if(response && response.data) {
                    // console.log('rmData: 4: ', response.data['AadharNumber']);
                    sessionStorage.setItem("rm_data", JSON.stringify(response.data));
                    localStorage.setItem("rm_data", JSON.stringify(response.data));
                    
                  }
                })
                .catch((error) => {
                  console.log('error msg block dp', error);
                  // this.$router.push({ path: '/alert-diy', query: { status: 'Error', msg: 'We are facing some issue while fetching RM details, please contact to RM' }});


                });
              }
              this.getQuoteDetails(this.uiQuoteId);
            }
            
        }) //proposal_ui_id, uiQuoteId, 
        .catch((error) => {
          console.log("error msg block dp", error);
            let msg=['We are facing some issue from backend while fetching proposal details']
            this.$router.push({ path: '/alert-diy', query: { msg: msg }});
        });
    },
    getQuoteDetails(ui_quote_id) {
        let qData = [ui_quote_id];
        var headers = {
            Authorization: "Basic " + window.btoa(process.env.VUE_APP_DATA_PER_USERNPASS),
            "Content-Type": "application/json",
        };
        this.backendApi("getQuoteDetails", qData, headers, "get")
        .then((response) => {
            // console.log('quote details: ', response.data.data[0]);
            this.quoteDetails = response.data.data[0];
            this.uiQuoteId = this.proposalDetails.ui_quote_id;

            this.userDetails = {
                "gender": this.quoteDetails.gender,
                "fullname": this.quoteDetails.life_assured_name,
                "dob": this.quoteDetails.dob,
                "age": null,
                "mobile": this.quoteDetails.mobile_number,
                "zipcode": this.quoteDetails.zipcode,
                "annualincome": this.quoteDetails.annual_income,
                "inlakhs": null,
                "employmenttype": this.quoteDetails.employee_type,
                "education": this.quoteDetails.education,
                "smoke": this.quoteDetails.smoker,
                "whatsapp": false
            };
            
            localStorage.setItem('userProfile',JSON.stringify(this.userDetails));
            sessionStorage.setItem('user_profile',JSON.stringify(this.userDetails));
            sessionStorage.setItem('quote_request',this.quoteDetails.quote_request_json);
 
            this.getQuoteResponseDetails(this.uiQuoteId);
        })
        .catch((error) => {
          console.log("error msg block dp", error);
        });
    },

     getQuoteResponseDetails(ui_quote_id) {
        let qData = [ui_quote_id];
        var headers = {
            Authorization: "Basic " + window.btoa(process.env.VUE_APP_DATA_PER_USERNPASS),
            "Content-Type": "application/json",
        };
        this.backendApi("getQuoteResponseDetails", qData, headers, "get")
        .then((response) => {
            this.loading = false;
            // console.log('quote response details: ', response.data.data);
            this.quoteResponseDetails = response.data.data;
            //let pln = JSON.parse(this.quoteResponseDetails.quote_response_data);
            let pln = this.quoteResponseDetails.quote_response_data;
            sessionStorage.setItem("selected_plan_data", JSON.stringify(pln));
            sessionStorage.setItem("selectedPlanForCheckout", JSON.stringify(pln));
            // console.log("Session added for selectedPlanForCheckout")
            this.getProposal();            
        })
        .catch((error) => {
          console.log("error msg block quote response", error);
        });
    },
    getResumeURL()
    {
      var  headers = {
        Authorization: "Basic " + window.btoa(process.env.VUE_APP_DATA_PER_USERNPASS),
        "Content-Type": "application/json",
      };
      let qData = [this.proposalUiId];
      this.backendApi("getResumeURL", qData, headers, "get")
        .then((response) => {
          if(response == 'error') {
            self.apiError = true;
          } else if(response && response.data) {
            if(response.data[1]['resumeURL']!='')
            // this.$router.push({ path: '/alert-diy', query: { status: 'Error', msg: 'Proposal already submitted to insurance company' }});

              // alert("Proposal already submitted to insurance company. Click OK to proceed.");
              window.location.href = response.data[1]['resumeURL'];
          }
        })
        .catch((error) => {
          console.log('error msg block dp', error);
        });
    },
    updateResumeJourneyURL(resumeUrl)
    {
      let qData = {
        "proposal_ui_id":this.proposalUiId,
        "resumeURL": resumeUrl
      };

      var headers = {
        Authorization: "Basic " + window.btoa(process.env.VUE_APP_USERNPASS),
        "Content-Type": "application/json",
      };

      this.backendApi("saveResumeURL", qData, headers)
          .then((response) => {
            if(response == 'error') {
              self.apiError = true;
            } else if(response && response.data) {
              console.log('proposal journey url updated: ', response.data)
            }
          })
          .catch((error) => {
            console.log('Exception: ', error);
          });

    },
    updateProposalStatus(statusid)
    {
        let qData = {
          "proposal_ui_id":this.proposalUiId,
          "status_id": statusid
          };
        var headers = {
          Authorization: "Basic " + window.btoa(process.env.VUE_APP_USERNPASS),
          "Content-Type": "application/json",
          };
        
        this.backendApi("ProposalStatus", qData, headers)
          .then((response) => {
            if(response == 'error') {
              self.apiError = true;
              // let msg=['We are facing some issue while saving proposal status']
              // this.$router.push({ path: '/alert-diy', query: {msg: msg }});
            }
            
             else if(response && response.data) {
              console.log('proposal status updated: ', response.data)
            }
          })
          .catch((error) => {
            console.log('error msg block email', error);
            // let msg=['We are facing some issue from backend while saving proposal status']
            //   this.$router.push({ path: '/alert-diy', query: {msg: msg }});
          });
    },
    previewFiles(imgData) {
      // console.log('previewFiles called: ', imgData)
      const type = imgData.split(';')[0].split('/')[0].split(':')[1];
      console.log('imgData type:', type);
      this.typeToDisplay = type;
      this.fileToDisplay = imgData;
    },

    sendMailToCustomerError()
    {
      var rmData;
      if (localStorage.getItem("rm_data")){
       rmData = JSON.parse(localStorage.getItem("rm_data"));
      }

      var userProfile;
      if(localStorage.getItem("userProfile"))
      {
        userProfile = JSON.parse(localStorage.getItem("userProfile"));  
      }

      var text=   `<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Strict//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-strict.dtd">
                  <html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">
                    <head>
                        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
                        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                        <title>Email</title>
                        <style type="text/css">@media screen and (min-width: 750px){body{font-family:Arial,Helvetica,sans-serif!important;font-weight:500}body,table,thead,tbody,tr,td,img{padding:0;margin:0;border:none;border-spacing:0px;border-collapse:collapse;vertical-align:top}.wrapper{padding-left:10px;padding-right:10px;padding-top:10px;padding-bottom:10px}h1,h2,h3,h4,h5,h6,p{margin:0;padding:0;padding-bottom:20px;line-height:1.6}img{width:100%;display:block}.main-table{width:710px;margin:0 auto;margin-top:5px}.main-table tr td{font-size:14px!important;line-height:18px!important}.main-table-td{padding:10px!important}.main-table tr td.order-detl-mob{font-size:21px!important}.brand-logo img{width:auto!important}.main-table tr.totl-cost-td td{font-size:18px!important;line-height:18px!important}table{border-spacing:0}.order-details-table td{font-size: 9px!important;}}</style>
                    </head>
                    <body cellspacing="0" cellpadding="0" border="0" class="body" style="padding-top:5px !important;padding-bottom:5px !important; margin:0px !important; display:block !important; min-width:100% !important; width:100% !important; -webkit-text-size-adjust:none; background-color: #eceaea; " bgcolor="#eceaea">
                        <!--[if !mso]><!-->
                        <table align="center" style="background-color: #eceaea;margin-top: 0px;width: 100%;">
                          <tr>
                              <td>
                                <table style="" class="main-table" align="center">
                                    <tr>
                                      <td style="background: #fff;" class="main-table-td">
                                          <table width="100%" style="background: #fff;border-collapse: collapse;" align="center" >
                                            <tr>
                                                <td>
                                                  <table width="100%" style="border-collapse: collapse;">
                                                      <tr>
                                                        <td style="vertical-align: middle;">
                                                            <table border="0">
                                                              <tr>
                                                                  <td class="brand-logo"> <img src="` + this.distributorLogo + `" alt="" style="width: 100%;"></td>
                                                              </tr>
                                                            </table>
                                                        </td>
                                                      </tr>
                                                      <tr>
                                                        <td height="10px" colspan="2"></td>
                                                      </tr>
                                                      <tr>
                                                        <td height="10px" colspan="2"></td>
                                                      </tr>
                                                  </table>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                  <table style="font-size: 9px;border-collapse: collapse;">
                                                      <tr></tr>
                                    <tr>
                                                        <td>Dear `+rmData['Name']+`,
                                      </td>
                                                      </tr>
                                                      <tr>
                                                        <td height="40px"></td>
                                                      </tr>
                                                      <tr>
                                                        <td>Your client `+ userProfile["fullname"] +` has approved the proposal form but got error from manufacturer.
                                                        </td>
                                                      </tr>
                                    
                                    <tr>
                                                        <td height="40px"></td>
                                                      </tr>
                                                      
                                                      <tr>
                                                        <td >Error Details: `+this.lastresponse+`</td>
                                                      </tr>
                                                      <tr>
                                                        <td height="40px"></td>
                                                      </tr>
                                    <tr>
                                                        <td>Regards,</td>
                                                      </tr>
                                    <tr>
                                                        <td>` + this.distributorID +`</td>
                                                      </tr>
                                    <tr>
                                                        <td height="50px"></td>
                                                      </tr>
                                    <tr>
                                                        <td style="font-size: 9px;border-collapse: collapse;">*Insurance is the subject matter of solicitation. IRDAI Insurance Broking License No. 1. License Code No. IRDA/DB 1/22 Valid till 01/01/2023</td>
                                                      </tr>
                                    <tr>
                                                        <td height="50px"></td>
                                                      </tr>
                                    <tr>
                                      <td><center>Powered by 9Platforms Technology Pvt Ltd.</center></td>
                                    </tr>
                                                  </table>
                                                </td>
                                            </tr>
                                            
                                            
                                          </table>
                                      </td>
                                    </tr>
                                </table>
                              </td>
                          </tr>
                        </table>
                    </body>
                  </html>`
      let qData = {
          "send_from":"customer.service@1silverbullet.tech",
          "subject":"Proposal Error in Submission",
          "text": text,
          "send_to":['dipak.bhosale@1silverbullet.tech']
          //Removed rm's email other than in production.
          //"send_to":['dipak.bhosale@1silverbullet.tech',rmData['Email']]
        };
        var headers = {
          Authorization: "Basic " + window.btoa(process.env.VUE_APP_USERNPASS),
          "Content-Type": "application/json",
        };
        this.backendApi("sendMail", qData, headers)
          .then((response) => {
            //console.log('save quote response: ', response);
            if(response == 'error') {
              self.apiError = true;
              // this.$router.push({ path: '/alert-diy', query: { status: 'Error', msg: 'We are facing some issue while sending mail' }});

            }

             else if(response && response.data){
              
              // console.log('email send success: ', response.data)
            }
          })
          .catch((error) => {
            console.log('error msg block email', error);
            // this.$router.push({ path: '/alert-diy', query: { status: 'Error', msg: 'We are facing some issue while sending mail' }});

          });
    },
    
    sendMailToCustomerAccept()
    {
      var rmData;
      if (localStorage.getItem("rm_data")){
       rmData = JSON.parse(localStorage.getItem("rm_data"));
      }

      var userProfile;
      if(localStorage.getItem("userProfile"))
      {
        userProfile = JSON.parse(localStorage.getItem("userProfile"));  
      }

      var text=   `<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Strict//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-strict.dtd">
                  <html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">
                    <head>
                        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
                        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                        <title>Email</title>
                        <style type="text/css">@media screen and (min-width: 750px){body{font-family:Arial,Helvetica,sans-serif!important;font-weight:500}body,table,thead,tbody,tr,td,img{padding:0;margin:0;border:none;border-spacing:0px;border-collapse:collapse;vertical-align:top}.wrapper{padding-left:10px;padding-right:10px;padding-top:10px;padding-bottom:10px}h1,h2,h3,h4,h5,h6,p{margin:0;padding:0;padding-bottom:20px;line-height:1.6}img{width:100%;display:block}.main-table{width:710px;margin:0 auto;margin-top:5px}.main-table tr td{font-size:14px!important;line-height:18px!important}.main-table-td{padding:10px!important}.main-table tr td.order-detl-mob{font-size:21px!important}.brand-logo img{width:auto!important}.main-table tr.totl-cost-td td{font-size:18px!important;line-height:18px!important}table{border-spacing:0}.order-details-table td{font-size: 9px!important;}}</style>
                    </head>
                    <body cellspacing="0" cellpadding="0" border="0" class="body" style="padding-top:5px !important;padding-bottom:5px !important; margin:0px !important; display:block !important; min-width:100% !important; width:100% !important; -webkit-text-size-adjust:none; background-color: #eceaea; " bgcolor="#eceaea">
                        <!--[if !mso]><!-->
                        <table align="center" style="background-color: #eceaea;margin-top: 0px;width: 100%;">
                          <tr>
                              <td>
                                <table style="" class="main-table" align="center">
                                    <tr>
                                      <td style="background: #fff;" class="main-table-td">
                                          <table width="100%" style="background: #fff;border-collapse: collapse;" align="center" >
                                            <tr>
                                                <td>
                                                  <table width="100%" style="border-collapse: collapse;">
                                                      <tr>
                                                        <td style="vertical-align: middle;">
                                                            <table border="0">
                                                              <tr>
                                                                  <td class="brand-logo"> <img src="` + this.distributorLogo + `" alt="" style="width: 100%;"></td>
                                                              </tr>
                                                            </table>
                                                        </td>
                                                      </tr>
                                                      <tr>
                                                        <td height="10px" colspan="2"></td>
                                                      </tr>
                                                      <tr>
                                                        <td height="10px" colspan="2"></td>
                                                      </tr>
                                                  </table>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                  <table style="font-size: 9px;border-collapse: collapse;">
                                                      <tr></tr>
                                    <tr>
                                                        <td>Dear `+rmData['Name']+`,
                                      </td>
                                                      </tr>
                                                      <tr>
                                                        <td height="40px"></td>
                                                      </tr>
                                                      <tr>
                                                        <td>Your client `+ userProfile["fullname"] +` has approved the proposal form and proposal application submitted successfully.
                                      </td>
                                                      </tr>
                                    
                                    <tr>
                                                        <td height="40px"></td>
                                                      </tr>
                                    <tr>
                                                        <td>Regards,</td>
                                                      </tr>
                                    <tr>
                                                        <td>` + this.distributorID +`</td>
                                                      </tr>
                                    <tr>
                                                        <td height="50px"></td>
                                                      </tr>
                                    <tr>
                                                        <td style="font-size: 9px;border-collapse: collapse;">*Insurance is the subject matter of solicitation. IRDAI Insurance Broking License No. 1. License Code No. IRDA/DB 1/22 Valid till 01/01/2023</td>
                                                      </tr>
                                    <tr>
                                                        <td height="50px"></td>
                                                      </tr>
                                    <tr>
                                      <td><center>Powered by 9Platforms Technology Pvt Ltd.</center></td>
                                    </tr>
                                                  </table>
                                                </td>
                                            </tr>
                                            
                                            
                                          </table>
                                      </td>
                                    </tr>
                                </table>
                              </td>
                          </tr>
                        </table>
                    </body>
                  </html>`
        let emailBody = `<!DOCTYPE html>
<html xmlns="http://www.w3.org/1999/xhtml" xmlns:th="http://www.thymeleaf.org">
   <head>
      <meta charset="ISO-8859-1" />
      <title>Insert title here</title>
   </head>
   <body>
      <table width="700"border="0"cellspacing="0" cellpadding="0" align="center"  bgcolor="#EEF3FB">
         <tr>
            <td
               style="  font-family: Public Sans; font-weight: 600; font-size: 18px;  color: #1f1f1f; padding: 20px 0px; text-align: center;" >
               <center><img style="max-width: 150px"src="` + this.distributorLogo + `" ></center>
            </td>
         </tr>
         <tr>
            <td>
               <table width="700" border="0" cellspacing="0" cellpadding="0"  align="center"   bgcolor="#fff" style="border-radius: 5px; background-color: #fff;
                  margin-left: 30px; margin-right: 30px;" >
                  <tr>
                     <td width="50"></td>
                     <td
                        style="
                        font-family: Public Sans; font-weight: 600; font-size: 18px;color: #1f1f1f;padding: 20px 0px 10px 0px;" >
                        Dear ${rmData['Name']}
                     </td>
                     <td width="50"></td>
                  </tr>
                  <tr>
                     <td width="20"></td>
                     <td
                        style="font-family: Public Sans;font-size: 18px;color: #1f1f1f;padding: 20px 0px;line-height: 28px;">
                        Your client ${this.formInfo['fieldGroups']['proposerDetails']['fieldGroups']['personalDetails']['fields']['firstName']['input']} ${this.formInfo['fieldGroups']['proposerDetails']['fieldGroups']['personalDetails']['fields']['lastName']['input']} has approved the proposal form and made payment successfully.
						            Proposal no: ${this.uiQuoteId}<br>
                     </td>
                     <td width="50"></td>
                  </tr>
                  <tr>
                     <td width="50"></td>
                     <td style=" border-bottom: 1px solid #f0f0f0; font-size: 14px; color: #1f1f1f; padding: 10px 0px 0px 0px; " ></td>
                     <td width="50"></td>
                  </tr>

				  <tr>
                     <td width="50"></td>
                     <td
                        style="font-family: Public Sans;font-size: 18px;color: #1f1f1f;padding: 20px 0px;line-height: 28px;" >
                        Regards,<br>
                  ` + this.distributorID +`
                     </td>
                     <td width="50"></td>
                  </tr>
				  <tr>
                     <td width="50"></td>
                     <td
                        style="font-family: Public Sans; font-size: 10px;  color: #1f1f1f; padding: 20px 0px; line-height: 22px;" >
                        *Insurance is the subject matter of solicitation. IRDAI Insurance Broking License No. 1. License Code No. IRDA/DB 1/22 Valid till 01/01/2023
                     </td>
                     <td width="50"></td>
                  </tr>
               </table>
            </td>
         </tr>
         <tr>
            <td
               style="  font-family: Public Sans; font-size: 12px;  text-align: center; opacity: 0.5; color: #1f1f1f;  padding: 10px; " >
               <center><b style="color: black;"> Powered by </b><strong style="color: blue;font-size: 12px;">9Platforms Technology Pvt Ltd.</strong></center>
            </td>
         </tr>
      </table>
   </body>
</html>`
        let qData = {
          "send_from":"customer.service@1silverbullet.tech",
          "subject":"Policy approved "+this.formInfo['fieldGroups']['proposerDetails']['fieldGroups']['personalDetails']['fields']['firstName']['input'] + " "+this.formInfo['fieldGroups']['proposerDetails']['fieldGroups']['personalDetails']['fields']['lastName']['input'],
          "text": emailBody,
          "send_to":['dipak.bhosale@1silverbullet.tech']
          //Removed rm's email other than in production.
          //"send_to":['dipak.bhosale@1silverbullet.tech',rmData['Email']]
        };
        var headers = {
          Authorization: "Basic " + window.btoa(process.env.VUE_APP_USERNPASS),
          "Content-Type": "application/json",
        };
        this.backendApi("sendMail", qData, headers)
          .then((response) => {
            //console.log('save quote response: ', response);
            if(response == 'error') {
              self.apiError = true;
            } else if(response && response.data){
              
              console.log('email send success: ', response.data)
            }
          })
          .catch((error) => {
            console.log('error msg block email', error);
          });
    },
    sendMailToCustomerReject()
    {
      var rmData;
      if (localStorage.getItem("rm_data")){
       rmData = JSON.parse(localStorage.getItem("rm_data"));
      }

      var userProfile;
      if(localStorage.getItem("userProfile"))
      {
        userProfile = JSON.parse(localStorage.getItem("userProfile"));  
      }

      var text=`<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Strict//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-strict.dtd">
                <html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">
                  <head>
                      <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
                      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                      <title>Email</title>
                      <style type="text/css">@media screen and (min-width: 750px){body{font-family:Arial,Helvetica,sans-serif!important;font-weight:500}body,table,thead,tbody,tr,td,img{padding:0;margin:0;border:none;border-spacing:0px;border-collapse:collapse;vertical-align:top}.wrapper{padding-left:10px;padding-right:10px;padding-top:10px;padding-bottom:10px}h1,h2,h3,h4,h5,h6,p{margin:0;padding:0;padding-bottom:20px;line-height:1.6}img{width:100%;display:block}.main-table{width:710px;margin:0 auto;margin-top:5px}.main-table tr td{font-size:14px!important;line-height:18px!important}.main-table-td{padding:10px!important}.main-table tr td.order-detl-mob{font-size:21px!important}.brand-logo img{width:auto!important}.main-table tr.totl-cost-td td{font-size:18px!important;line-height:18px!important}table{border-spacing:0}.order-details-table td{font-size: 9px!important;}}</style>
                  </head>
                  <body cellspacing="0" cellpadding="0" border="0" class="body" style="padding-top:5px !important;padding-bottom:5px !important; margin:0px !important; display:block !important; min-width:100% !important; width:100% !important; -webkit-text-size-adjust:none; background-color: #eceaea; " bgcolor="#eceaea">
                      <!--[if !mso]><!-->
                      <table align="center" style="background-color: #eceaea;margin-top: 0px;width: 100%;">
                        <tr>
                            <td>
                              <table style="" class="main-table" align="center">
                                  <tr>
                                    <td style="background: #fff;" class="main-table-td">
                                        <table width="100%" style="background: #fff;border-collapse: collapse;" align="center" >
                                          <tr>
                                              <td>
                                                <table width="100%" style="border-collapse: collapse;">
                                                    <tr>
                                                      <td style="vertical-align: middle;">
                                                          <table border="0">
                                                            <tr>
                                                                <td class="brand-logo"> <img src="` + this.distributorLogo + `" alt="" style="width: 100%;"></td>
                                                            </tr>
                                                          </table>
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td height="10px" colspan="2"></td>
                                                    </tr>
                                                    <tr>
                                                      <td height="10px" colspan="2"></td>
                                                    </tr>
                                                </table>
                                              </td>
                                          </tr>
                                          <tr>
                                              <td>
                                                <table style="font-size: 9px;border-collapse: collapse;">
                                                    <tr></tr>
                                  <tr>
                                                      <td>Dear `+rmData['Name']+`,
                                    </td>
                                                    </tr>
                                                    <tr>
                                                      <td height="40px"></td>
                                                    </tr>
                                                    <tr>
                                                      <td>Your client `+ userProfile["fullname"] +` has Rejected the proposal form with following reason.
                                    </td>
                                                    </tr>
                                  
                                  <tr>
                                                      <td height="40px"></td>
                                                    </tr>
                                  <tr>
                                                      <td>`+this.message+`
                                    </td>
                                                    </tr>
                                  
                                  <tr>
                                                      <td height="40px"></td>
                                                    </tr>
                                  <tr>
                                                      <td>Regards,</td>
                                                    </tr>
                                  <tr>
                                                      <td>` + this.distributorID +`</td>
                                                    </tr>
                                  <tr>
                                                      <td height="50px"></td>
                                                    </tr>
                                  <tr>
                                                      <td style="font-size: 9px;border-collapse: collapse;">*Insurance is the subject matter of solicitation. IRDAI Insurance Broking License No. 1. License Code No. IRDA/DB 1/22 Valid till 01/01/2023</td>
                                                    </tr>
                                  <tr>
                                                      <td height="50px"></td>
                                                    </tr>
                                  <tr>
                                    <td><center>Powered by 9Platforms Technology Pvt Ltd.</center></td>
                                  </tr>
                                                </table>
                                              </td>
                                          </tr>
                                          
                                          
                                        </table>
                                    </td>
                                  </tr>
                              </table>
                            </td>
                        </tr>
                      </table>
                  </body>
                </html>`

      let emailBody = `<!DOCTYPE html>
<html xmlns="http://www.w3.org/1999/xhtml" xmlns:th="http://www.thymeleaf.org">
   <head>
      <meta charset="ISO-8859-1" />
      <title>Insert title here</title>
   </head>
   <body>
      <table width="700"border="0"cellspacing="0" cellpadding="0" align="center"  bgcolor="#EEF3FB">
         <tr>
            <td
               style="  font-family: Public Sans; font-weight: 600; font-size: 18px;  color: #1f1f1f; padding: 20px 0px; text-align: center;" >
               <center><img style="max-width: 150px"src="` + this.distributorLogo + `" ></center>
            </td>
         </tr>
         <tr>
            <td>
               <table width="700" border="0" cellspacing="0" cellpadding="0"  align="center"   bgcolor="#fff" style="border-radius: 5px; background-color: #fff;
                  margin-left: 30px; margin-right: 30px;" >
                  <tr>
                     <td width="50"></td>
                     <td
                        style="
                        font-family: Public Sans; font-weight: 600; font-size: 18px;color: #1f1f1f;padding: 20px 0px 10px 0px;" >
                        Dear ${rmData['Name']}
                     </td>
                     <td width="50"></td>
                  </tr>
                  <tr>
                     <td width="20"></td>
                     <td
                        style="font-family: Public Sans;font-size: 18px;color: #1f1f1f;padding: 20px 0px;line-height: 28px;">
                        Your client ${this.formInfo['fieldGroups']['proposerDetails']['fieldGroups']['personalDetails']['fields']['firstName']['input']} ${this.formInfo['fieldGroups']['proposerDetails']['fieldGroups']['personalDetails']['fields']['lastName']['input']} has rejected the proposal form with following reason.<br><br>
						            Proposal no: ${this.uiQuoteId}<br>
						${this.message}
                     </td>
                     <td width="50"></td>
                  </tr>
                  <tr>
                     <td width="50"></td>
                     <td style=" border-bottom: 1px solid #f0f0f0; font-size: 14px; color: #1f1f1f; padding: 10px 0px 0px 0px; " ></td>
                     <td width="50"></td>
                  </tr>

                  
				  <tr>
                     <td width="50"></td>
                     <td
                        style="font-family: Public Sans;font-size: 18px;color: #1f1f1f;padding: 20px 0px;line-height: 28px;" >
                        Regards,<br>
                        ` + this.distributorID +`
                     </td>
                     <td width="50"></td>
                  </tr>
				  <tr>
                     <td width="50"></td>
                     <td
                        style="font-family: Public Sans; font-size: 10px;  color: #1f1f1f; padding: 20px 0px; line-height: 22px;" >
                        *Insurance is the subject matter of solicitation. IRDAI Insurance Broking License No. 1. License Code No. IRDA/DB 1/22 Valid till 01/01/2023
                     </td>
                     <td width="50"></td>
                  </tr>
               </table>
            </td>
         </tr>
         <tr>
            <td
               style="  font-family: Public Sans; font-size: 12px;  text-align: center; opacity: 0.5; color: #1f1f1f;  padding: 10px; " >
               <center><b style="color: black;"> Powered by </b><strong style="color: blue;font-size: 12px;">9Platforms Technology Pvt Ltd.</strong></center>
            </td>
         </tr>
      </table>
   </body>
</html>`
      let qData = {
          "send_from":"customer.service@1silverbullet.tech",
          "subject":"Policy rejected "+this.formInfo['fieldGroups']['proposerDetails']['fieldGroups']['personalDetails']['fields']['firstName']['input'] + " "+this.formInfo['fieldGroups']['proposerDetails']['fieldGroups']['personalDetails']['fields']['lastName']['input'],
          "text": emailBody,
          "send_to":['dipak.bhosale@1silverbullet.tech']
          //Removed rm's email other than in production.
          //"send_to":['dipak.bhosale@1silverbullet.tech',rmData['Email']]    
        };
        var headers = {
          Authorization: "Basic " + window.btoa(process.env.VUE_APP_USERNPASS),
          "Content-Type": "application/json",
        };
        this.backendApi("sendMail", qData, headers)
          .then((response) => {
            //console.log('save quote response: ', response);
            if(response == 'error') {
              self.apiError = true;
            } else if(response && response.data){
              
              console.log('email send success: ', response.data)
            }
          })
          .catch((error) => {
            console.log('error msg block email', error);
          });
    },
    rejectedProposal(){
        //send mail to RM
        this.sendMailToCustomerReject()
        //Update db with status id 10 for Proposal Modification Requested from customer
        this.updateProposalStatus(10);
        //Redirect to thankyou page
        this.$router.push('/thankYou');
    },
    convertSentenceCase(value) {
      const result = value.replace(/([A-Z])/g, " $1");
      const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
      return finalResult;
    },
    getShow(tab) {
      if(tab == 'proposerDetails') {
        return "show";
      } else {
        return '';
      }
    },
    getInsurerImgs(value) {
      let img = getInsuranceImages();
      return require("../../assets/images/" + img[value]);
    },
    prePayment(){
        let productCode_DIY;
        let paymentDetails_DIY;
        if(sessionStorage.getItem('filledData')) {
          productCode_DIY = JSON.parse(sessionStorage.getItem("filledData"));
        }
        if(sessionStorage.getItem('selected_plan_data')) {
          paymentDetails_DIY = JSON.parse(sessionStorage.getItem("selected_plan_data"));
        }
        
        let data = this.$route.query.response;
        if(productCode_DIY["manufacturerId"] == "TATA" && (typeof(data) == "undefined"))
        { 
          let reqData = 
          {
            "data":{
            "additionalSetup": {
                "currency": "INR",
                "userCountry": "",
                "userRegion": "",
                "userLanguage": "",
                "userIP": "",
                "userAgent": "",
                "userGeoLocation": {
                    "latitude": "",
                    "longitude": ""
                }
            },
            "distributor": {
                "distributorID": productCode_DIY.fieldGroups.distributor.fields.distributorID.input,
                "agentID": productCode_DIY.fieldGroups.distributor.fields.agentCode.input,
                "salesChannel": "",
                "varFields": [
                    {
                        "fieldName": "",
                        "fieldValue": ""
                    }
                ]
            },
            "productCode": productCode_DIY["productId"],
            "insuranceCompanyCode": productCode_DIY["manufacturerId"],
            "applicationNo": "",
            "policyNo": "",
            "quoteId": "",
            "UITrackingRefNo": "",
            "redirectUrl": document.location.href,
            "memberDetails": {
                "firstName": productCode_DIY.fieldGroups.proposerDetails.fieldGroups.personalDetails.fields.firstName.input,
                "lastName": productCode_DIY.fieldGroups.proposerDetails.fieldGroups.personalDetails.fields.lastName.input,
                "mobileNumber": "9617181277",
                "email": "tata@1silverbullet.com",
                "varFields": [
                    {
                        "fieldName": "",
                        "fieldValue": ""
                    }
                ]
            },
            "paymentDetails": {
                "premiumPaymentFrequency": paymentDetails_DIY.productDetails.premiumPaymentFrequency,
                "amountToBePaid": paymentDetails_DIY.individualDetails[0].premiumDetails[0].totalPremiumValue,
                "method": "",
                "bankAccount": {
                    "name": "",
                    "accountNumber": "",
                    "ifsc": "",
                    "accountType": ""
                },
                "varFields": [
                    {
                        "fieldName": "",
                        "fieldValue": ""
                    }
                ]
            },
            "varFields": [
                {
                    "fieldName": "",
                    "fieldValue": ""
                }
            ]
        }};

        
        //   var headers = {
        //   Authorization: "Basic " + window.btoa(process.env.VUE_APP_USERNPASS),
        //   "Content-Type": "application/json",
        // };
        let headers ={};

          this.backendApi("paymentUrlApi", reqData, headers)
            .then((response) => {
              // console.log("PaymentUrlApiResponse",response)
              if(response.status == "error")
              {
                this.loading = false;
                let msg=['We are facing some issue while fetching payment URL']
                this.$router.push({ path: '/alert-diy', query: {msg: msg }});
                // alert("Network Error");
                
              }
              else
              {
                console.log("Policy No."+response.data.data[0].policyNo);
                console.log("App No."+response.data.data[0].applicationNo);
                if(response.data.data[0].policyNo == "" || response.data.data[0].applicationNo=="") {
                  self.loading =false;
                  let msg=['Policy Number or Application Number is NULL']
                  this.$router.push({ path: '/alert-diy', query: {msg: msg }});
                  // alert("Policy Number|Application Number is NULL. Please try again!");
                }
                else {
                  this.formInfo.fieldGroups.traceInfo.fields.manuPolicyNo.input = response.data.data[0].policyNo;
                  this.formInfo.fieldGroups.traceInfo.fields.manuApplicationNo.input = response.data.data[0].applicationNo;
                  this.saveProposal(response.data.data[0].paymentDetails.paymentUrl);
                  //window.location.href = response.data.data[0].paymentDetails.paymentUrl;
                }
              }
              
            }).catch((error) => {
              console.log('error msg block getPaymentURL', error);
              self.loading = false;
              // let msg=['We are facing some issue from backend while fetching payment URL']
              // this.$router.push({ path: '/alert-diy', query: { msg: msg }});

            });
        }
    },
    convertProposalJson()
    {
      console.log("response data after last tab",this.formInfo)
       this.proposalJson["data"]={}
      this.proposalJson["data"]=JSON.parse(JSON.stringify(this.formInfo))
      // console.log("pro json",this.proposalJson)
      // for(let key in this.responseData)
      // {
      //   if(key==sectionIndex)
      //   {
          // this.proposalJson["data"]["fieldGroups"][sectionIndex]=JSON.parse(JSON.stringify(this.responseData[key]))
          for(let grandParentKey in this.proposalJson["data"])
          {
            // if(grandParentKey!="fieldGroups")
            // {
            //   delete this.proposalJson["data"][grandParentKey]
            // }
            if(grandParentKey=="fieldGroups"){
            //level 1
            for(let parentKey in this.proposalJson["data"][grandParentKey])
            {

            
          for(let checkfg in this.proposalJson["data"][grandParentKey][parentKey] )
          {
             if(checkfg!="fieldGroups" && checkfg!="fields")
             {
              
              // console.log("section",checkfg)
              delete this.proposalJson["data"][grandParentKey][parentKey][checkfg]
             }
             else if(checkfg=="fieldGroups")
             {
              //level 2
                for(let childKey in this.proposalJson["data"][grandParentKey][parentKey][checkfg])
                {


                   for(let checkChildfg in this.proposalJson["data"][grandParentKey][parentKey][checkfg][childKey])
                   {
                    if(checkChildfg!="fields" && checkChildfg!="fieldGroups")
                    {
                      //  console.log("checkChildfg",checkChildfg)
                      delete this.proposalJson["data"][grandParentKey][parentKey][checkfg][childKey][checkChildfg]
                    }
                    else if(checkChildfg=="fields")   
                    {
                        for(let childKeyfieldsChild in this.proposalJson["data"][grandParentKey][parentKey][checkfg][childKey][checkChildfg])
                        {
                            for(let childKeyfieldsChildKey in this.proposalJson["data"][grandParentKey][parentKey][checkfg][childKey][checkChildfg][childKeyfieldsChild])
                            {
                                 if(childKeyfieldsChildKey!="input")
                                 {
                                  delete this.proposalJson["data"][grandParentKey][parentKey][checkfg][childKey][checkChildfg][childKeyfieldsChild][childKeyfieldsChildKey]
                                 }
                            }

                        }
                    }
                    else if(checkChildfg=="fieldGroups")
                    {
                      //level 3
                        for(let fg_fg_fg_key in this.proposalJson["data"][grandParentKey][parentKey][checkfg][childKey][checkChildfg])
                        {
                              for(let fg_fg_fg_key_f in this.proposalJson["data"][grandParentKey][parentKey][checkfg][childKey][checkChildfg][fg_fg_fg_key])
                              {
                                if(fg_fg_fg_key_f!="fields" && fg_fg_fg_key_f!="fieldGroups")
                                {
                                  delete this.proposalJson["data"][grandParentKey][parentKey][checkfg][childKey][checkChildfg][fg_fg_fg_key][fg_fg_fg_key_f]
                                }
                                else if(fg_fg_fg_key_f=="fields")
                                {
                                  for(let fg_fg_fg_key_f_key in this.proposalJson["data"][grandParentKey][parentKey][checkfg][childKey][checkChildfg][fg_fg_fg_key][fg_fg_fg_key_f])
                                  {
                                      for(let fg_fg_fg_key_f_key_input in this.proposalJson["data"][grandParentKey][parentKey][checkfg][childKey][checkChildfg][fg_fg_fg_key][fg_fg_fg_key_f][fg_fg_fg_key_f_key])
                                      {
                                        if(fg_fg_fg_key_f_key_input!="input")
                                        {
                                          delete this.proposalJson["data"][grandParentKey][parentKey][checkfg][childKey][checkChildfg][fg_fg_fg_key][fg_fg_fg_key_f][fg_fg_fg_key_f_key][fg_fg_fg_key_f_key_input]

                                        }
                                      }
                                  }
                                }
                                else if(fg_fg_fg_key_f=="fieldGroups")
                                  {
                                    //level 4
                                      for(let fg_fg_fg_key_f_key in this.proposalJson["data"][grandParentKey][parentKey][checkfg][childKey][checkChildfg][fg_fg_fg_key][fg_fg_fg_key_f])
                                      {
                                            for(let fg_fg_fg_key_f_key_f in this.proposalJson["data"][grandParentKey][parentKey][checkfg][childKey][checkChildfg][fg_fg_fg_key][fg_fg_fg_key_f][fg_fg_fg_key_f_key])
                                            {
                                              if(fg_fg_fg_key_f_key_f!="fields" && fg_fg_fg_key_f_key_f!="fieldGroups")
                                              {
                                                delete this.proposalJson["data"][grandParentKey][parentKey][checkfg][childKey][checkChildfg][fg_fg_fg_key][fg_fg_fg_key_f][fg_fg_fg_key_f_key][fg_fg_fg_key_f_key_f]
                                              }
                                              else if(fg_fg_fg_key_f_key_f=="fields")
                                              {
                                                for(let fg_fg_fg_key_f_key_f_key in this.proposalJson["data"][grandParentKey][parentKey][checkfg][childKey][checkChildfg][fg_fg_fg_key][fg_fg_fg_key_f][fg_fg_fg_key_f_key][fg_fg_fg_key_f_key_f])
                                                {
                                                    for(let fg_fg_fg_key_f_key_f_key_input in this.proposalJson["data"][grandParentKey][parentKey][checkfg][childKey][checkChildfg][fg_fg_fg_key][fg_fg_fg_key_f][fg_fg_fg_key_f_key][fg_fg_fg_key_f_key_f][fg_fg_fg_key_f_key_f_key])
                                                    {
                                                      if(fg_fg_fg_key_f_key_f_key_input!="input")
                                                      {
                                                        delete this.proposalJson["data"][grandParentKey][parentKey][checkfg][childKey][checkChildfg][fg_fg_fg_key][fg_fg_fg_key_f][fg_fg_fg_key_f_key][fg_fg_fg_key_f_key_f][fg_fg_fg_key_f_key_f_key][fg_fg_fg_key_f_key_f_key_input]

                                                      }
                                                    }
                                                }
                                              }
                                            }
                                      }
                                  }
                              }
                        }
                    }
                   }

                    
                }
             }
             else if(checkfg=="fields")
             {
              for(let childofFields in this.proposalJson["data"][grandParentKey][parentKey][checkfg])
              {
                   for(let childOfFieldsOfChildKey in this.proposalJson["data"][grandParentKey][parentKey][checkfg][childofFields])
                   {
                      if(childOfFieldsOfChildKey!="input")
                      {
                        delete this.proposalJson["data"][grandParentKey][parentKey][checkfg][childofFields][childOfFieldsOfChildKey]
                      }
                   }
              }
             }
          }
            }
            }
          }
          
          // console.log("after filter proposal json",this.proposalJson)
      //   }
      // } 
    },
    buyPolicy() {


      console.log('Buy Policy Called');
      

    //   if(this.spDetails.source) {

        var self = this;
        this.errorMsgs = "";
        this.selectedPlan = JSON.parse(sessionStorage.getItem("selectedPlanForCheckout"));
        //let productApiUrl = this.selectedPlan.insuranceAndProducts.insuranceCompanyCode + '/' + this.selectedPlan.insuranceAndProducts.productCode;

        let productCode_DIY;
        if(sessionStorage.getItem('filledData')) {
          productCode_DIY = JSON.parse(sessionStorage.getItem("filledData"));
        }
        
        if(productCode_DIY["manufacturerId"]=="ICICI")
        {
          this.formInfo.fieldGroups.other.fields.partnerThankYouUrl['input'] = this.thankYouUrl;
        }
        
         this.convertProposalJson()
        let reqData = {"data":{
          "quoteResponse": { "data": { "quote": [ this.selectedPlan ],"request":JSON.parse(this.quoteDetails.quote_request_json)}},
          "data": this.proposalJson["data"]
        }};

        // let headers = {
        //   Authorization: "Basic " + window.btoa(process.env.VUE_APP_PMSNPASS),
        //   "Content-Type": "application/json",
        // };
        let headers = {};
        

        
          this.getResumeURL();
      
        
        this.loading = true;
        // console.log("this is the request of submit Proposal", reqData)
        this.backendApi("proposalVersionApi", reqData, headers)
          .then((response) => {
            //add reponse.data in db with ui_id
            // console.log('Sumbit proposal success', response);
            //Update proposal status to "Proposal Submitted"
            //this.updateProposalStatus(9);
            
            self.loading = false;
            self.apiError = false;
            if(response.data.errors){
              if(response.data.errors.length > 0)
              {
                //Update proposal status to "Proposal Error"
                this.updateProposalStatus(12);
                this.saveProposalResponse(reqData,response.data);
                //self.apiError = true;
                // console.log('inside error: ', response.data);
                let ck = 1;
                response.data.errors.forEach(function(element) {
                  self.errorMsgs += ck +'. '+ element.errorMessage +'<br/>';
                  ck++;
                });
              }
              else
              {
                if(response['status'] == 'error') {
                  //Update proposal status to "Proposal Error"
                  this.updateProposalStatus(12);
                  this.saveProposalResponse(reqData,response.data);
                  //self.apiError = true;
                  // console.log('inside error: ', response.data);
                  let ck = 1;
                  response.data.errors.forEach(function(element) {
                    self.errorMsgs += ck +'. '+ element.errorMessage +'<br/>';
                    ck++;
                  });

                } else if(response && response.data) {
                  //Update proposal status to "Proposal Submitted"
                  // this.updateProposalStatus(9);
                  this.saveProposalResponse(reqData,response.data);
                  // console.log('Submit proposal response: ', response.data)
                  self.loading = false;
                  //this.response = response.data;

                  let reqData2 = [this.selectedPlan.insuranceAndProducts.productCode, this.selectedPlan.insuranceAndProducts.insuranceCompanyCode, response.data.reqId];
                  this.reqTimeOut();
                  this.getProposalPoll(reqData2);
                  
                }
              }
            }
            else
            {
              if(response['status'] == 'error') {
                //Update proposal status to "Proposal Error"
                this.updateProposalStatus(12);
                this.saveProposalResponse(reqData,response.data);
                //self.apiError = true;
                // console.log('inside error: ', response.data);
                let ck = 1;
                response.data.errors.forEach(function(element) {
                  self.errorMsgs += ck +'. '+ element.errorMessage +'<br/>';
                  ck++;
                });

              } else if(response && response.data) {
                //Update proposal status to "Proposal Submitted"
                // this.updateProposalStatus(9);
                this.saveProposalResponse(reqData,response.data);
                // console.log('Submit proposal response: ', response.data)
                self.loading = false;
                //this.response = response.data;

                let reqData2 = [this.selectedPlan.insuranceAndProducts.productCode, this.selectedPlan.insuranceAndProducts.insuranceCompanyCode, response.data.reqId];
                this.reqTimeOut();
                this.getProposalPoll(reqData2);
                
              }
            }
            
            
          })
          .catch((error) => {
            console.log('error msg block submit proposal', error);
            self.loading = false;
          });

    //   } else {
    //     alert('SP Details not available');
    //   }

    },
    getProposalPoll(reqData) {
      let self = this;
      console.log('get proposal called: ', reqData);
      // let headers = {
      //   Authorization: "Basic " + window.btoa(process.env.VUE_APP_PMSNPASS),
      //   "Content-Type": "application/json",
      // };
      let headers = {};
      this.loading = true;

      setTimeout(() => {
        // console.log("set time out called")
          this.backendApi("proposalPollApi", reqData, headers, "get")
          .then((response) => {
            // console.log('Get proposal poll success');
            //self.loading = false;
            self.apiError = false;
            if(response.errors)
            {
              if(response.errors.length > 0){
                //Update proposal status to "Proposal Error"
                this.updateProposalStatus(12);
                clearTimeout(this.timer);
                self.apiError = true;
                self.loading = false;
                let ck = 1;
                response.errors.forEach(function(element) {
                  self.errorMsgs += ck +'. '+ element.errorMessage +'<br/>';
                  ck++;
                });
              }
            }
            if(response.error)
            {
              if(response.error.length > 0){
                //Update proposal status to "Proposal Error"
                this.updateProposalStatus(12);
                clearTimeout(this.timer);
                self.apiError = true;
                self.loading = false;
                let ck = 1;
                response.error.forEach(function(element) {
                  self.errorMsgs += ck +'. '+ element.errorMessage +'<br/>';
                  ck++;
                });
              }
            }
            if(response.data.errors)
            {
              if(response.data.errors.length > 0){
                //Update proposal status to "Proposal Error"
                this.updateProposalStatus(12);
                clearTimeout(this.timer);
                self.apiError = true;
                self.loading = false;
                let ck = 1;
                response.data.errors.forEach(function(element) {
                  self.errorMsgs += ck +'. '+ element.errorMessage +'<br/>';
                  ck++;
                });
              }
            }
            if(response == 'error') {
              //Update proposal status to "Proposal Error"
              this.updateProposalStatus(12);
              self.apiError = true;
              self.loading = false;
            } else if(response && response.data){
              console.log('Get proposal poll response: ', response.data)

              if(response.data?.data?.message) {
                
                //console.log('nside internal message');
                if(response.data.data.message == 'Please wait we are still processing the request') {
                  self.getProposalPoll(reqData);
                }

              } else if(response.data?.status) {
                // console.log('indide kkkk', response.data.status)
                if(response.data.status.message == 'Success' || response.data.status.code == 'E00') {
                  console.log('inside success');
                  clearTimeout(this.timer);
                  self.loading = false;
                  
                  let productCode_DIY;
                  if(sessionStorage.getItem('filledData')) {
                    productCode_DIY = JSON.parse(sessionStorage.getItem("filledData"));
                  }
                  
                  if(productCode_DIY["manufacturerId"]=="ICICI")
                  {
                    let resumeJourenyUrl = response.data.URL.resumeJourneyURL;
                    console.log('resumeJourenyUrl: ', resumeJourenyUrl);
                    if(resumeJourenyUrl !== '') {
                      console.log('success url');
                      //Updating proposal status tp 'Proposal Submitted'
                      this.updateProposalStatus(9);
                      this.updateResumeJourneyURL(resumeJourenyUrl);
                      this.sendMailToCustomerAccept();
                      window.location.href = resumeJourenyUrl;
                    }
                  }
                  else
                  {
                    this.thankYouUrl = new URL(location).origin+"/#/payment?transactionId=" + this.formInfo.fieldGroups.traceInfo.fields.paymentID.input;
                    window.location.href = this.thankYouUrl;
                  }
                  
                  
                } else {
                  console.log('get proposal called again. prev stat:'+ JSON.stringify(response.data.status));
                  self.loading = false;
                  this.lastresponse = JSON.stringify(response.data.status);
                  clearTimeout(this.timer);
                  let msg=[]
                  msg.push("Received Error response from manufacturer: "+this.lastresponse)
                  
                  // alert("Received Error response from manufacturer: "+this.lastresponse)
                  //Updating proposal status tp 'Proposal Error'
                  this.updateProposalStatus(12);
                  this.sendMailToCustomerError();
                  // window.location.href = new URL(location).origin + "/#/proposaldetailscheckdiy?proposalId="+this.$route.query.proposalId+"&transactionId="+this.$route.query.transactionId;
                  //self.getProposalPoll(reqData);
                  let msgError="We are facing some issue while fetching poll Api"
                  this.$router.push({ path: '/alert-diy', query: {msgError:msgError, reqId:response.data.reqId,msg: msg }});
                }
              }

               
            }
          })
          .catch((error) => {
            self.loading = false;
            console.log('error msg block get proposal poll', error);
          });
      }, 10000);

      

    },
    checkVisibility(value) {
      //return eval(value);
      if(value) {
        let obj = this.getFinalObject(value);
        try {
          return eval(obj);
        }
        catch(err) {
          console.log('error exp: ', value);
        }
      } else {
        return true;
      }
    },
    getFinalObject(tempResponse) {
      //console.log('tempResponse: ', tempResponse)
      var tempString = JSON.stringify(tempResponse);
      var updatedString = tempString.replace(/'input' in formInfo\[/g, "formInfo[");
      var updatedString2 = updatedString.replace(/formInfo\[/g, "this.formInfo[");
      //var updatedString3 = updatedString2.replace(/this.this./g, "this.");
      //console.log('final String: ', updatedString3);
      return JSON.parse(updatedString2); 
    },
    editDetails(tabIndex) {
      if(confirm("Do you really want to edit these details?")){
        localStorage.setItem("filledDataTab", JSON.stringify(tabIndex));
        sessionStorage.setItem("filledDataTab", JSON.stringify(tabIndex));
        this.$router.push('/proposal');
      }
    },
    getSelectValue(array, val) {
      if(array) {
        let itemIndex = array.findIndex(item => item.Value == val);
        if(array[itemIndex]) {
         return array[itemIndex]['Text'];
        }
      } else {
        return '';
      }
    },
    getMultiSelectValue(array, array2) {
      if(array2) {
        let tmpVal = [];
        for(var i = 0; i < array2.length; i++) {
          var x = this.getSelectValue(array, array2[i]);
          if(x) {
            tmpVal.push(x);
          }
        }
        return tmpVal;
      }
    },
    dateconvert(value){
      const formattedDate = moment(value).format('DD/MM/YYYY')
     // console.log(formattedDate)
      return formattedDate;
    },
    generateOTP(){
         this.otpSent = null;
         this.otpValid = null;
         console.log('get otp called');
         this.disabledResend = true;
         let reqBody = {"data":{"distributor": {
                                "distributorID": this.distributorID,
                            },
                            "insuranceCompanyCode": this.selectedPlan.insuranceAndProducts.insuranceCompanyCode,
                            "UITrackingRefNo": this.proposalUiId,
                            "OTPDetails":{
                                "mobileNumber": parseInt(this.formInfo.fieldGroups.proposerDetails.fieldGroups.personalDetails.fields.mobileNumber.input),
                                "email": this.formInfo.fieldGroups.proposerDetails.fieldGroups.personalDetails.fields.email.input
                            }
        }};
        console.log('otpReq: ',reqBody);
        // this.loading = true;
        // let headers = {Authorization: "Basic " + window.btoa(process.env.VUE_APP_USERNPASS),
        // "Content-Type": "application/json",
        // };
        let headers ={};
        this.backendApi("sendOtpApi",  reqBody, headers)
        .then((response) => {
          this.loading = false;
          // console.log(response.data.data.OTPTransactionId)
          this.otpTransactionId = response.data.data.OTPTransactionId;
          this.otpExpiryTime = response.data.data.ExpiryTime;
          this.otpSent = "OTP has been sent successfully";
          this.resendOTP();
          // console.log('get otp response', response);          
          if(this.formInfo.manufacturerId == "ICICI")
          {
            // Add created time
            let d_utc = moment.utc();
            this.formInfo.fieldGroups.otpDetails.fields.otpReqCreatedTime.input = d_utc.format('DD-MM-YYYY hh:mm:ss');
          }
        })
        .catch((error) => {
          console.log('error msg block get otp on proposal confirmation', error);
          self.loading = false;
        });
    },
    otpModalReset(){
        this.otpSent= null;
        this.otpValid= null;
        this.otp = "";
        this.otpMessage = null;
        this.disabledResend = true;
        this.timerMinutes = "";
        this.timerSeconds = "";
    },
    validateOTP(){
        console.log('validate otp called');
         let reqBody = {"data":{"distributor": {
                                "distributorID": this.distributorID,
                            },
                            "insuranceCompanyCode": this.selectedPlan.insuranceAndProducts.insuranceCompanyCode,
                            "UITrackingRefNo": this.proposalUiId,
                            "OTPDetails":{
                                "OTP": this.otp,
                                "OTPTransactionId":this.otpTransactionId
                            }
        }};
        // console.log('validate otpReq: ',reqBody);
        // let headers = {Authorization: "Basic " + window.btoa(process.env.VUE_APP_USERNPASS),
        // "Content-Type": "application/json",
        // };
        let headers= {};
        this.backendApi("valOtpApi", reqBody, headers)
        .then((response) => {
          this.otpMessage = response.data.data.OTPAccepted;
          // console.log("validated otp response = ", response);
          if(this.otpMessage == "Yes"){
            if(this.formInfo.manufacturerId == "ICICI")
            {
              // Add otpReqUpdatedTime time and otpReqOtpNo
              let d_utc = moment.utc();
              this.formInfo.fieldGroups.otpDetails.fields.otpReqUpdatedTime.input = d_utc.format('DD-MM-YYYY hh:mm:ss');
              this.formInfo.fieldGroups.otpDetails.fields.otpReqOtpNo.input = this.otp;
              this.formInfo.fieldGroups.otpDetails.fields.otpReqStatus.input = "1";
              this.formInfo.fieldGroups.otpDetails.fields.otpReqExpiryTimeInMin.input = "30";
              this.saveProposal();
            }
            
            $("#otpModal").modal("hide");
            this.loading = true;
            this.otpModalReset();
            let productCode_DIY;
            
            if(sessionStorage.getItem('filledData')) {
              productCode_DIY = JSON.parse(sessionStorage.getItem("filledData"));
              
            }
            if(productCode_DIY["manufacturerId"] == "TATA")
            {
              
              let status = JSON.parse(sessionStorage.getItem("proposalStatusValue"));
              // console.log("status",status)
              // this.prePayment();
              if(status=="Payment Success")
              {
                // this.getResumeURL()
                this.buyPolicy();
              }
              else if(status=="Payment Failure")
              {
                
                this.prePayment();
              }
              else if(status=="Proposal Error")
              {
                // this.getResumeURL()
                this.buyPolicy();
              }
              else if(status=="Proposal Submitted")
              {
                let msg='Proposal already submitted'
                this.$router.push({ path: '/thankyoumail', query: {msg: msg}});
              }
              else{
                this.prePayment();
              }
              
            }
            else
            {
              this.buyPolicy();
            }
          }
          else{
            // console.log('validate otp response', response.data.data.OTPAccepted);
            this.otpMessage = null;
            this.otpValid = response.data.data.reason;
            this.otp = "";
            this.otpSent = null;
          }
        })
        .catch((error) => {
          console.log('error msg block get otp on proposal confirmation', error);
          self.loading = false;
        });
    },
    saveProposalResponse(req,resp){
      let propoalData = {
        "proposal_ui_id": this.proposalUiId.toString(),
        "proposal_request": JSON.stringify(req),
        "proposal_response": JSON.stringify(resp)
      }

      var headers = {
        Authorization: "Basic " + window.btoa(process.env.VUE_APP_DATA_PER_USERNPASS),
        "Content-Type": "application/json",
      };
      
      //console.log('qData: ', qData);
      this.backendApi("saveProposalResponse", propoalData, headers)
        .then((response) => {
            console.log(response);
        })
        .catch((error) => {
          console.log('error in saving proposal response', error);
        });
    },
    saveProposal(redirectionlink) {
      let productCode_DIY;
      let rm_code;
      if(sessionStorage.getItem('filledData')) {
        productCode_DIY = JSON.parse(sessionStorage.getItem("filledData"));
      }
      // console.log(productCode_DIY);
      if(sessionStorage.getItem('rm_data')) {
        rm_code = JSON.parse(sessionStorage.getItem("rm_data"));
      }

      let NRI= "";
      try {
        NRI = (this.formInfo.fieldGroups.proposerDetails.fieldGroups.personalDetails.fields.residentStatus.input == 'indianResident')?'No':'Yes';
      } catch (error) {}

      let propoalData = {
        "proposal_ui_id": this.proposalUiId.toString(),
        "ui_quote_id": this.uiQuoteId,
        "submit_response_id": "",
        "rm_code": rm_code["Userid"],
        "name": this.formInfo.fieldGroups.proposerDetails.fieldGroups.personalDetails.fields.firstName.input,
        "mobile_number": this.formInfo.fieldGroups.proposerDetails.fieldGroups.personalDetails.fields.mobileNumber.input,
        "dob": this.formInfo.fieldGroups.proposerDetails.fieldGroups.personalDetails.fields.dateOfBirth.input,
        "gender": this.formInfo.fieldGroups.proposerDetails.fieldGroups.personalDetails.fields.gender.input,
        "zipcode": this.formInfo.fieldGroups.proposerDetails.fieldGroups.communicationAddressDetails.fields.zipCode.input,
        "city": this.formInfo.fieldGroups.proposerDetails.fieldGroups.communicationAddressDetails.fields.city.input,
        "state": this.formInfo.fieldGroups.proposerDetails.fieldGroups.communicationAddressDetails.fields.state.input,
        "NRI": NRI,
        "medical": "",
        "insurance_company_code": productCode_DIY["manufacturerId"],
        "product_code": productCode_DIY["productId"],
        "proposal_json": JSON.stringify(this.formInfo)
      };

      var headers = {
        Authorization: "Basic " + window.btoa(process.env.VUE_APP_DATA_PER_USERNPASS),
        "Content-Type": "application/json",
      };
      
      //console.log('qData: ', qData);
      this.backendApi("saveProposal", propoalData, headers)
        .then((response) => {
          //console.log('save quote response: ', response);
           if(response == 'error') {
            self.apiError = true;
          } else if(response && response.data){
            if(typeof redirectionlink !== 'undefined')
            {
              window.location.href = redirectionlink;
            }
            this.proposalUiId = response.data.data.proposal_ui_id;
            this.uiQuoteId = response.data.data.ui_quote_id;

            console.log('this.proposalUiId: ', this.proposalUiId);
            sessionStorage.setItem("proposal_ui_id", JSON.stringify(this.proposalUiId));

            // Saving Logs
            let logData = {
              "ui_quote_id": this.uiQuoteId,
              "quote_request_id": this.proposalUiId,
              "mobile_number": this.userProfile.mobile,
              "dob": this.userProfile.dob,
              "page_name": "proposal",
              "tag_name": this.submittedTab,
              "data": JSON.stringify(this.formInfo)
            };
            // console.log('logData: ', logData);
            this.saveLogData(logData);

          }          
        })
        .catch((error) => {
          console.log('error msg block dp', error);
        });
    },
    getOTP() {
      console.log('get otp called');

      let otpReq = {
        "data":{
        "additionalSetup":{		
          "userCountry":"",	
          "userRegion":"",
          "userLanguage":"",
          "userIP":"",
          "userAgent":"",
          "userGeoLocation":{	
            "latitude":"",
            "longitude":""
          }
        },
        "distributor":{
          "distributorID": this.distributorID,
          "agentID":"",
          "salesChannel":"",	
          "varFields":[{
            "fieldName":"",
            "fieldValue":""
          }]
        },
        "insuranceCompanyCode": this.selectedPlan.insuranceAndProducts.insuranceCompanyCode,	
        "applicationNo":"",
        "policyNo":"",
        "quoteId": this.uiQuoteId,
        "UITrackingRefNo": this.proposalUiId,
        "OTPDetails":{	
          "mobileNumber": this.formInfo.fieldGroups.proposerDetails.fieldGroups.personalDetails.fields.mobileNumber.input,	
          "email":""
        },
        "varFields":[{		
          "fieldName":"",
          "fieldValue":""
        }]
      }};
      // console.log('otpReq: ', otpReq);
     
      this.loading = true;
      let headers = {};
      this.backendApi("sendOtpApi", otpReq, headers)
        .then((response) => {
          this.loading = false;
          console.log('get otp response', response);
        })
        .catch((error) => {
          console.log('error msg block get otp on proposal confirmation', error);
          self.loading = false;
        });

      /* let smsUserName = process.env.VUE_APP_SMS_USERNAME;
      let smsPassword = process.env.VUE_APP_SMS_PASSWORD;
      let smsFrom = process.env.VUE_APP_SMS_FROM;
      let smsUdh = process.env.VUE_APP_SMS_UDH;
      this.randOtp = Math.floor(100000 + Math.random() * 900000);
      let mobileNumber = this.formInfo.fieldGroups.proposerDetails.fieldGroups.personalDetails.fields.mobileNumber.input;
      let msgText = 'Hi ' + this.formInfo.fieldGroups.proposerDetails.fieldGroups.personalDetails.fields.firstName.input +', \n Your life term OTP is: ' + this.randOtp + '';
      let headers = {};
      let reqData = [
        smsUserName,
        smsPassword,
        mobileNumber,
        smsFrom,
        smsUdh,
        msgText
      ];

      console.log('Mobile: ', mobileNumber, 'Msg: ', msgText);
       this.backendApi("sendSMS", reqData, headers, "get")
          .then((response) => {
            console.log('Send SMS success: ', response);
          })
          .catch((error) => {
          self.loading = false;
          console.log('error msg block send sms', error);
        });
      this.sendOtp = true; */
    }, 
    resendOTP() {
    var exp = Date.parse(this.otpExpiryTime);
    console.log("expiryTime = ",this.otpExpiryTime);
    let self = this;
      const t = setInterval( function(){
        var timeleft = ((exp - new Date().getTime()));
        self.timerMinutes = Math.floor((timeleft % (1000 * 60 * 60)) / (1000 * 60));
        self.timerSeconds = Math.floor((timeleft % (1000 * 60)) / 1000);
        self.timerMinutes = self.timerMinutes < 10 ? "0"+self.timerMinutes : self.timerMinutes;
        self.timerSeconds = self.timerSeconds < 10 ? "0"+self.timerSeconds : self.timerSeconds;
        // console.log("minutes = ",self.timerMinutes);
        // console.log("seconds = ",self.timerSeconds);
        if (timeleft <= 0 || self.otpSent == null){
            clearInterval(t);
            self.disabledResend = false;
            self.timerMinutes = "";
            self.timerSeconds = "";
        }
    },1000);
      this.resendOtp = true;
    },
    submitOTP() {
      console.log('submit otp called');

      let submitOtpReq = {
        "data":{
        "distributor":{
          "distributorID": this.distributorID,
          "agentID":"",
          "salesChannel":"",	
          "varFields":[{
            "fieldName":"",
            "fieldValue":""
          }]
        },
        "insuranceCompanyCode": this.selectedPlan.insuranceAndProducts.insuranceCompanyCode,	
        "applicationNo":"",
        "policyNo":"",
        "quoteId": this.uiQuoteId,	
        "OTPDetails":{
          "OTP":"",	
          "OTPTransactionId":""
        },
        "varFields":[{
          "fieldName":"",
          "fieldValue":""
        }]
      }};

      console.log('submitOtpReq: ', submitOtpReq);
      this.loading = true;
      let headers = {};
      this.backendApi("valOtpApi", submitOtpReq, headers)
        .then((response) => {
          this.loading = false;
          console.log('submit otp response', response);
        })
        .catch((error) => {
          console.log('error msg block get otp on proposal confirmation', error);
          self.loading = false;
        });

      /* this.error = []; 
      if(this.inputedOtp.length > 0) {
        this.submitOtp = true;
        this.otpSuccess = true;
        console.log('inputed OTP: ', this.inputedOtp);
      } else {
        this.error["inputedOtp"] = "Please Enter OTP";
      } */
    },
    reqTimeOut() {
      console.log('request timeout called');
      this.timer = setTimeout(() => {
            let msg=["We are facing some issue from network, Request time out"]
            this.$router.push({ path: '/alert-diy', query: {msg: msg }});
        // alert('No response received, Please try again.');
        this.$router.go();
      }, 180000);
    }


  },
}
</script>

<style scoped lang="scss">
@import '~@/assets/scss/components/checkout.scss';
@import '~@/assets/scss/components/proposal-page.scss';
</style>