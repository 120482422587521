<template>
  <div class="loading_block_outer" v-if="loading">
    <Loading />
  </div>
  
  
  <div v-else>
    <div class="inner-page-bg">
      <div class="inner-page-margin">
        <div class="d-flex justify-content-center">
          <span id="errorList">{{this.errorListSelect}}</span>
        </div>
        <div class="row">
          <div class="white-sidebar col-md-4">
            <div
              class="
                policy-feature1
                d-flex
                justify-content-between
                align-items-center
                pt-2
                pb-2
                border-bottom
              "
            >
              <h5>Policy Cover</h5>
              <div class="select-dropdown position-relative pr-3">
                <select
                  class="form-control form-control-sm"
                  @change="onChangeAmt($event)"
                  v-model="quoteAmount"
                >
                  <option
                    v-for="(value, ind) in qAmt"
                    :key="ind"
                    v-bind:value="value"
                    :selected="value === quoteAmount"
                  >
                    &#8377; <span> {{ convertInLakh(value) }} </span>
                  </option>
                </select>
              </div>
            </div>
            <div
              class="
                policy-feature1
                d-flex
                justify-content-between
                align-items-center
                pt-2
                pb-2
                border-bottom
              "
            >
              <h5>Cover Till Age</h5>
              <div class="select-dropdown position-relative pr-3">
                <select
                  class="form-control form-control-sm"
                  @change="getCoverTillAge($event)"
                  v-model="coverTillAge"
                >
                  <option
                    v-for="(value, ind) in ageRange"
                    :key="ind"
                    v-bind:value="value"
                  >
                    {{ value }} Yrs
                  </option>
                </select>
              </div>
            </div>
            <div
              class="policy-feature1 d-flex flex-column pt-3 pb-3 border-bottom"
            >
              <h5>Premium Paying Frequency</h5>
              <div class="d-flex justify-content-between align-items-center">
                <label>
                  <input
                    class="checkbox-tools"
                    type="radio"
                    name="ppf"
                    v-model="premiumPaymentFrequency"
                    v-bind:value="'M'"
                    v-on:click="getPpf"
                  />
                  <span class="border justify-content-center">Monthly</span>
                </label>
                <label>
                  <input
                    class="checkbox-tools"
                    type="radio"
                    name="ppf"
                    v-model="premiumPaymentFrequency"
                    v-bind:value="'Q'"
                    v-on:click="getPpf"
                  />
                  <span class="border justify-content-center">Quarterly</span>
                </label>
                <label>
                  <input
                    class="checkbox-tools"
                    type="radio"
                    name="ppf"
                    v-model="premiumPaymentFrequency"
                    v-bind:value="'HY'"
                    v-on:click="getPpf"
                  />
                  <span class="border justify-content-center">Half Yearly</span>
                </label>
                <label>
                  <input
                    class="checkbox-tools"
                    type="radio"
                    name="ppf"
                    v-model="premiumPaymentFrequency"
                    v-bind:value="'Y'"
                    v-on:click="getPpf"
                  />
                  <span class="border justify-content-center">Yearly</span>
                </label>
              </div>
            </div>
            <div
              class="policy-feature1 d-flex flex-column pt-3 pb-3 border-bottom"
            >
              <div
                class="d-flex justify-content-between align-items-center mb-3"
              >
                <h5>Insurer</h5>
              </div>
              <div class="grid-container1">
                <label class="m-0 mb-1 control control-checkbox" v-for="(idt, ind) in insurerList" :key="ind">
                  {{idt.company_name}}
                  <input
                    type="checkbox"
                    :value="idt.company_code"
                    @click="getInsurer($event)"
                    v-model="insurerCodes"
                  />
                  <div class="control-indicator"></div>
                </label>
              </div>
            </div>
            <div
              class="policy-feature1 d-flex flex-column pt-3 pb-3 border-bottom"
            >
              <h5>Family Will Receive</h5>
              <div class="d-flex justify-content-between align-items-center">
                <label>
                  <input
                    type="radio"
                    name="fwr"
                    v-model="DBPOptionSelected"
                    v-bind:value="'4'"
                    v-on:click="getFwr"
                    :disabled="disableButtons"
                  />
                  <span class="border justify-content-center"
                    >Lumpsum Payout</span
                  >
                </label>
                <label>
                  <input
                    type="radio"
                    name="fwr"
                    v-model="DBPOptionSelected"
                    v-bind:value="'5'"
                    v-on:click="getFwr"
                    :disabled="disableButtons"
                  />
                  <span class="border justify-content-center"
                    >Monthly Income</span
                  >
                </label>
                <label>
                  <input
                    type="radio"
                    name="fwr"
                    v-model="DBPOptionSelected"
                    v-bind:value="'6'"
                    v-on:click="getFwr"
                    :disabled="disableButtons"
                  />
                  <span class="border justify-content-center"
                    >Lumpsum + Income</span
                  >
                </label>
              </div>
            </div>
            <div>
              <div class="policy-feature1 d-flex flex-column pt-3 pb-3">
                <h5>Pay Premium</h5>
                <div class="d-flex justify-content-between align-items-center">
                  <label>
                    <input
                      type="radio"
                      name="pp"
                      v-model="premiumPaymentOption"
                      v-bind:value="'1'"
                      v-on:click="getPayPremium"
                      :disabled="disableButtons"
                    />
                    <span class="border justify-content-center">Only Once</span>
                  </label>
                  <label>
                    <input
                      type="radio"
                      name="pp"
                      v-model="premiumPaymentOption"
                      v-bind:value="'2'"
                      v-on:click="getPayPremium"
                      :disabled="disableButtons"
                    />
                    <span class="border justify-content-center"
                      >For Entire Policy Term</span
                    >
                  </label>
                  <label>
                    <input
                      type="radio"
                      name="pp"
                      v-model="premiumPaymentOption"
                      v-bind:value="'3'"
                      v-on:click="getPayPremium"
                      :disabled="disableButtons"
                    />
                    <span class="border justify-content-center"
                      >Limited No. Of Years</span
                    >
                  </label>
                </div>
              </div>
              <div
                class="
                  policy-feature1
                  d-flex
                  justify-content-between
                  align-items-center
                  pt-2
                  pb-2
                  border-bottom
                  age-limit
                "
                v-if="premiumPayTermBlock"
              >
                <h5>Till what age do you want to pay?</h5>
                <div class="select-dropdown position-relative pr-3">
                  <select
                    class="form-control form-control-sm border"
                    @change="getPremiumPaymentTerm($event)"
                    v-model="premiumPaymentTerm2"
                  >
                    <option
                      v-for="(value, ind) in ageRange2"
                      :key="ind"
                      v-bind:value="value"
                    >
                      <span>{{ value }} Yrs</span>
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div
              class="policy-feature1 d-flex flex-column pt-3 pb-3 border-bottom"
            >
              <h5>Cover Option</h5>
              <div class="d-flex justify-content-between align-items-center">
                <label>
                  <input
                    type="radio"
                    name="coverPlan"
                    v-model="coverOption"
                    v-bind:value="'3'"
                    v-on:click="getCoverPlan"
                    :disabled="disableButtons"
                  />
                  <span class="border justify-content-center"
                    >Increasing <br />Cover Plan</span
                  >
                </label>
                <label>
                  <input
                    type="radio"
                    name="coverPlan"
                    v-model="coverOption"
                    v-bind:value="'2'"
                    v-on:click="getCoverPlan"
                    :disabled="disableButtons"
                  />
                  <span class="border justify-content-center"
                    >Decreasing <br />cover plan</span
                  >
                </label>
                <label>
                  <input
                    type="radio"
                    name="coverPlan"
                    v-model="coverOption"
                    v-bind:value="'1'"
                    v-on:click="getCoverPlan"
                    :disabled="disableButtons"
                  />
                  <span class="border justify-content-center"
                    >Level Cover <br />Plan</span
                  >
                </label>
              </div>
            </div>
            <div class="policy-feature1 return-premium d-flex pt-2 pb-2">
              <label class="m-0 mb-1 control control-checkbox p-0">
                Plans That Return My Premium
                <input
                  type="checkbox"
                  name="planReturn"
                  v-model="planReturn"
                  v-bind:value="'100'"
                  v-on:click="getRPremium"
                  :disabled="disableButtons"
                />
                <div class="control-indicator"></div>
              </label>
            </div>
          </div>
          <!-- mobile filter -->
          <div class="mobile-filter">
            <div class="policy-feature1 d-flex align-items-start flex-column">
              <h5>Policy Cover</h5>
              <div class="select-dropdown position-relative pr-3">
                <select
                  class="form-control form-control-sm"
                  v-model="quoteAmount"
                  @change="onChangeAmt($event)"
                >
                  <option
                    v-for="(value, ind) in qAmt"
                    :key="ind"
                    v-bind:value="value"
                    :selected="value === quoteAmount"
                  >
                    &#8377; <span> {{ convertInLakh(value) }} </span>
                  </option>
                </select>
              </div>
            </div>
            <div class="policy-feature1 d-flex align-items-start flex-column">
              <h5>Cover Till Age</h5>
              <div class="select-dropdown position-relative pr-3">
                <select
                  class="form-control form-control-sm"
                  @change="getCoverTillAge($event)"
                  v-model="coverTillAge"
                >
                  <option
                    v-for="(value, ind) in ageRange"
                    :key="ind"
                    v-bind:value="value"
                  >
                    {{ value }} Yrs
                  </option>
                </select>
              </div>
            </div>
            <div class="policy-feature1 d-flex align-items-start flex-column">
              <h5>Premium Frequency</h5>
              <div class="select-dropdown position-relative pr-3">
                <select
                  class=""
                  @change="getPpf($event)"
                  v-model="premiumPaymentFrequency"
                >
                  <option><span>Select</span></option>
                  <option v-bind:value="'M'"><span>Monthly</span></option>
                  <option v-bind:value="'Q'"><span>Quarterly</span></option>
                  <option v-bind:value="'HY'"><span>Half Yearly</span></option>
                  <option v-bind:value="'Y'"><span>Yearly</span></option>
                </select>
              </div>
            </div>
          </div>
          <div class="comparison-section col-md-8">
            <!--atart  -->
            <div class="d-flex justify-content-between align-items-center comparison-header mt-3 mb-3" >
              <div>
                <h3 v-if="noOfResults">Showing {{ noOfResults }} Results</h3>
              </div>

              

              <div class="d-flex justify-content-between align-items-center" >
                <div class="qt-link" v-if="responseData.length > 0">
                <a @click="openEmailModal()" class="cursor-pointer">
                  <img src="~@/assets/images/email.svg" alt="" >
                  <span>Send Quotes To Customer</span>
                </a>
              </div>
                <div class="compare-plans d-flex align-items-center" >
                  <div class="user-dropdown">
                    <div class="dropdown">
                      <button
                        class="
                          d-flex
                          justify-content-between
                          align-items-center
                          dropdown-toggle
                        "
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <img src="~@/assets/images/user-dropdown.png" alt="" />
                      </button>
                      <div
                        class="dropdown-menu dropdown-menu-right"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <ul class="d-flex justify-content-between align-items-center">
                          <li class="mr-1">
                            <img v-if="userData.gender == 'Male'" src="~@/assets/images/boy-profile.png" alt="" />
                            <img v-if="userData.gender == 'Female'" src="~@/assets/images/girl-profile.png" alt="" />   
                          </li>
                            
                          <li class="mr-1 position-relative">
                            <span>{{ userData.age }}</span>Y
                          </li>
                          <li class="mr-1 position-relative">
                            <img
                              src="~@/assets/images/non-smoking-area.png"
                              alt=""
                              v-if="userData.smoke == 'No'"
                            />
                            <img
                              src="~@/assets/images/smoking-area.png"
                              alt=""
                              v-if="userData.smoke == 'Yes'"
                            />
                          </li>
                          <!-- <li class="mr-1">{{ userData.inlakhs }} L</li> -->
                          <li
                            class="cursor-pointer"
                            data-toggle="modal"
                            data-target="#editprofile"
                          >
                            <img src="~@/assets/images/edit-profile.png" alt="" />
                          </li>
                        </ul>
                        <ul class="d-flex justify-content-between align-items-center">
                          <li class="mr-1">
                            <img v-if="user2Data.gender == 'Male'" src="~@/assets/images/boy-profile.png" alt="" />
                            <img v-if="user2Data.gender == 'Female'" src="~@/assets/images/girl-profile.png" alt="" />
                          </li>
                          <li class="mr-1 position-relative">
                            <span>{{ user2Data.age }}</span>Y
                          </li>
                          <li class="mr-1 position-relative">
                            <img
                              src="~@/assets/images/non-smoking-area.png"
                              alt=""
                              v-if="user2Data.smoke == 'No'"
                            />
                            <img
                              src="~@/assets/images/smoking-area.png"
                              alt=""
                              v-if="user2Data.smoke == 'Yes'"
                            />
                          </li>
                          <!-- <li class="mr-1">{{ userData.inlakhs }} L</li> -->
                          <li
                            class="cursor-pointer"
                            data-toggle="modal"
                            data-target="#editsecondprofile"
                          >
                            <img src="~@/assets/images/edit-profile.png" alt="" />
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                

                <div class="recommended-dropdown">
                  <div class="dropdown">
                    <button
                      class="dropdown-toggle"
                      type="button"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <img src="~@/assets/images/sort.png" alt="" />
                      Sort
                    </button>
                    <div
                      class="dropdown-menu dropdown-menu-right"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <ul>
                        <li>Sort</li>
                        <li
                          @click="sortByLowestPremium(1)"
                          :class="
                            sortSelectedId === 1 ? 'sort-selected-item' : null
                          "
                        >
                          Lowest Premium
                        </li>
                        <li
                          @click="sortByHighestPremium(2)"
                          :class="
                            sortSelectedId === 2 ? 'sort-selected-item' : null
                          "
                        >
                          Highest Policy Term
                        </li>
                        <li
                          @click="sortByMaxClaimSttled(3)"
                          :class="
                            sortSelectedId === 3 ? 'sort-selected-item' : null
                          "
                        >
                          Max. Claim Settled
                        </li>
                        <li
                          @click="sortByHigherCover(4)"
                          :class="
                            sortSelectedId === 4 ? 'sort-selected-item' : null
                          "
                        >
                          Higher Cover
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                
              </div>
            </div>
            <!-- end -->
            
            <div class="plans-section">
              <div class="error-text pt-2" v-if="apiError">
                Insurance Gateway not responding, please try again
              </div>

              <div class="mb-4" v-for="(dt, ind) in responseData" :key="ind">
                <div class="insurer-outer-div" v-if="getInsurerFilter(dt.insuranceAndProducts.insuranceCompanyCode)">
                  <div class="outer-div" v-if="getPremiumAmt(dt.individualDetails[0].premiumDetails) != null"> 
                    <div class="plan1">
                      <div class="d-flex align-items-center">
                        <label class="m-0 control control-checkbox">
                          <input
                            type="checkbox"
                            :id="'plan_p' + ind"
                            :value="ind + '_P'"
                            @click="getCompare($event)"
                          />
                          <div class="control-indicator"></div>
                        </label>
                        <router-link to="#">
                          <span>
                            <!-- <img :src="dt.supportingData.insCompanyLogo" /> -->
                            <img
                              :src="
                                getInsurerImgs(
                                  dt.insuranceAndProducts.insuranceCompanyCode
                                )
                              "
                              alt=""
                            />
                            <br />  
                            <i> {{ dt.insuranceAndProducts.productName }} 
                              
                            </i>
                          </span>
                        </router-link>
                        <div class="ml-4 mob-plan-amt">
                          <div class="d-flex align-items-center mb-2">
                            <div class="plan-amt">
                              <div>
                                &#8377;
                                {{
                                  convertInLakh(dt.individualDetails[0].sumAssured)
                                }}
                              </div>
                              <i v-if="checkMandatoryRiderFlag(dt)"><text>( {{ getSumAssuredAmtRiderSA(dt) }} )</text>
                                <div class="hover-tooltip">
                                  <img src="~@/assets/images/info-button.png" alt="">
                                  <div class="tooltip-content">
                                    <p>{{ getBreakUpMessage(dt) }}</p>
                                  </div> <!--tooltip-content-->
                                </div><br/>
                              </i> 
                              <i>Policy Cover</i>
                              <a
                              href=""
                              class="mob-d-none"
                              data-toggle="modal"
                              :data-target="'#mobile-feature-model' + ind"
                            >
                              Features <i class="icon-keyboard_arrow_right"></i>
                            </a>
                            </div>
                            <div class="plan-amt">
                              <div>
                                {{
                                  parseInt(dt.individualDetails[0].age) +
                                  parseInt(dt.productDetails.policyTerm)
                                }}
                                Yrs
                              </div>
                              <i
                                >Max Limit:
                                {{ dt.supportingData.validRanges.PolicyTerm.max }}
                                Yrs</i
                              >
                              <div class="mob-claim-settle">
                                Claims Settled:
                                <b>
                                  {{
                                  getCsValue(
                                      dt.supportingData.Messages
                                        .ClaimSettlementPercent
                                    )
                                  }}
                                  %
                                </b>
                                <i data-toggle="modal" data-target="#claimSettle" title="Click Here" @click="showClaimPopup(dt.supportingData.Messages.ClaimSettlementPercent, dt.insuranceAndProducts.insuranceCompanyCode)">
                                  <img src="~@/assets/images/info-button.png" alt="">
                                </i>
                              </div>
                            </div>
                            <div class="plan-amt mob-d-none">
                              <div class="cursor-pointer">
                                {{
                                  getCsValue(
                                    dt.supportingData.Messages
                                      .ClaimSettlementPercent
                                  )
                                }}%
                                <i data-toggle="modal" data-target="#claimSettle" title="Click Here" @click="showClaimPopup(dt.supportingData.Messages.ClaimSettlementPercent, dt.insuranceAndProducts.insuranceCompanyCode)">
                                  <img src="~@/assets/images/info-button.png" alt="">
                                </i>
                              </div>
                              <i>Claims Settled</i>
                            </div>

                            <div class="plan-amt mob-d-none">
                              <a
                                target="_blank"
                                :href="dt.supportingData.Messages.brochureLink"
                              >
                                <img src="~@/assets/images/download.png" alt="" />
                              </a>
                            </div>
                          </div>
                          <div class="d-flex mob-feature-modal">
                            <div
                              class="
                                extra-feature
                                mr-4
                                d-flex
                                justify-content-between
                                align-items-center
                                mob-d-none
                              "
                              v-if="
                                dt.supportingData.Messages.TypeOfMedical != 'null'
                              "
                            >
                              <img src="~@/assets/images/phone.svg" alt="" />
                              {{ dt.supportingData.Messages.TypeOfMedical }}
                            </div>
                            <div
                              class="
                                extra-feature
                                d-flex
                                justify-content-between
                                align-items-center
                                mob-d-none
                              "
                              @click="
                                showClaimPopup(
                                  dt.supportingData.Messages.ClaimSettlementPercent
                                )
                              "
                            >
                              <img src="~@/assets/images/clock.svg" alt="" />
                              {{ dt.supportingData.Messages.ClaimSettlementTAT }}
                              claim settlement
                            </div>
                            <div
                              class="modal fade mobile-feature-model"
                              :id="'mobile-feature-model' + ind"
                              tabindex="-1"
                              role="dialog"
                              aria-labelledby="exampleModalLabel"
                              aria-hidden="true"
                            >
                              <div class="modal-dialog" role="document">
                                <div class="modal-content">
                                  <div class="modal-header">
                                    <h5 class="modal-title" id="exampleModalLabel">
                                      Features
                                    </h5>
                                    <button
                                      type="button"
                                      class="close"
                                      data-dismiss="modal"
                                      aria-label="Close"
                                    >
                                      <span aria-hidden="true">&times;</span>
                                    </button>
                                  </div>
                                  <div class="modal-body">
                                    <ul>
                                      <li
                                        v-if="
                                          dt.supportingData.Messages
                                            .TypeOfMedical != 'null'
                                        "
                                      >
                                        <img
                                          src="~@/assets/images/phone.svg"
                                          alt=""
                                        />
                                        {{
                                          dt.supportingData.Messages.TypeOfMedical
                                        }}
                                      </li>
                                      <li>
                                        <img
                                          src="~@/assets/images/clock.svg"
                                          alt=""
                                        /> {{ dt.supportingData.Messages .ClaimSettlementTAT  }}claim settlement
                                       </li>
                                      <li>
                                        <div
                                          v-for="(dt2, ind2) in dt.productDetails
                                            .AddOnSection.addOnSelected"
                                          :key="ind2"
                                        >
                                          <div
                                            class="
                                              policy-feature1
                                              d-flex
                                              pt-2
                                              pb-2
                                              position-relative
                                            "
                                            v-if="dt2.premiumIncludedFlag != null"
                                          >
                                            <span
                                              class="
                                                m-0
                                                mb-0
                                                control control-checkbox
                                              "
                                              v-if="
                                                dt2.premiumIncludedFlag === 'Yes'
                                              "
                                            >
                                              <img
                                                src="~@/assets/images/check-mark.svg"
                                                class="check-markimg"
                                                alt=""
                                              />

                                              <a v-if="dt2.optionSelected == '17'" data-toggle="modal" data-target="#editsecondprofile">{{ dt2.optionName }} </a>
                                              <text v-else>
                                                {{
                                                  dt2.optionName
                                                }}
                                              </text>
                                              <b>Free</b>
                                            </span>
                                            <label
                                              class="
                                                m-0
                                                mb-0
                                                control control-checkbox
                                              "
                                              v-if="
                                                dt2.premiumIncludedFlag !== 'Yes'
                                              "
                                            >
                                            <a v-if="dt2.optionSelected == '17'" data-toggle="modal" data-target="#editsecondprofile">{{ dt2.optionName }} </a>
                                              <text v-else>
                                              {{
                                                dt2.optionName
                                              }}
                                              </text> 
                                              <b v-if="dt2.premiumDetails">&nbsp; &#8377;
                                                {{
                                                  getPremiumAmt2(dt2.premiumDetails)
                                                }}
                                              </b>
                                              <input
                                                type="checkbox"
                                                :value="dt2.optionSelected"
                                                :v-model="dt2.checked"
                                                @click="
                                                  updateAddOn($event, ind, ind2)
                                                "
                                              />
                                              <div class="control-indicator"></div>
                                            </label>
                                          </div>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="w-140 mob-policy-price-amt">
                          <div
                            class="
                              d-flex
                              justify-content-end
                              align-items-center
                              policy-price
                              mb-3
                            "
                          >
                            <div class="policy-price-amt">
                              &#8377;<b>
                              {{
                                getPremiumAmt(dt.individualDetails[0].premiumDetails)
                              }}
                              </b>
                            </div>
                            <a v-on:click="goToPolicyDetails(dt)" class="cursor-pointer"
                             :id="dt.insuranceAndProducts.insuranceCompanyCode + '-' + dt.insuranceAndProducts.productCode"
                             >
                              <i class="icon-navigate_next yellow-bg-arrow"
                              :id="dt.insuranceAndProducts.insuranceCompanyCode + '-' + dt.insuranceAndProducts.productCode"></i>
                            </a>
                          </div>
                          <div
                            class="
                              extra-feature
                              d-flex
                              justify-content-between
                              align-items-center
                              green-bg
                            "
                            v-if="
                              dt.supportingData.Messages.MarketingMessages[0]
                                .Message != 'null' && dt.supportingData.Messages.MarketingMessages[0]
                                .Message != ''
                            "
                          >
                            {{
                              dt.supportingData.Messages.MarketingMessages[0]
                                .Message
                            }}
                          </div>
                        </div>
                      </div>
                      <div class="discount-mob-d-none">
                        <div>
                          <a target="_blank" :href="dt.supportingData.Messages.brochureLink">
                            <img src="~@/assets/images/download.png" alt="" />
                          </a>
                        </div>
                        <div
                          class="
                            extra-feature
                            d-flex
                            justify-content-between
                            align-items-center
                            green-bg
                          "
                          v-if="
                            dt.supportingData.Messages.MarketingMessages[0]
                              .Message != 'null'
                          "
                        >
                          {{
                            dt.supportingData.Messages.MarketingMessages[0].Message
                          }}
                        </div>
                        <div
                          class="
                            d-flex
                            justify-content-end
                            align-items-center
                            policy-price
                          "
                        >
                          <div class="policy-price-amt">
                            &#8377;<b>
                            {{
                              getPremiumAmt(dt.individualDetails[0].premiumDetails)
                            }}
                            </b>
                          </div>
                          <a v-on:click="goToPolicyDetails(dt)" class="cursor-pointer"
                          :id="dt.insuranceAndProducts.insuranceCompanyCode + '-' + dt.insuranceAndProducts.productCode"
                          :value="dt.insuranceAndProducts.insuranceCompanyCode + '-' + dt.insuranceAndProducts.productCode">
                            <i class="icon-navigate_next yellow-bg-arrow"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div class="more-features-section position-relative mob-d-none toggle" v-if="countAddOns(dt.productDetails.AddOnSection.addOnSelected, dt.individualDetails[0].rider)">
                      <div>
                        <div class="collapse" :id="'collapseExample' + ind + '_out'">
                          <div class="d-flex1">
                            <!-- AddOns -->
                            <div
                              v-for="(dt2, ind2) in dt.productDetails.AddOnSection
                                .addOnSelected"
                              :key="ind2"
                            >
                              <div
                                class="
                                  policy-feature1
                                  d-flex
                                  pt-2
                                  pb-2
                                  position-relative
                                "
                                v-if="dt2.premiumIncludedFlag != null"
                              >
                                <label
                                  class="m-0 mb-0 control p-0"
                                  v-if="dt2.premiumIncludedFlag === 'Yes'"
                                >
                                  <img
                                    src="~@/assets/images/check-mark.svg"
                                    class="check-markimg"
                                    alt=""
                                  />
                                  <a v-if="dt2.optionSelected == '17'" data-toggle="modal" data-target="#editsecondprofile">{{ dt2.optionName }} </a>
                                  <text v-else>
                                  {{ dt2.optionName }}
                                  </text>
                                  <b> Free</b>
                                </label>
                                <label
                                  class="ml-4 mb-0 control control-checkbox p-0"
                                  v-if="dt2.premiumIncludedFlag !== 'Yes'"
                                > 
                                  <a v-if="dt2.optionSelected == '17'" data-toggle="modal" data-target="#editsecondprofile">{{ dt2.optionName }} </a>
                                  <text v-else>
                                  {{ dt2.optionName }}
                                  </text>
                                  <b v-if="dt2.premiumDetails">&nbsp; &#8377;
                                    {{ getPremiumAmt2(dt2.premiumDetails) }}
                                  </b>
                                  <input
                                    type="checkbox"
                                    class="add-padding"
                                    :value="dt2.optionSelected"
                                    :v-model="dt2.checked"
                                    @click="updateAddOn($event, ind, ind2)"
                                  />
                                  <div class="control-indicator"></div>
                                </label>
                              </div>
                            </div>
                            <!-- ./ AddOns -->
                            <!-- Rider Section -->
                            <div
                              v-for="(dt3, ind3) in dt.individualDetails[0].rider"
                              :key="ind3"
                            >
                              <div
                                class="
                                  policy-feature1
                                  d-flex
                                  pt-2
                                  pb-2
                                  position-relative
                                "
                              >
                                <label
                                  class="ml-4 mb-0 control control-checkbox p-0"
                                > 
                                  {{ dt3.riderName }}
                                  <b v-if="dt3.premiumDetails">&nbsp; &#8377;
                                    {{ getPremiumAmt3(dt3.premiumDetails) }}
                                  </b>
                                  <input
                                    type="checkbox"
                                    class="add-padding"
                                    :value="dt3.ridercode"
                                    :v-model="dt3.checked"
                                    @click="updateRider($event, ind, ind3)"
                                  />
                                  <div class="control-indicator"></div>
                                </label>
                              </div>
                            </div>
                            <!-- ./ Rider Section -->
                          </div>
                        </div>
                        <a class="cursor-pointer d-flex" data-toggle="collapse" @click="showAddOnBlock(ind + '_out')"> 
                          <div v-if="countAddOns(dt.productDetails.AddOnSection.addOnSelected, dt.individualDetails[0].rider) > 2">
                                  + {{ countAddOns(dt.productDetails.AddOnSection.addOnSelected, dt.individualDetails[0].rider) - 2 }} 
                                  more  
                          </div>
                          <i class="icon-keyboard_arrow_down" 
                          v-if="countAddOns(dt.productDetails.AddOnSection.addOnSelected, dt.individualDetails[0].rider) > 2"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="erMsg">
                <b>{{ erMsg }}</b>
              </div>

              <div class="out-of-range" v-if="responseDataOutOfRange.length > 0">
                <div class="similar-policy-head"><b>We have other similar policies, you can look into it.</b></div> 
                <div class="mb-4" v-for="(dt, ind) in responseDataOutOfRange" :key="ind">
                  <div class="insurer-outer-div" v-if="getInsurerFilter(dt.insuranceAndProducts.insuranceCompanyCode)">
                    <div class="outer-div"> 
                      <div class="plan1">
                        <div class="d-flex align-items-center">
                          <label class="m-0 control control-checkbox">
                            <input
                              type="checkbox"
                              :id="'plan_o' + ind"
                              :value="ind + '_O'"
                              @click="getCompare($event)"
                            />
                            <div class="control-indicator"></div>
                          </label>
                          <router-link to="#">
                            <span>
                              <!-- <img :src="dt.supportingData.insCompanyLogo" /> -->
                              <img
                                :src="
                                  getInsurerImgs(
                                    dt.insuranceAndProducts.insuranceCompanyCode
                                  )
                                "
                                alt=""
                              />
                              <br />  
                              <i> {{ dt.insuranceAndProducts.productName }} 
                                
                              </i>
                            </span>
                          </router-link>
                          <div class="ml-4 mob-plan-amt">
                            <div class="d-flex align-items-center mb-2">
                              <div class="plan-amt">
                                <div :class="checkOutOfBondCondition('sumAssured', dt.supportingData.outOfRangeDetails)">
                                  &#8377;
                                  {{
                                    convertInLakh(dt.individualDetails[0].sumAssured)
                                  }}
                                </div>
                                <i v-if="checkMandatoryRiderFlag(dt)"><text>( {{ getSumAssuredAmtRiderSA(dt) }} )</text>
                                  <div class="hover-tooltip">
                                    <img src="~@/assets/images/info-button.png" alt="">
                                    <div class="tooltip-content">
                                      <p>{{ getBreakUpMessage(dt) }}</p>
                                    </div> <!--tooltip-content-->
                                  </div><br/>
                                </i> 
                                <i>Policy Cover</i>
                                <a
                                href=""
                                class="mob-d-none"
                                data-toggle="modal"
                                :data-target="'#mobile-feature-model' + ind"
                              >
                                Features <i class="icon-keyboard_arrow_right"></i>
                              </a>
                              </div>
                              <div class="plan-amt">
                                <div :class="checkOutOfBondCondition('policyTerm', dt.supportingData.outOfRangeDetails)">
                                  {{
                                    parseInt(dt.individualDetails[0].age) +
                                    parseInt(dt.productDetails.policyTerm)
                                  }}
                                  Yrs
                                </div>
                                <i
                                  >Max Limit:
                                  {{ dt.supportingData.validRanges.PolicyTerm.max }}
                                  Yrs</i
                                >
                                <div class="mob-claim-settle">
                                  Claims Settled:
                                  <b>
                                    {{
                                    getCsValue(
                                        dt.supportingData.Messages
                                          .ClaimSettlementPercent
                                      )
                                    }}
                                    %
                                  </b>
                                  <i data-toggle="modal" data-target="#claimSettle" title="Click Here" @click="showClaimPopup(dt.supportingData.Messages.ClaimSettlementPercent, dt.insuranceAndProducts.insuranceCompanyCode)">
                                    <img src="~@/assets/images/info-button.png" alt="">
                                  </i>
                                </div>
                              </div>
                              <div class="plan-amt mob-d-none">
                                <div class="cursor-pointer">
                                  {{
                                    getCsValue(
                                      dt.supportingData.Messages
                                        .ClaimSettlementPercent
                                    )
                                  }}%
                                  <i data-toggle="modal" data-target="#claimSettle" title="Click Here" @click="showClaimPopup(dt.supportingData.Messages.ClaimSettlementPercent, dt.insuranceAndProducts.insuranceCompanyCode)">
                                    <img src="~@/assets/images/info-button.png" alt="">
                                  </i>
                                </div>
                                <i>Claims Settled</i>
                              </div>

                              <div class="plan-amt mob-d-none">
                                <a
                                  target="_blank"
                                  :href="dt.supportingData.Messages.brochureLink"
                                >
                                  <img src="~@/assets/images/download.png" alt="" />
                                </a>
                              </div>
                            </div>
                            <div class="d-flex mob-feature-modal">
                              <div
                                class="
                                  extra-feature
                                  mr-4
                                  d-flex
                                  justify-content-between
                                  align-items-center
                                  mob-d-none
                                "
                                v-if="
                                  dt.supportingData.Messages.TypeOfMedical != 'null'
                                "
                              >
                                <img src="~@/assets/images/phone.svg" alt="" />
                                {{ dt.supportingData.Messages.TypeOfMedical }}
                              </div>
                              <div
                                class="
                                  extra-feature
                                  d-flex
                                  justify-content-between
                                  align-items-center
                                  mob-d-none
                                "
                                @click="
                                  showClaimPopup(
                                    dt.supportingData.Messages.ClaimSettlementPercent
                                  )
                                "
                              >
                                <img src="~@/assets/images/clock.svg" alt="" />
                                {{ dt.supportingData.Messages.ClaimSettlementTAT }}
                                claim settlement
                              </div>
                              <div
                                class="modal fade mobile-feature-model"
                                :id="'mobile-feature-model' + ind"
                                tabindex="-1"
                                role="dialog"
                                aria-labelledby="exampleModalLabel"
                                aria-hidden="true"
                              >
                                <div class="modal-dialog" role="document">
                                  <div class="modal-content">
                                    <div class="modal-header">
                                      <h5 class="modal-title" id="exampleModalLabel">
                                        Features
                                      </h5>
                                      <button
                                        type="button"
                                        class="close"
                                        data-dismiss="modal"
                                        aria-label="Close"
                                      >
                                        <span aria-hidden="true">&times;</span>
                                      </button>
                                    </div>
                                    <div class="modal-body">
                                      <ul>
                                        <li
                                          v-if="
                                            dt.supportingData.Messages
                                              .TypeOfMedical != 'null'
                                          "
                                        >
                                          <img
                                            src="~@/assets/images/phone.svg"
                                            alt=""
                                          />
                                          {{
                                            dt.supportingData.Messages.TypeOfMedical
                                          }}
                                        </li>
                                        <li>
                                          <img
                                            src="~@/assets/images/clock.svg"
                                            alt=""
                                          /> {{ dt.supportingData.Messages .ClaimSettlementTAT  }}claim settlement
                                        </li>
                                        <li>
                                          <div
                                            v-for="(dt2, ind2) in dt.productDetails
                                              .AddOnSection.addOnSelected"
                                            :key="ind2"
                                          >
                                            <div
                                              class="
                                                policy-feature1
                                                d-flex
                                                pt-2
                                                pb-2
                                                position-relative
                                              "
                                              v-if="dt2.premiumIncludedFlag != null"
                                            >
                                              <span
                                                class="
                                                  m-0
                                                  mb-0
                                                  control control-checkbox
                                                "
                                                v-if="
                                                  dt2.premiumIncludedFlag === 'Yes'
                                                "
                                              >
                                                <img
                                                  src="~@/assets/images/check-mark.svg"
                                                  class="check-markimg"
                                                  alt=""
                                                />

                                                <a v-if="dt2.optionSelected == '17'" data-toggle="modal" data-target="#editsecondprofile">{{ dt2.optionName }} </a>
                                                <text v-else>
                                                  {{
                                                    dt2.optionName
                                                  }}
                                                </text>
                                                <b>Free</b>
                                              </span>
                                              <label
                                                class="
                                                  m-0
                                                  mb-0
                                                  control control-checkbox
                                                "
                                                v-if="
                                                  dt2.premiumIncludedFlag !== 'Yes'
                                                "
                                              >
                                              <a v-if="dt2.optionSelected == '17'" data-toggle="modal" data-target="#editsecondprofile">{{ dt2.optionName }} </a>
                                                <text v-else>
                                                {{
                                                  dt2.optionName
                                                }}
                                                </text> 
                                                <b v-if="dt2.premiumDetails">&nbsp; &#8377;
                                                  {{
                                                    getPremiumAmt2(dt2.premiumDetails)
                                                  }}
                                                </b>
                                                <input
                                                  type="checkbox"
                                                  :value="dt2.optionSelected"
                                                  :v-model="dt2.checked"
                                                  @click="
                                                    updateAddOn($event, ind, ind2)
                                                  "
                                                />
                                                <div class="control-indicator"></div>
                                              </label>
                                            </div>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="w-140 mob-policy-price-amt">
                            <div
                              class="
                                d-flex
                                justify-content-end
                                align-items-center
                                policy-price
                                mb-3
                              "
                            >
                              <div class="policy-price-amt">
                                &#8377;<b>
                                {{
                                  getPremiumAmt4(dt.individualDetails[0].premiumDetails)
                                }}
                                </b>
                              </div>
                              <a v-on:click="goToPolicyDetails(dt)" class="cursor-pointer">
                                <i class="icon-navigate_next yellow-bg-arrow"></i>
                              </a>
                            </div>
                            <div
                              class="
                                extra-feature
                                d-flex
                                justify-content-between
                                align-items-center
                                green-bg
                              "
                              v-if="
                                dt.supportingData.Messages.MarketingMessages[0]
                                  .Message != 'null' && dt.supportingData.Messages.MarketingMessages[0]
                                  .Message != ''
                              "
                            >
                              {{
                                dt.supportingData.Messages.MarketingMessages[0]
                                  .Message
                              }}
                            </div>
                          </div>
                        </div>
                        <div class="discount-mob-d-none">
                          <div>
                            <a target="_blank" :href="dt.supportingData.Messages.brochureLink">
                              <img src="~@/assets/images/download.png" alt="" />
                            </a>
                          </div>
                          <div
                            class="
                              extra-feature
                              d-flex
                              justify-content-between
                              align-items-center
                              green-bg
                            "
                            v-if="
                              dt.supportingData.Messages.MarketingMessages[0]
                                .Message != 'null'
                            "
                          >
                            {{
                              dt.supportingData.Messages.MarketingMessages[0].Message
                            }}
                          </div>
                          <div
                            class="
                              d-flex
                              justify-content-end
                              align-items-center
                              policy-price
                            "
                          >
                            <div class="policy-price-amt">
                              &#8377;<b>
                              {{
                                getPremiumAmt4(dt.individualDetails[0].premiumDetails)
                              }}
                              </b>
                            </div>
                            <a v-on:click="goToPolicyDetails(dt)" class="cursor-pointer">
                              <i class="icon-navigate_next yellow-bg-arrow"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div class="more-features-section position-relative mob-d-none toggle" v-if="countAddOns(dt.productDetails.AddOnSection.addOnSelected, dt.individualDetails[0].rider)">
                        <div>
                          <div class="collapse" :id="'collapseExample' + ind">
                            <div class="d-flex1">
                              <div
                                v-for="(dt2, ind2) in dt.productDetails.AddOnSection
                                  .addOnSelected"
                                :key="ind2"
                              >
                                <div
                                  class="
                                    policy-feature1
                                    d-flex
                                    pt-2
                                    pb-2
                                    position-relative
                                  "
                                  v-if="dt2.premiumIncludedFlag != null"
                                >
                                  <label
                                    class="m-0 mb-0 control p-0"
                                    v-if="dt2.premiumIncludedFlag === 'Yes'"
                                  >
                                    <img
                                      src="~@/assets/images/check-mark.svg"
                                      class="check-markimg"
                                      alt=""
                                    />
                                    <a v-if="dt2.optionSelected == '17'" data-toggle="modal" data-target="#editsecondprofile">{{ dt2.optionName }} </a>
                                    <text v-else>
                                    {{ dt2.optionName }}
                                    </text>
                                    <b> Free</b>
                                  </label>
                                  <label
                                    class="ml-4 mb-0 control control-checkbox p-0"
                                    v-if="dt2.premiumIncludedFlag !== 'Yes'"
                                  > 
                                    <a v-if="dt2.optionSelected == '17'" data-toggle="modal" data-target="#editsecondprofile">{{ dt2.optionName }} </a>
                                    <text v-else>
                                    {{ dt2.optionName }}
                                    </text>
                                    <!-- <b v-if="dt2.premiumDetails">&nbsp; &#8377;
                                      {{ getPremiumAmt2(dt2.premiumDetails) }}
                                    </b> -->
                                    <input
                                      type="checkbox"
                                      class="add-padding"
                                      :value="dt2.optionSelected"
                                      :v-model="dt2.checked"
                                      @click="updateAddOn($event, ind, ind2)"
                                    />
                                    <div class="control-indicator"></div>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <a class="cursor-pointer d-flex" data-toggle="collapse" @click="showAddOnBlock(ind)"> 
                            <div v-if="countAddOns(dt.productDetails.AddOnSection.addOnSelected, dt.individualDetails[0].rider) > 2">
                                    + {{ countAddOns(dt.productDetails.AddOnSection.addOnSelected, dt.individualDetails[0].rider) - 2 }} 
                                    more  
                            </div>
                            <i class="icon-keyboard_arrow_down" 
                            v-if="countAddOns(dt.productDetails.AddOnSection.addOnSelected, dt.individualDetails[0].rider) > 2"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
              

              <div v-if="loading2" class="text-center">
                <img class="loader2" src="~@/assets/images/loader-gray.gif" alt="" />
                <!-- <img src="~@/assets/images/loading_gif.gif" alt="" /> -->
              </div>

            </div>
          </div>
        </div>
      </div>

      <!-- claim settle modal-->
      <div
        class="modal fade"
        id="claimSettle"
        tabindex="-1"
        role="dialog"
        aria-labelledby="claimSettleLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <div class="modal-body">
              <span>
                <img :src="popupInsurerImg" alt="" />
              </span>
              <h4>Claim settlement ratio for last few years</h4>
              <div class="img-holder">
                <ul>
                  <li v-for="(data, ind) in claimModalResult" :key="ind">
                    <span> {{ data.year }} </span>
                    <div class="badge-content">
                      <figure>
                        <img
                          v-if="ind == '0'"
                          src="~@/assets/images/orange-badge.png"
                          alt=""
                        />
                        <img
                          v-if="ind != '0'"
                          src="~@/assets/images/blue-badge.png"
                          alt=""
                        />
                      </figure>
                      <figcaption class="text-center">
                        <b>{{ data.value }}%</b>
                      </figcaption>
                    </div>
                  </li>
                </ul>
                <div class="standing-man">
                  <img src="~@/assets/images/standing-man.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mob-compare-plans">
        <ul class="d-flex justify-content-center align-items-center">
          <li
            class=""
            data-toggle="modal"
            data-target="#exampleModalLong"
          >
            <img src="~@/assets/images/m-filter.png" alt="" />
          </li>
        </ul>
      </div>
    </div>

    <!-- Compare Section -->
    <div class="bottom-wrap z-index-2" 
        v-if="idsToCompare.length > 0">
      <div
        class="compare-plan-bottom"
      >
        <div class="d-flex justify-content-center align-items-center">
          <div
            class="
              plan-card
              position-relative
              d-flex
              justify-content-center
              align-items-center
              flex-column
              mr-4
            "
            v-for="(dt3, ind3) in selectedForFilter"
            :key="ind3"
          >
            <span class="cursor-pointer" v-on:click="removeFromFilter(ind3)">
              <img src="~@/assets/images/cross.svg" alt=""/>
            </span>
            <img
              :src="getInsurerImgs(dt3.insuranceAndProducts.insuranceCompanyCode)"
              alt=""
            />
            <h4>{{ dt3.insuranceAndProducts.productName }}</h4>
          </div>
          <div
            class="
              plan-card
              d-flex
              justify-content-center
              align-items-center
              flex-column
              mr-4
              add-more-plan
            "
            v-on:click="scrollToTop"
            v-if="idsToCompare.length < 3"
          >
            <img src="~@/assets/images/add-more-plan.png" alt="" />
            <h4>Add more plans</h4>
          </div>
          
          <div>
            <!-- <button class="compare-plan-btn">
              Compare
              <i class="icon-navigate_next"></i>
            </button> -->
            <router-link to="/compareplan" class="compare-plan-btn">
              Compare <i class="icon-navigate_next"></i>
            </router-link>

            
          </div>
        </div>
      </div>
    </div>
    <!-- ./Filter Section -->

    <div
      class="modal fade"
      id="exampleModalLong"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLongTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">Filters</h5>
          </div>
          <div class="modal-body">
            <div class="mob-filter-wrap mb-2 mob-filter-insurer">
              <div
                class="d-flex justify-content-between align-items-center mb-1"
              >
                <h5>Insurer</h5>
              </div>   
              <div class="d-flex flex-column justify-content-start align-items-start">

                <label class="m-0 mb-1 control control-checkbox" v-for="(idt, ind) in insurerList" :key="ind">
                  {{idt.company_name}}
                  <input
                    type="checkbox"
                    :value="idt.company_code"
                    @click="getInsurer($event)"
                    v-model="insurerCodes"
                  />
                  <div class="control-indicator"></div>
                </label>
              </div>
            </div>
            <div class="mob-filter-wrap mb-2">
              <div
                class="d-flex justify-content-between align-items-center mb-1"
              >
                <h5>Family Will Receive</h5>
              </div>
              <div class="d-flex align-items-start flex-column">
                <div>
                  <input
                    type="radio"
                    name="fwr2"
                    v-model="DBPOptionSelected"
                    v-bind:value="'4'"
                    v-on:click="getFwr"
                    :disabled="disableButtons"
                  />
                  <label>Lumpsum Payout</label>
                </div>
                <div>
                  <input
                    type="radio"
                    name="fwr2"
                    v-model="DBPOptionSelected"
                    v-bind:value="'5'"
                    v-on:click="getFwr"
                    :disabled="disableButtons"
                  />
                  <label>Monthly Income</label>
                </div>
                <div>
                  <input
                    type="radio"
                    name="fwr2"
                    v-model="DBPOptionSelected"
                    v-bind:value="'6'"
                    v-on:click="getFwr"
                    :disabled="disableButtons"
                  />
                  <label>Lumpsum + Income</label>
                </div>
              </div>
            </div>
            <div class="mob-filter-wrap mb-2">
              <div
                class="d-flex justify-content-between align-items-center mb-1"
              >
                <h5>Pay Premium</h5>
              </div>
              <div class="d-flex align-items-start flex-column">
                <div>
                  <input
                    type="radio"
                    name="pp2"
                    v-model="premiumPaymentOption"
                    v-bind:value="'1'"
                    v-on:click="getPayPremium"
                    :disabled="disableButtons"
                  />
                  <label>Only Once</label>
                </div>
                <div>
                  <input
                    type="radio"
                    name="pp2"
                    v-model="premiumPaymentOption"
                    v-bind:value="'2'"
                    v-on:click="getPayPremium"
                    :disabled="disableButtons"
                  />
                  <label>For Entire Policy Term</label>
                </div>
                <div>
                  <input
                    type="radio"
                    name="pp2"
                    v-model="premiumPaymentOption"
                    v-bind:value="'3'"
                    v-on:click="getPayPremium"
                    :disabled="disableButtons"
                  />
                  <label>Limited no. of Years</label>
                </div>
              </div>
              <div
                class="
                  policy-feature1
                  pt-2
                  pb-2
                  age-limit
                  pad-left
                "
                v-if="premiumPayTermBlock"
              >
                <h5>Till what age do you want to pay?</h5>
                <div class="select-dropdown position-relative pr-3">
                  <select
                    class="form-control1 mob-select-drpdwn d-block ml-3"
                    @change="getPremiumPaymentTerm($event)"
                    v-model="premiumPaymentTerm2"
                  >
                    <option
                      v-for="(value, ind) in ageRange2"
                      :key="ind"
                      v-bind:value="value"
                    >
                      <span>{{ value }} Yrs</span>
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="mob-filter-wrap mb-2">
              <div
                class="d-flex justify-content-between align-items-center mb-1"
              >
                <h5>Cover Options</h5>
              </div>
              <div class="d-flex align-items-start flex-column">
                <div>
                  <input
                    type="radio"
                    name="coverPlan2"
                    v-model="coverOption"
                    v-bind:value="'3'"
                    v-on:click="getCoverPlan"
                    :disabled="disableButtons"
                  />
                  <label>Increasing Cover Plan</label>
                </div>
                <div>
                  <input
                    type="radio"
                    name="coverPlan2"
                    v-model="coverOption"
                    v-bind:value="'2'"
                    v-on:click="getCoverPlan"
                    :disabled="disableButtons"
                  />
                  <label>Decreasing cover plan</label>
                </div>
                <div>
                  <input
                    type="radio"
                    name="coverPlan2"
                    v-model="coverOption"
                    v-bind:value="'1'"
                    v-on:click="getCoverPlan"
                    :disabled="disableButtons"
                  />
                  <label>Level Cover Plan</label>
                </div>
              </div>
            </div>
            <div class="policy-feature1 mob-return-premium d-flex pt-2 pb-2">
              <label class="m-0 mb-1 control control-checkbox p-0">
                Plans that Return my Premium
                <input
                  type="checkbox"
                  name="planReturn2"
                  v-model="planReturn"
                  v-bind:value="'100'"
                  v-on:click="getRPremium"
                  :disabled="disableButtons"
                />
                <div class="control-indicator"></div>
              </label>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="" data-dismiss="modal">
              <i class="icon-keyboard_arrow_left"></i>Back to qoutes
            </button>
          </div>
        </div>
      </div>
    </div>
  

 <div
      class="modal fade"
      id="emailModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="emailModalTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="emailModalTitle">Enter Email Id</h5>
          </div>
          <div class="modal-body">
            <div class="benefit-input form-control1">
              <input type="text" v-model.trim="quoteSendingEmails"/>
              <p class="error-text"> {{ quoteSendingEmailsErrorMsg }} </p>
            </div>
            
            
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" @click="sendQuotesToCustomer()">Send Quotes</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "@/components/loading.vue";
import $ from "jquery";

import {getDistributer,getInsurerList,getPolicyCover,getInsuranceImages,getOPTSelected,getLazyLoadingConfig} from '@/mixins/common';


export default {
  name: "policyquote",
  emits: ["myVarChanged3"],
  props: [
    'items',
    'userProfileData',
    'userProfileData2'
  ],
  components: {
    Loading,
    
  },
  mixins:[getDistributer,getInsurerList,getPolicyCover,getInsuranceImages,getOPTSelected,getLazyLoadingConfig],
  watch: { 
    userProfileData: function(newVal, oldVal) { // watch it
      console.log('Prop changed: ', newVal, ' | was: ', oldVal)
      this.userData = newVal;
      this.testApi();
    },
    userProfileData2: function(newVal, oldVal) { // watch it
      console.log('Prop changed2: ', newVal, ' | was2: ', oldVal)
      this.user2Data = newVal;
    }
  },
  mounted() {    
    // removing existing proposal session
    localStorage.removeItem("filledData");
    localStorage.removeItem("filledDataTab");
    sessionStorage.removeItem("filledData");
    sessionStorage.removeItem("filledDataTab");

    // Setting Distributer ID
    this.distributorID = getDistributer()["distributerID"];
    this.distributorLogo = getDistributer()["distributorLogo"];
    this.contactEmail = getDistributer()["contactEmail"];
    this.infoEmail = getDistributer()["infoEmail"];
    this.contactNumber = getDistributer()["contactNumber"];

    // Code without restrictions
    this.qAmt = getPolicyCover();
    this.apiCallinginterval = parseInt(getLazyLoadingConfig()['inerval']);
    this.noOfTimesAPICalls = parseInt(getLazyLoadingConfig()['nooftimes']);

    this.userData = JSON.parse(sessionStorage.getItem("user_profile"));
    if (sessionStorage.getItem("user2_profile")) {
      this.user2Data = JSON.parse(sessionStorage.getItem("user2_profile"));
    }
    let startAge = parseInt(this.userData.age) + 5;

    for (var i = startAge; i <= 100; i++) {
      this.ageRange.push(i);
    }

    this.setAgeRange2(startAge, 60);

    this.insurerList = getInsurerList();
    
    var self = this;

    /* setTimeout(function(){
      self.getLogData();
    }, 15000); */

    if (sessionStorage.getItem("user_profile")) {
      this.userData = JSON.parse(sessionStorage.getItem("user_profile"));
    }
    if (sessionStorage.getItem("user2_profile")) {
      this.user2Data = JSON.parse(sessionStorage.getItem("user2_profile"));
    }
    
    if (sessionStorage.getItem("rm_data")){
      this.rmData = JSON.parse(sessionStorage.getItem("rm_data"));
      this.agentID = this.rmData.Userid;
      console.log('this.agentID: ', this.agentID)
    }
    
    localStorage.setItem('currentRoute', JSON.stringify("policy-quote"));

    this.testApi();
  },
  data() {
    return {
      errorListSelect:"",
      errorFlag:false,
      errorMsg:"",
      errorMsgList:[],
      loading: false,
      loading2: false,
      userData: "",
      rmData: "",
      typeOfQuote: "Multi-Quote",
      quoteCategory: "Sum Assured",
      alternateFreqRequired: "Yes",
      outOfBoundConfig: "No",
      // Distributer Details 
      distributorID: "D002",
      agentID: "A001",
      //agentID: "",
      salesChannel: "Others",
      coverTillAge: "",
      // Personal Details
      memberType: "Life Assured",
      memberSequenceNumber: 1,
      title: "",
      maritalStatus: "Married",
      occupation: "",
      relationWithFirstLifeAssured: "Self",
      riderTerm: "",
      riderPremiumPayingTerm: "",
      riderSA: "",
      // Product Details
      productType: "",
      productCode: "",
      policyTerm: null,
      policyTermUnit: "",
      premiumPaymentOption: "",
      premiumPaymentTerm: null,
      premiumPaymentTerm2: null,
      premiumPayTermBlock: false,
      premiumPaymentFrequency: "",

      planOptionSelected: "",
      coverOption: "",
      DBPOptionSelected: "",
      lumpsumPayoutPercent: "",
      lumpsumAmount: "",
      incomeAmount: "",
      incomeFrequency: "",
      incomeTerm: "",
      incomeTermUnit: "",
      incomeIncreasingPercent: "",

      returnOfPremiumPercentage: 0,
      AddOnsOptionSelected: "",
      addOnSumAssured: "",

      riderTerm: "",
      riderPremiumPayingTerm: "",
      riderSA: "",

      newOptionName: "",
      newOptionSelected: "",
      quoteAmount: 0,

      // Others
      qAmt: [],
      ageRange: [],
      ageRange2:[],
      url_data: null,
      apiData: {},
      responseData: [],
      responseDataOutOfRange: [],
      noOfResults: "",
      noOfResults2: "",
      apiError: "",
      insurerCodes: [],
      insurerCodesArray: [],
      addOnOptionSelected: [],
      sortSelectedId: 0,
      idsToCompare: [],

      claimModalResult: [],
      popupInsurerImg: "",
      selectedForFilter: [],
      filterPageValues: {
        quoteAmount: "",
        coverTillAge: "",
        premiumPayingFreq: "",
        fwr: "",
        coverPlan: ""
      },
      uiQuoteId: "",
      requestId: "",
      noOfCalls: 0,
      apiCallinginterval: "",
      noOfTimesAPICalls: 1,
      tempCount2: 1,
      polling: null,
      userData: "",
      user2Data: "",
      erMsg: "",
      planReturn: false,
      insurerList: "",
      quoteSendingEmails: "",
      quoteSendingEmailsErrorMsg: "",
      disableButtons: false,
      pollapiflag: false
    };

    //var ClaimSettlementPercent = {'2017_2018':'97.9', '2018_2019':'98.6','2019_2020':'97.8','2020_2021':'97.9'};
  },
  methods: {
    // Interval related function
    
    pollData() { 
      //console.log('called poll');
      this.disableButtons = true;
      this.polling = setInterval(() => {
        //if(this.noOfCalls < this.noOfTimesAPICalls) {
        //console.log("PollData PollDataFlag: "+ this.pollapiflag);
        if(this.pollapiflag == false){
          this.getLifeTerm();
        } else {
          this.loading2 = false;

          if(this.responseData.length == 0) {
            this.erMsg = 'No products found as per your selected filter. Please change the filters.';
          }

          this.beforeDestroy();
        }
      }, this.apiCallinginterval)
    },
    beforeDestroy () {
      //console.log("before destroy called");
      this.disableButtons = false;
      clearInterval(this.polling)
    },
    // End: Interval related function
    saveQuote() {
      //console.log('save quote called');
      var headers = {
        Authorization: "Basic " + window.btoa(process.env.VUE_APP_DATA_PER_USERNPASS),
        "Content-Type": "application/json",
      };
      
      let qData = {
        "life_assured_name": this.userData.fullname,
        "mobile_number": this.userData.mobile,
        "dob": this.userData.dob,
        "gender": this.userData.gender,
        "zipcode": this.userData.zipcode,
        "annual_income": this.userData.annualincome,
        "employee_type": this.userData.employmenttype,
        "education": this.userData.education,
        "smoker": this.userData.smoke,
        "type_of_quote": this.apiData.typeOfQuote,
        "rm_code": this.agentID,
        "rm_info_json": JSON.stringify(this.apiData.distributor),
        "quote_request_json": JSON.stringify(this.apiData)
      };
      //console.log('qData: ', qData);
      this.backendApi("saveQuote", qData, headers)
        .then((response) => {
          //console.log('save quote response: ', response);
          console.log("response status code",response.status)
           if(response == 'error') {
            self.apiError = true;
           

          }
          else if(response.status=="error")
          {
            let msg=['We are facing some issue while saving quote data']

            this.$router.push({ path: '/networkalert', query: {backUrl:'policy-quote', msg: msg }});
          }
          
          else if(response && response.data){
            

            this.uiQuoteId = response.data.data.ui_quote_id;
              console.log("savequote")
              this.getWhatsapp();
              this.getQuotationApi()
            
            //console.log('ui quote id: ', this.uiQuoteId)
          }
        })
        .catch((error) => {
          console.log('error msg block dp', error);
          let msg=['We are facing some issue from backend while saving quote data ']
          this.$router.push({ path: '/networkalert', query: {backUrl:'policy-quote', msg: msg}}); 
                 });
    },
     getWhatsapp(){
       this.render=false
       this.consent = this.userData.whatsapp ? '1' : '0'    
       let qData={
       "mobileNumber": this.userData.mobile,
       "quoteId": this.uiQuoteId,
       "consent": this.consent
        } 
        //console.log(qData);
         let headers = {};
        
        this.backendApi("getWhatsappDetails", qData, headers)
        .then((response) => {
         console.log(response);
        })
        .catch((error) => {
          console.log("error msg block dp", error);
          // alert("Unable to save Quote")
        });

    },
    saveQuoteResponse(quoteResData) {
      var headers= {
        Authorization: "Basic " + window.btoa(process.env.VUE_APP_DATA_PER_USERNPASS),
        "Content-Type": "application/json",
      };
      this.backendApi("saveQuoteResponse", quoteResData, headers)
        .then((response) => {
          //console.log('save quote response: ', response);
           if(response == 'error') {
            self.apiError = true;
            // let msg=['We are facing some issue while saving quote response data']

            // this.$router.push({ path: '/alert', query: {backUrl:'policy-quote', msg: msg }});
          }
          //  else if(response.status=="error")
          // {
          //   let msg=['We are facing some issue while saving quote response data']

          //   this.$router.push({ path: '/alert', query: {backUrl:'policy-quote', msg: msg }});
          // }
           else if(response && response.data) {
            //this.uiQuoteId = response.data.data.ui_quote_id;
            //console.log('ui quote id: ', this.uiQuoteId)
          }
        })
        .catch((error) => {
          console.log('error msg block dp', error);
            // let msg=['We are facing some issue from backend while saving quote response data ']

            // this.$router.push({ path: '/alert', query: {backUrl:'policy-quote', msg: msg }});
        });
    },
    getLogData() {
      //console.log('get log data called');
      var  headers = {
        Authorization: "Basic " + window.btoa(process.env.VUE_APP_DATA_PER_USERNPASS),
        "Content-Type": "application/json",
      };
     
      let qData = [this.userData.mobile, this.userData.dob];
      //console.log('qData: ', qData);
      this.backendApi("getLogData", qData, headers, "get")
        .then((response) => {
          //console.log('save quote response: ', response);
           if(response == 'error') {
            self.apiError = true;
          } else if(response && response.data) {

              if(response.data.data.length > 0) {
                console.log('response.data.data: ', response.data.data)
                var res = confirm("Do you want to resume your previous journey?");
                if (res == true) {
                  if(response.data.data.page_name == 'policydetails') {
                    sessionStorage.setItem("selected_plan_data", JSON.stringify(response.data.data.data));
                    sessionStorage.setItem("quote_request", JSON.stringify(response.data.data.quote));
                  } else if(response.data.data.page_name == 'checkout') {
                    sessionStorage.setItem("selectedPlanForCheckout", JSON.stringify(response.data.data.data));
                    sessionStorage.setItem("quoteRequestID", JSON.stringify(response.data.data.ui_quote_id));
                  }
                 
                  this.$router.push(response.data.data.page_name);
                } else {
                    //console.log('no pressed');
                }
              } else {
                //console.log('log data length 0');
              }

          }
        })
        .catch((error) => {
          console.log('error msg block dp', error);
        });
    },
    getQuotationApi()
    {
       this.beforeDestroy();
      var self = this;
      
      if(this.apiData.product.premiumPaymentOption == 3){
        this.premiumPayTermBlock = true;
        //this.premiumPaymentTerm2 = null;
      } else {
        this.premiumPayTermBlock = false;
      }

      this.insurerCodes = [];
     

      // console.log("get policy called");
      // console.log(' this.noOfCalls: ', this.noOfCalls);
      // let reqData = JSON.stringify(apiData);
      // //localStorage.setItem('apiData', JSON.stringify(this.apiData));
      sessionStorage.setItem("quote_request", JSON.stringify(this.apiData));
      // var headers = {
      //   Authorization: "Basic " + window.btoa(process.env.VUE_APP_USERNPASS),
      //   "Content-Type": "application/json",
      // };
      this.loading = true;
      let reqData=  {"data":this.apiData};
      let headers = {};
      
      this.backendApi("quotationApiGet", reqData, headers)
        .then((response) => {
          
          this.loading = false;
          
          this.responseData = [];
          // console.log("response",response)
          if(response == 'error') {
            self.apiError = true;
            console.log('error block called : get policy'); 
          } 
          else if(response.data.errors.length>0)
          {
            let msg=[]
            this.errorMsg="We are facing some issue while fetching quotation Api"
              for(let i=0;i<response.data.errors.length;i++)
              {
                  msg.push(response.data.errors[i]["errorMessage"])
              }
              // this.errorFlag=true
            this.$router.push({ path: '/networkalert', query: {backUrl:'policy-quote',msgError:this.errorMsg,reqId:response.data.reqId, msg:msg  }});

          }
          else if(response.status=="error")
          {
            console.log("error",response)

          }
          else if(response) {
            this.apiError = false;
            this.requestId = response.data.reqId;
            this.loading2 = true;

            if(response.data?.data?.quote?.length > 0) {
              if(self.tempCount2 == 1) {
                self.tempCount2++;
                response.data.data.quote.forEach((element, index) => {
                  self.responseData.push(element);
                })
                if(self.responseData.length > 0) {
                  this.loading2 = false;
                }

                self.quoteAmount = self.responseData[0].individualDetails[0].sumAssured;
                self.coverTillAge = parseInt(self.responseData[0].individualDetails[0].age) + parseInt(self.responseData[0].productDetails.policyTerm);
                self.setAgeRange2(self.responseData[0].individualDetails[0].age, self.coverTillAge);


                


                // premium payment frequency setting

                self.premiumPaymentFrequency = self.responseData[0].productDetails.premiumPaymentFrequency;
                self.apiData.product.premiumPaymentFrequency = self.premiumPaymentFrequency;
                sessionStorage.setItem("quote_request", JSON.stringify(self.apiData));
                self.DBPOptionSelected = self.responseData[0].productDetails.DBPoption.optionSelected;
                self.premiumPaymentOption = self.responseData[0].productDetails.premiumPaymentOption;
                // if(self.responseData[0].productDetails.CoverOption.optionSelected) {
                //   self.coverOption = self.responseData[0].productDetails.CoverOption.optionSelected.toString();
                // }
                self.premiumPaymentTerm = parseInt(self.responseData[0].productDetails.premiumPaymentTerm);
                if(self.premiumPaymentOption == 3) {
                  self.premiumPaymentTerm2 = parseInt(self.responseData[0].individualDetails[0].age) + parseInt(self.responseData[0].productDetails.premiumPaymentTerm);
                  console.log('inside premium pay iiiiiiiii: ', self.premiumPaymentTerm2);
                }
                self.policyTerm = parseInt(self.responseData[0].productDetails.policyTerm);
                //self.premiumPaymentTerm = parseInt(self.responseData[0].productDetails.premiumPaymentTerm) + parseInt(self.responseData[0].individualDetails[0].age);
                self.filterPageValues = {
                  quoteAmount: self.quoteAmount,
                  coverTillAge: self.coverTillAge,
                  premiumPayingFreq: self.premiumPaymentFrequency,
                  fwr: self.DBPOptionSelected,
                  coverOption: self.coverOption
                }
                // Condition for No of records
                self.getPpf3(self.premiumPaymentFrequency);
                localStorage.setItem("filterPageValues", JSON.stringify(self.filterPageValues));
              } else {
                response.data.data.quote.forEach((element, index) => {
                  self.responseData.push(element);
                })

                // Condition for No of records

                this.getPpf3(self.premiumPaymentFrequency);
              }
              localStorage.setItem("responseData", JSON.stringify(this.responseData));

              // Quote response api section
              
              //  console.log("after savequote")
                 let qResData = {
                "ui_quote_id": this.uiQuoteId,
                "quote_response_id": response.data.reqId,
                "annual_income": this.userData.annualincome,
                "quote_response_data": JSON.stringify(response.data.data.quote)
              };
              this.saveQuoteResponse(qResData);
              
              
            }

            if(response.data.errors.length == 0)
            {
              setTimeout(function(){
                self.getLifeTerm();
                self.pollData();
              }, 1000);
            }
            else
            {
              this.loading2 = false;
              if(this.responseData.length == 0) {
                this.erMsg = 'No products found as per your selected filter. Please change the filters.';
                this.beforeDestroy();
              }
            }
          }
          
        })
        .catch((error) => {
          console.log('error msg block : get policy', error);
          self.loading = false;
          self.apiError = true;
            let msg=['We are facing some issue from while fetching quotation API']

            this.$router.push({ path: '/networkalert', query: {backUrl:'policy-quote', msg: msg }});
        });
    },
    getPolicy(apiData) {
      this.saveQuote();
      
    },
    getLifeTerm() {

      
      //this.noOfCalls++;
      var self = this;
      this.loading2 = true;
      this.pollapiflag = false;
      // var headers = {
      //   Authorization: "Basic " + window.btoa(process.env.VUE_APP_USERNPASS),
      //   "Content-Type": "application/json",
      // };
      let headers = {};
      let reqData = [this.requestId];

      //  console.log('request id: ', reqData);
      this.backendApi("getLifeTermApi", reqData, headers, "get")
        .then((response) => {
          if(self.responseData.length > 0) {
            this.loading2 = false;
          }
          if(response == 'error') {
            //self.apiError = true;
          } else if(response && response.data) {

            if(response.data?.data?.quote?.length > 0) {

              if(self.tempCount2 == 1) {
                self.tempCount2++;
                response.data.data.quote.forEach((element, index) => {
                  if(element.supportingData.outOfRangeDetails.length > 0) {
                    self.responseDataOutOfRange.push(element);
                  } else {
                    self.responseData.push(element);
                  }
                })

                console.log('self.responseDataOutOfRange: ', self.responseDataOutOfRange);

                if(self.responseData.length > 0) {
                  this.loading2 = false;
                }

                self.quoteAmount = self.responseData[0].individualDetails[0].sumAssured;
                self.coverTillAge = parseInt(self.responseData[0].individualDetails[0].age) + parseInt(self.responseData[0].productDetails.policyTerm);
                self.setAgeRange2(self.responseData[0].individualDetails[0].age, self.coverTillAge);

                // Setting 2nd life details
                
                // if (!sessionStorage.getItem("user2_profile") && self.responseData[0].individualDetails[1]) {
                //   console.log('inside user2data')
                //   let spouseDetails = self.responseData[0].individualDetails[1];
                //   var today = new Date();
                //   var dd = String(today.getDate()).padStart(2, '0');
                //   var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
                //   var yyyy = today.getFullYear();

                //   let dob = (yyyy - spouseDetails.age) +'-'+ mm +'-' + dd;
                //   let tempUser2Data = {
                //     fullname: null,
                //     gender: spouseDetails.gender,
                //     dob: dob,
                //     age: spouseDetails.age,
                //     employmenttype: spouseDetails.occupation,
                //     smoke: spouseDetails.tobacco
                //   };
                //   this.user2Data = tempUser2Data;
                //   localStorage.setItem("user2Profile", JSON.stringify(this.user2Data));
                //   sessionStorage.setItem("user2_profile", JSON.stringify(this.user2Data));
                //   this.$emit('myVarChanged3', this.user2Data);

                //   // Setting User2Data in API Request
                //   if(this.user2Data) {
                //     this.apiData.personalInformation.individualDetails[1] = {
                //       memberType: "Life Assured",
                //       memberSequenceNumber: 2,
                //       firstName: this.user2Data.fullname,
                //       middleName: "",
                //       lastName: "",
                //       gender: this.user2Data.gender,
                //       dateOfBirth: this.user2Data.dob,
                //       maritalStatus: "",
                //       qualification: "",
                //       occupation: this.user2Data.employmenttype,
                //       annualIncome: parseInt(this.userData.annualincome),
                //       email: "",
                //       tobacco: this.user2Data.smoke,
                //       relationWithFirstLifeAssured: "Spouse",
                //       zipCode: this.userData.zipcode.toString(),
                //       state: "",
                //       city: "",
                //       riderDetails: [
                //         {
                //           riderCode: "",
                //           varFields: [
                //             {
                //               fieldName: "",
                //               fieldValue: "",
                //             },
                //           ],
                //         },
                //       ],
                //       varFields: [
                //         {
                //           fieldName: "isStaffOfPNB",
                //           fieldValue: "No",
                //         },
                //       ],
                //     }

                //     if(this.quoteAmount > 0) {
                //       this.apiData.personalInformation.individualDetails[1].quoteAmount = this.quoteAmount;
                //     }
                //   }
                //   // -----------------------------

                // } else {
                //   console.log('else block for user2 data');
                // }
                

                // premium payment frequency setting
                self.premiumPaymentFrequency = self.responseData[0].productDetails.premiumPaymentFrequency;
                self.apiData.product.premiumPaymentFrequency = self.premiumPaymentFrequency;
                sessionStorage.setItem("quote_request", JSON.stringify(self.apiData));

                self.DBPOptionSelected = self.responseData[0].productDetails.DBPoption.optionSelected;
                self.premiumPaymentOption = self.responseData[0].productDetails.premiumPaymentOption;
                self.coverOption = self.responseData[0].productDetails.CoverOption.optionSelected.toString();
                self.premiumPaymentTerm = parseInt(self.responseData[0].productDetails.premiumPaymentTerm);

                if(self.premiumPaymentOption == 3) {
                  self.premiumPaymentTerm2 = parseInt(self.responseData[0].individualDetails[0].age) + parseInt(self.responseData[0].productDetails.premiumPaymentTerm);
                  console.log('inside premium pay iiiiiiiii: ', self.premiumPaymentTerm2);
                }
                
                self.policyTerm = parseInt(self.responseData[0].productDetails.policyTerm);
                //self.premiumPaymentTerm = parseInt(self.responseData[0].productDetails.premiumPaymentTerm) + parseInt(self.responseData[0].individualDetails[0].age);
                console.log('self.premiumPaymentTerm inner: ', self.premiumPaymentTerm)
                self.filterPageValues = {
                  quoteAmount: self.quoteAmount,
                  coverTillAge: self.coverTillAge,
                  premiumPayingFreq: self.premiumPaymentFrequency,
                  fwr: self.DBPOptionSelected,
                  coverOption: self.coverOption
                }

                // Condition for No of records
                self.getPpf3(self.premiumPaymentFrequency);

                localStorage.setItem("filterPageValues", JSON.stringify(self.filterPageValues));

              } else {
                response.data.data.quote.forEach((element, index) => {
                  self.responseData.push(element);
                })
                // Condition for No of records
                this.getPpf3(self.premiumPaymentFrequency);
              }
              
              localStorage.setItem("responseData", JSON.stringify(this.responseData));

              // Quote response api section
              let qResData = {
                "ui_quote_id": this.uiQuoteId,
                "quote_response_id": response.data.reqId,
                "annual_income": this.userData.annualincome,
                "quote_response_data": JSON.stringify(response.data.data.quote)
              };

              this.saveQuoteResponse(qResData);
            } 

            // Stop Polling
            console.log("resposne PollDataFlag: "+response.data.data.isPollComplete);
            if(response.data.data.isPollComplete != undefined) {
              if(response.data.data.isPollComplete == true) {
                this.pollapiflag = true;
              }
            }
            console.log("PollDataFlag: "+ this.pollapiflag);
            

          }

        })
        .catch((error) => {
          console.log('error msg block', error);
          self.loading = false;
          //self.apiError = true;
        });

      

    },
    convertInLakh(amt) {
      if (amt < 10000000) {
        return amt / 100000 + "L";
      } else {
        return amt / 10000000 + "Cr.";
      }
    },

    testApi() {
      // Setting default value for each call
      this.beforeDestroy();
      this.erMsg = "";
      this.noOfCalls = 0;
      this.noOfResults = 0;
      this.tempCount2 = 1;
     
      if (sessionStorage.getItem("quote_request") && sessionStorage.getItem("quote_request_flag") == 1){
        sessionStorage.removeItem('quote_request_flag');
        this.apiData = JSON.parse(sessionStorage.getItem("quote_request"));
        this.apiData.typeOfQuote = "Multi-Quote";
        this.apiData.product.insuranceAndProducts = [];  
      } else {
          this.apiData = {
          typeOfQuote: "Multi-Quote",
          quoteCategory: "Sum Assured",
          alternateFreqRequired: "Yes",
          outOfBoundConfig: "Yes",
          distributor: {
            distributorID: this.distributorID,
            agentID: this.agentID,
            salesChannel: this.salesChannel,
            varFields: [
              {
                fieldName: "typeOfSale",
                fieldValue: "assisted"
              },
              {
                fieldName: "channelPartner",
                fieldValue: ""
              }
            ],
          },
          personalInformation: {
            individualDetails: [
              {
                memberType: "Life Assured",
                memberSequenceNumber: 1,
                //title: "",
                firstName: this.userData.fullname,
                middleName: "",
                lastName: "",
                gender: this.userData.gender,
                dateOfBirth: this.userData.dob,
                maritalStatus: "",
                qualification: "",
                occupation: "",
                mobileNumber: this.userData.mobileNumber,
                annualIncome: parseInt(this.userData.annualincome),
                email: "",
                tobacco: this.userData.smoke,
                mobileNumber: parseInt(this.userData.mobile),
                relationWithFirstLifeAssured: "Self",
                zipCode: this.userData.zipcode.toString(),
                state: "",
                city: "",
                riderDetails: [
                  {
                    riderCode: "",
                    varFields: [
                      {
                        fieldName: "",
                        fieldValue: "",
                      },
                    ],
                  },
                ],
                varFields: [
                  {
                    fieldName: "isStaffOfPNB",
                    fieldValue: "No",
                  },
                ],
              },
            ],
          },
          product: {
            productType: "LifeTerm",
            insuranceAndProducts: this.insurerCodesArray,
            premiumPaymentTerm: this.premiumPaymentTerm,
            policyTerm: this.policyTerm,
            policyTermUnit: "",
            premiumPaymentOption: this.premiumPaymentOption,
            premiumPaymentFrequency: this.premiumPaymentFrequency,
            planOption: {
              OptionSelected: "",
              varFields: [
                {
                  fieldName: "",
                  fieldValue: "",
                },
              ],
            },
            coverOption: {
              OptionSelected: this.coverOption,
              varFields: [
                {
                  fieldName: "",
                  fieldValue: "",
                },
              ],
            },
            DBPoption: {
              OptionSelected: this.DBPOptionSelected,
              incomeTermUnit: "",
              varFields: [
                {
                  fieldName: "",
                  fieldValue: "",
                },
              ],
            },
            AddOnSection: {
              returnOfPremium: {
                returnOfPremiumPercentage: this.returnOfPremiumPercentage,
                varFields: [
                  {
                    fieldName: "",
                    fieldValue: "",
                  },
                ],
              },
              AddOns: [
                {
                  OptionSelected: "",
                  varFields: [
                    {
                      fieldName: "",
                      fieldValue: "",
                    },
                  ],
                },
              ],
            },
            riderInformation: {
              riderDetails: [
                {
                  riderCode: "",
                  varFields: [
                    {
                      fieldName: "",
                      fieldValue: "",
                    },
                  ],
                },
              ],
            },
            newOptions: {
              newOptionName: "",
              optionValues: [
                {
                  OptionSelected: "",
                  varFields: [
                    {
                      fieldName: "",
                      fieldValue: "",
                    },
                  ],
                },
              ],
            },
            varFields: [
              {
                fieldName: "",
                fieldValue: "",
              },
            ],
          },
          other: {
            keyFeatureDocLanguage: "",
            varFields: [
              {
                fieldName: "paymentMethod",
                fieldValue: "Online",
              },
            ],
          },
        };
      }

      
      
      // Setting User2Data in API Request
      if(this.user2Data) {
        this.apiData.personalInformation.individualDetails[1] = {
          memberType: "Life Assured",
          memberSequenceNumber: 2,
          firstName: this.user2Data.fullname,
          middleName: "",
          lastName: "",
          gender: this.user2Data.gender,
          dateOfBirth: this.user2Data.dob,
          maritalStatus: "",
          qualification: "",
          occupation: this.user2Data.employmenttype,
          annualIncome: parseInt(this.userData.annualincome),
          email: "",
          tobacco: this.user2Data.smoke,
          relationWithFirstLifeAssured: "Spouse",
          zipCode: this.userData.zipcode.toString(),
          state: "",
          city: "",
          riderDetails: [
            {
              riderCode: "",
              varFields: [
                {
                  fieldName: "",
                  fieldValue: "",
                },
              ],
            },
          ],
          varFields: [
            {
              fieldName: "isStaffOfPNB",
              fieldValue: "No",
            },
          ],
        }

        if(this.quoteAmount > 0) {
          this.apiData.personalInformation.individualDetails[1].quoteAmount = this.quoteAmount;
        }
      }
      
      // setting Quote Amount
      if(this.quoteAmount > 0) {
        this.apiData.personalInformation.individualDetails[0].quoteAmount = this.quoteAmount;
      }
      console.log('this.apiData: ', this.apiData);
      this.getPolicy(this.apiData);
    },
    getPpf(event) {
      let isCacheEnabled = false;
      this.erMsg = "";
      this.premiumPaymentFrequency = event.target.value;
      this.apiData.product.premiumPaymentFrequency = this.premiumPaymentFrequency;
      sessionStorage.setItem("quote_request", JSON.stringify(this.apiData));
      console.log('this.premiumPaymentFrequency', this.premiumPaymentFrequency);
      if(isCacheEnabled)
      {
        this.getPolicy(this.apiData);
      }      

      let counter = 0;
      if(this.insurerCodes.length > 0) {
        console.log('if block ppf')
        this.responseData.forEach((element, index) => {
          let itemIndex = element.individualDetails[0].premiumDetails.findIndex(item => item.mode == this.premiumPaymentFrequency);
          let insurerIndex = this.insurerCodes.indexOf(element.insuranceAndProducts.insuranceCompanyCode);
          if(itemIndex > -1 && insurerIndex > -1) {
            counter++;
          } 
        })
      } else {
        //console.log('else block get ppf');
        this.responseData.forEach((element, index) => {
          let itemIndex = element.individualDetails[0].premiumDetails.findIndex(item => item.mode == this.premiumPaymentFrequency);
          //console.log('item index: ', itemIndex)
          if(itemIndex > -1) {
            counter++;
          } 
        })
      }
      this.noOfResults = counter;
      if(this.noOfResults == 0 && this.noOfCalls < 5) {
        this.erMsg = 'We are searching for quotes as per your selected criteria, please wait…';
      } else if(this.noOfResults == 0) {
        this.erMsg = 'No products found as per your selected filter. Please change the filters.';
      } else {
        this.erMsg = '';
      }
      //this.testApi();
    },
    getPpf2(){
      this.erMsg = "";
      let counter = 0;
      if(this.insurerCodes.length > 0) {
        console.log('if block ppf2')
        this.responseData.forEach((element, index) => {
          let itemIndex = element.individualDetails[0].premiumDetails.findIndex(item => item.mode == this.premiumPaymentFrequency);
          let insurerIndex = this.insurerCodes.indexOf(element.insuranceAndProducts.insuranceCompanyCode);
          if(itemIndex > -1 && insurerIndex > -1) {
            counter++;
          } 
        })
      } else {
        //console.log('else block get ppf2');
        this.responseData.forEach((element, index) => {
          let itemIndex = element.individualDetails[0].premiumDetails.findIndex(item => item.mode == this.premiumPaymentFrequency);
          //console.log('item index: ', itemIndex)
          if(itemIndex > -1) {
            counter++;
          } 
        })
      }
      this.noOfResults = counter;
      if(this.noOfResults == 0 && this.noOfCalls < 5) {
        this.erMsg = 'We are searching for quotes as per your selected criteria, please wait…';
      } else if(this.noOfResults == 0) {
        this.erMsg = 'No products found as per your selected filter. Please change the filters.';
      } else {
        this.erMsg = '';
      }
    },
    getPpf3(value) {
      this.premiumPaymentFrequency = value;
      this.apiData.product.premiumPaymentFrequency = this.premiumPaymentFrequency;
      sessionStorage.setItem("quote_request", JSON.stringify(this.apiData));
      console.log('this.premiumPaymentFrequency', this.premiumPaymentFrequency);
      let counter = 0;
      if(this.insurerCodes.length > 0) {
        console.log('if block ppf')
        this.responseData.forEach((element, index) => {
          let itemIndex = element.individualDetails[0].premiumDetails.findIndex(item => item.mode == this.premiumPaymentFrequency);
          let insurerIndex = this.insurerCodes.indexOf(element.insuranceAndProducts.insuranceCompanyCode);
          if(itemIndex > -1 && insurerIndex > -1) {
            counter++;
          } 
        })
      } else {
        //console.log('else block get ppf');
        this.responseData.forEach((element, index) => {
          let itemIndex = element.individualDetails[0].premiumDetails.findIndex(item => item.mode == this.premiumPaymentFrequency);
          //console.log('item index: ', itemIndex)
          if(itemIndex > -1) {
            counter++;
          } 
        })
      }
      this.noOfResults = counter;
      if(this.noOfResults == 0 && this.noOfCalls < 5) {
        this.erMsg = 'We are searching for quotes as per your selected criteria, please wait…';
      } else if(this.noOfResults == 0){
        this.erMsg = 'No products found as per your selected filter. Please change the filters.';
      } else {
        this.erMsg = '';
      }
    },
    onChangeAmt(event) {
      console.log(event.target.value);
      this.quoteAmount = parseInt(event.target.value);
      this.testApi();
    },
    getCoverTillAge(event) {
      console.log(event.target.value);
      this.policyTerm = parseInt(event.target.value) - parseInt(this.userData.age);
      this.premiumPaymentTerm = parseInt(event.target.value) - parseInt(this.userData.age);
      this.setAgeRange2(parseInt(this.userData.age) + 5, event.target.value);
      console.log('this.policyTerm: ', this.policyTerm)
      this.testApi();
    },
    getInsurer(event) {
      //console.log('Insurer selected: ', this.insurerCodes)
      if (event.target.checked) {
        console.log(event.target.value);
        this.insurerCodes.push(event.target.value);
      } else {
        console.log(event.target.value);
        var index = this.insurerCodes.indexOf(event.target.value);
        if (index !== -1) {
          this.insurerCodes.splice(index, 1);
        }
      }

      this.getPpf2();
      /*console.log('this.insurerCodes: ', this.insurerCodes);

      this.insurerCodesArray = [];
      for (var i = 0; i < this.insurerCodes.length; i++) {
        //Do something
        var tmp = {
          insuranceCompanyCode: this.insurerCodes[i],
          productCode: [],
        };
        this.insurerCodesArray.push(tmp);
      } */
      //console.log(this.insurerCodesArray);
      $("#exampleModalLong").modal('hide');
      //this.testApi();
    },
    getInsurerFilter(code) {
      if(this.insurerCodes.length > 0) {
        let index = this.insurerCodes.indexOf(code);
        if(index > -1) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    getFwr(event) {
      console.log(event.target.value);
      $("#exampleModalLong").modal('hide');
      this.DBPOptionSelected = event.target.value;
      this.testApi();
    },
    getPayPremium(event) {
      console.log(event.target.value);
      $("#exampleModalLong").modal('hide');
      if (event.target.value == 1) {
        this.premiumPaymentOption = event.target.value;
        this.premiumPaymentFrequency = "S";
        this.premiumPaymentTerm = 1;
        this.testApi();
      } else if(event.target.value == 3){
        this.premiumPaymentFrequency = "M";
        this.premiumPayTermBlock = true;
        this.premiumPaymentTerm2 = null;
      } else {
        this.premiumPayTermBlock = false;
        this.premiumPaymentFrequency = "M";
        this.premiumPaymentOption = event.target.value;
        this.premiumPaymentTerm = this.policyTerm;
        this.testApi();
      }
    },
    getPremiumPaymentTerm(event) {
      console.log(event.target.value);
      //this.premiumPaymentTerm = parseInt(event.target.value);
      this.premiumPaymentTerm2 = event.target.value;
      this.premiumPaymentTerm = parseInt(event.target.value) - parseInt(this.userData.age);
      this.testApi();
    },
    getCoverPlan(event) {
      $("#exampleModalLong").modal('hide');
      console.log(event.target.value);
      this.coverOption = event.target.value;
      this.testApi();
    },
    getRPremium(event) {
      console.log(event.target.checked);
      $("#exampleModalLong").modal('hide');
      if (event.target.checked == true) {
        this.planReturn = true;
        this.returnOfPremiumPercentage = parseInt(event.target.value);
      } else {
        this.planReturn = false;
        this.returnOfPremiumPercentage = 0;
      }
      this.testApi();
    },
    getCsValue(value) {

      var obj;
      if(value != null && value != 'null') {
        //console.log('get cs value called: ', value);
        eval('obj='+value);
        //console.log(obj);
        var keys = Object.keys(obj);
        //console.log(keys);
        let len = keys.length;
        return obj[keys[len-1]];
      } else {
        return "";
      }
      
    },
    showClaimPopup(value, insurerCode) {
      
      this.claimModalResult = [];
      this.popupInsurerImg = this.getInsurerImgs(insurerCode);
      var obj1;
      eval('obj1='+value);
      const entries = Object.entries(obj1);
      let len = entries.length;

      entries.forEach((element, index) => {
        let tempArr = entries[len - 1 - index];
        let new_text = tempArr[0].replace("_", " - ");
        let temp = {"year": new_text, "value": tempArr[1]};
        this.claimModalResult.push(temp);
      })
      

    },
    getInsurerImgs(value) {
      let img = getInsuranceImages();
      return require("../../assets/images/" + img[value]);
    },
    getOptSelectedText(value) {
     // let optSelected = getOPTSelected
      return getOPTSelected()[value];
    },
    updateAddOn(event, index, selInd) {
      //console.log('update add on called: ', event, index);
      if (event.target.checked == true) {
        this.addOnOptionSelected.push(event.target.value);
        // Adding flag for rider section
        this.responseData[index].productDetails.AddOnSection.addOnSelected[selInd].checked = true;
        this.updatePremiumValue(1, index, selInd);
      } else {
        // Removing flag for rider section
        this.responseData[index].productDetails.AddOnSection.addOnSelected[selInd].checked = false;
        var index2 = this.addOnOptionSelected.indexOf(event.target.value);
        if (index2 !== -1) {
          this.addOnOptionSelected.splice(index2, 1);
        }
        this.updatePremiumValue(0, index, selInd);
      }
      //console.log('updated value for rider section: ', this.responseData);
    },
    updatePremiumValue(flag, index, selInd) {
      //console.log("flag: ", flag, "ind: ", index, "sel ind: ", selInd);
     /*  let premiumValue =
        this.responseData[index].individualDetails[0].premiumDetails[0]
          .premiumValue;
      let additionalPremiumValue =
        this.responseData[index].productDetails.AddOnSection.addOnSelected[
          selInd
        ].premiumDetails[0].premiumValue; */

      let additionalPremiumValue =
        this.responseData[index].productDetails.AddOnSection.addOnSelected[
          selInd
        ].premiumDetails  
      
      //console.log('additionalPremiumValue: ', additionalPremiumValue);

      if (flag == 1) {

        additionalPremiumValue.forEach((element, ind2) => {
          let adMode = element.mode;
          let adAmount = element.totalPremiumValue;
          let itemIndex = this.responseData[index].individualDetails[0].premiumDetails.findIndex(item => item.mode == adMode);
          //console.log('item index: ', itemIndex);
          if(itemIndex > -1) {
            let tmpAmt = this.responseData[index].individualDetails[0].premiumDetails[itemIndex].totalPremiumValue;
            let updatedAmt = (tmpAmt + adAmount);
            
            this.responseData[index].individualDetails[0].premiumDetails[itemIndex].totalPremiumValue = updatedAmt
            //console.log('upated amt: ', updatedAmt, this.responseData[index].individualDetails[0].premiumDetails[itemIndex].totalPremiumValue)
          }
        })
      } else {
        additionalPremiumValue.forEach((element, ind2) => {
          let adMode = element.mode;
          let adAmount = element.totalPremiumValue;
          let itemIndex = this.responseData[index].individualDetails[0].premiumDetails.findIndex(item => item.mode == adMode);
          if(itemIndex > -1) {
            let tmpAmt = this.responseData[index].individualDetails[0].premiumDetails[itemIndex].totalPremiumValue;
            this.responseData[index].individualDetails[0].premiumDetails[itemIndex].totalPremiumValue = (tmpAmt - adAmount);
          }
        })
      }
    },
    sortByLowestPremium(num) {
      this.sortSelectedId = num;
      this.responseData.sort(
        (a, b) =>
          Number(a.individualDetails[0].premiumDetails[0].premiumValue) -
          Number(b.individualDetails[0].premiumDetails[0].premiumValue)
      );
      //console.log("ascending", this.responseData);
    },
    sortByHighestPremium(num) {
      this.sortSelectedId = num;
      this.responseData.sort(
        (a, b) =>
          Number(b.individualDetails[0].premiumDetails[0].premiumValue) -
          Number(a.individualDetails[0].premiumDetails[0].premiumValue)
      );
      //console.log("descending", this.responseData);
    },
    sortByMaxClaimSttled(num) {
      this.sortSelectedId = num;
      console.log(this.sortSelectedId);
      this.responseData.sort(
        (a, b) =>
          Number(this.getCsValue(b.supportingData.Messages.ClaimSettlementPercent)) -
          Number(this.getCsValue(a.supportingData.Messages.ClaimSettlementPercent))
      );
      //console.log("descending", this.responseData);
    },
    sortByHigherCover(num) {
      this.sortSelectedId = num;
      this.responseData.sort(
        (a, b) =>
          Number(b.individualDetails[0].sumAssured) -
          Number(a.individualDetails[0].sumAssured)
      );
    },
    getCompare(event) {
      if (event.target.checked === true) {
        console.log("if block eecuted", this.idsToCompare);

        var len = this.idsToCompare.length;
        if (len < 3) {
          
          this.idsToCompare.push(event.target.value);
        } else {
          event.target.checked = false;
          this.errorListSelect=""
          this.errorListSelect="You can select only 3 policies at once"
          window.scrollTo(0, 0);
          // alert("You can select only 3 policies at once");
        }
      } else {
        console.log("else block eecuted", this.idsToCompare);
        var index2 = this.idsToCompare.indexOf(event.target.value);
        if (index2 !== -1) {
          this.errorListSelect=""
          this.idsToCompare.splice(index2, 1);
        }
        console.log("else block eecuted", this.idsToCompare);
      }

      this.selectedForFilter = [];
      this.idsToCompare.forEach((element, index) => {
        const myArray = element.split("_");
        let temp;
        if(myArray[1] == 'P') {
          temp = this.responseData[myArray[0]];
        } else {
          temp = this.responseDataOutOfRange[myArray[0]];
        }
        this.selectedForFilter.push(temp);
      })
      localStorage.setItem('selectedForFilter',JSON.stringify(this.selectedForFilter));
      localStorage.setItem('idsToCompare',JSON.stringify(this.idsToCompare));
      console.log('selected for filter', this.selectedForFilter);
    },
    scrollToTop() {
      $('html, body').animate({ scrollTop: 0 }, 'fast');
    },
    removeFromFilter(ind) {

      console.log('removeFromFilter called: ', ind);
      console.log('this.idsToCompare called: ', this.idsToCompare);

      let val = this.idsToCompare[ind];
      const myArray = val.split("_");
      console.log('val lll: ', val);

      //this.responseData[val][]
      if(myArray[1] == 'P') {
        $('#plan_p'+myArray[0]).prop('checked', false); // Unchecks it
      } else {
        $('#plan_o'+myArray[0]).prop('checked', false); // Unchecks it
      }
      
      var index2 = this.idsToCompare.indexOf(val);
      if (index2 !== -1) {
        this.idsToCompare.splice(index2, 1);
      }
      this.selectedForFilter.splice(ind, 1);
      localStorage.setItem('selectedForFilter',JSON.stringify(this.selectedForFilter));
      localStorage.setItem('idsToCompare',JSON.stringify(this.idsToCompare))
    },
    goToPolicyDetails(data) {
      //console.log('selected plan: ', data);
      //localStorage.setItem('selectedPlan', JSON.stringify(data));
      this.beforeDestroy();
      sessionStorage.setItem("selected_plan_data", JSON.stringify(data));
      //this.$router.push('/policydetails');
      if(data.insuranceAndProducts.insuranceCompanyCode == 'TATA' || data.insuranceAndProducts.insuranceCompanyCode == 'HDFC') {
      //if(data.insuranceAndProducts.insuranceCompanyCode == 'TATA') {
        this.$router.push('/termplanqte');
      } else {
        this.$router.push('/policydetails');
      }
    },
    countAddOns(data, data2) {
      let len1 = data.length;
      let len2 = data2.length;
      let len = parseInt(len1) + parseInt(len2);
      /*if(len == 1 && data[0].optionSelected == null) {
        len = 0;
      }*/
      return len;
    },
    showAddOnBlock(val) {
      //collapseExample
      let ckk = $('#collapseExample' + val).hasClass('show');
      if(ckk == true) {
        $('#collapseExample' + val).removeClass('show')
      } else {
        $('#collapseExample' + val).addClass('show');
      }
    },
    getPremiumAmt(data) {
      let itemIndex = data.findIndex(item => item.mode == this.premiumPaymentFrequency);
      if(itemIndex > -1) {
        return this.currency(data[itemIndex]['totalPremiumValue']);
      } else {
        return null;
      }
    },
    getPremiumAmt2(data) {
      let itemIndex = data.findIndex(item => item.mode == this.premiumPaymentFrequency);
      if(itemIndex > -1) {
        return this.currency(data[itemIndex]['totalPremiumValue']);
      } else {
        return null;
      }
    },
    getPremiumAmt3(data) {
      let itemIndex = data.findIndex(item => item.mode == this.premiumPaymentFrequency);
      if(itemIndex > -1) {
        return this.currency(data[itemIndex]['totalRiderPremiumValue']);
      } else {
        return null;
      }
    },
    getPremiumAmt4(data) {
      let itemIndex = data.findIndex(item => item.mode == this.premiumPaymentFrequency);
      if(itemIndex > -1) {
        return this.currency(data[itemIndex]['totalPremiumValue']);
      } else {
        return this.currency(data[0]['totalPremiumValue']);
      }
    },
    currency(amt) {
      //return new Intl.NumberFormat().format(amt);
      let tmpAmt = parseFloat(amt).toFixed(2);
      return new Intl.NumberFormat().format(tmpAmt);
    },
    setAgeRange2(strtAge, EndAge) {
      this.ageRange2 = [];
      for (var i = strtAge; i <= EndAge; i++) {
        this.ageRange2.push(i);
      }
    },
    openEmailModal() {
      console.log('openEmailModal called');
      $("#emailModal").modal('show');
    },
    sendQuotesToCustomer() {
      console.log('sendQuotesToCustomer called');
      this.quoteSendingEmailsErrorMsg = '';
      var email = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
      if(this.quoteSendingEmails == '' || this.quoteSendingEmails == null) {
        this.quoteSendingEmailsErrorMsg = 'Please Enter Email First';
      } else if(!email.test(this.quoteSendingEmails)) {
        this.quoteSendingEmailsErrorMsg = 'Please Enter Valid Email';
      } else {
         $("#emailModal").modal('hide');
        // If email success
        var userInfo = {
          "name": this.userData.fullname,
          "gender": this.userData.gender,
          "dob": this.userData.dob,
          "occupation":this.userData.employmenttype,
          "pin":this.userData.zipcode,
          "smoker": this.userData.smoke
        };
      
        var quotesList = '';

        this.responseData.forEach(
          (element, index) => {

            if(this.getPremiumAmt(element.individualDetails[0].premiumDetails) != null) {
              let tmp = '<tr>'
                +'<td height="5px"></td>'
                +'<td height="5px"></td>'
                +'<td height="5px"></td>'
              +'</tr>'
              +'<tr>'
                +'<td style="color: #0000ff;">'+element.insuranceAndProducts.productName+'</td>'
                +'<td style="color: #0000ff;">'+element.individualDetails[0].sumAssured+'</td>'
                +'<td style="color: #0000ff;">'+this.getPremiumAmt(element.individualDetails[0].premiumDetails)+'</td>'
              +'</tr>';

              quotesList += tmp;
            }
            
          }
        );

        var text = '<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Strict//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-strict.dtd">'
          +'<html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">'
            +'<head>'
              +'<!--[if gte mso 9]>'
              +'<xml>'
              +'<o:OfficeDocumentSettings>'
                +'<o:AllowPNG/>'
                +'<o:PixelsPerInch>96</o:PixelsPerInch>'
              +'</o:OfficeDocumentSettings>'
              +'</xml>'
              +'<![endif]--><!--[if (mso)|(mso 16)]>'
              +'<style type="text/css">a{text-decoration:none;color:#0249A3}</style>'
              +'<![endif]-->'
              +'<meta http-equiv="Content-Type" content="text/html; charset=utf-8" />'
              +'<meta name="viewport" content="width=device-width, initial-scale=1.0" />'
              +'<title>Email</title>'
              +'<style type="text/css">'
              +'@media screen and (min-width: 750px){'
              +'body{font-family:Arial,Helvetica,sans-serif!important;font-weight:500}'
              +'body,table,thead,tbody,tr,td,img'
              +'{padding:0;margin:0;border:none;border-spacing:0px;border-collapse:collapse;vertical-align:top}'
              +'.wrapper{padding-left:10px;padding-right:10px;padding-top:10px;padding-bottom:10px}'
              +'h1,h2,h3,h4,h5,h6,p{margin:0;padding:0;padding-bottom:20px;line-height:1.6}'
              +'img{width:100%;display:block}'
              +'.main-table{width:710px;margin:0 auto;margin-top:5px}'
              +'.main-table tr td{font-size:14px!important;line-height:18px!important}'
              +'.main-table-td{padding:10px!important}'
              +'.main-table tr td.order-detl-mob{font-size:21px!important}'
              +'.brand-logo img{width:auto!important}'
              +'.main-table tr.totl-cost-td td{font-size:18px!important;line-height:18px!important}'
              +'table{border-spacing:0}'
              +'.order-details-table td{font-size: 9px!important;}'
              +'}'
              +'</style>'
            +'</head>'
            +'<body cellspacing="0" cellpadding="0" border="0" class="body" style="padding-top:5px !important;padding-bottom:5px !important; margin:0px !important; display:block !important; min-width:100% !important; width:100% !important; -webkit-text-size-adjust:none; background-color: #eceaea; " bgcolor="#eceaea">'
              +'<!--[if !mso]><!-->'
              +'<table align="center" style="background-color: #eceaea;margin-top: 5px;width: 100%;">'
              +'<tr>'
                +'<td>'
                  +'<table style="" class="main-table" align="center">'
                    +'<tr>'
                    +'<td style="background: #fff;" class="main-table-td">'
                      +'<table width="100%" style="background: #fff;border-collapse: collapse;" align="center" >'
                        +'<tr>'
                          +'<td>'
                          +'<table width="100%" style="border-collapse: collapse;">'
                            +'<tr>'
                              +'<td width="20%" class="mobile-td1" style="vertical-align: middle;">'
                                +'<table border="0">'
                                +'<tr>'
                                  +'<td class="brand-logo"> <img src="' + this.distributorLogo + '" alt="" style="width: 100%;"></td>'
                                +'</tr>'
                                +'</table>'
                              +'</td>'
                            +'</tr>'
                            +'<tr>'
                              +'<td height="10px" colspan="2"></td>'
                            +'</tr>'
                            +'<tr>'
                              +'<td height="10px" colspan="2"></td>'
                            +'</tr>'
                          +'</table>'
                          +'</td>'
                        +'</tr>'
                        +'<tr>'
                          +'<td>'
                          +'<table style="font-size: 9px;border-collapse: collapse;">'
                            +'<tr>'
                              +'<td>Hello '+userInfo['name']+',</td>'
                            +'</tr>'
                            +'<tr>'
                              +'<td height="10px"></td>'
                            +'</tr>'
                            +'<tr>'
                              +'<td>Thank you for your interest in our insurance product offering. Based on your enquiry, here are the'+' best plans selected for your consideration.</td>'
                            +'</tr>'
                          +'</table>'
                          +'</td>'
                        +'</tr>'
                        +'<tr>'
                          +'<td>'
                          +'<table width="100%" style="border-collapse: collapse;">'
                            +'<tr>'
                              +'<td height="15px"></td>'
                            +'</tr>'
                            +'<tr>'
                              +'<td class="order-detl-mob" style="font-weight: bold;font-size: 10px;">Profile</td>'
                            +'</tr>'
                          +'</table>'
                          +'</td>'
                        +'</tr>'
                        +'<tr>'
                          +'<td height="15px"></td>'
                        +'</tr>'
                        +'<tr>'
                          +'<td>'
                          +'<table width="100%" class="order-details-table" style="font-size: 9px;border-collapse: collapse;">'
                            +'<tr>'
                              +'<td>Name</td>'
                              +'<td>: '+userInfo['name']+'</td>'
                            +'</tr>'
                            +'<tr>'
                              +'<td height="5px"></td>'
                              +'<td height="5px"></td>'
                            +'</tr>'
                            +'<tr>'
                              +'<td>Gender</td>'
                              +'<td>: '+userInfo['gender']+'</td>'
                            +'</tr>'
                            +'<tr>'
                              +'<td height="4px"></td>'
                              +'<td height="4px"></td>'
                            +'</tr>'
                            +'<tr>'
                              +'<td>Date of Birth</td>'
                              +'<td>: '+userInfo['dob']+'</td>'
                            +'</tr>'
                            +'<tr>'
                              +'<td height="4px"></td>'
                              +'<td height="4px"></td>'
                            +'</tr>'
                            +'<tr>'
                              +'<td>Occupation</td>'
                              +'<td>: '+userInfo['occupation']+'</td>'
                            +'</tr>'
                            +'<tr>'
                              +'<td height="4px"></td>'
                              +'<td height="4px"></td>'
                            +'</tr>'
                            +'<tr>'
                              +'<td>Pin</td>'
                              +'<td>: '+userInfo['pin']+'</td>'
                            +'</tr>'
                            +'<tr>'
                              +'<td height="4px"></td>'
                              +'<td height="4px"></td>'
                            +'</tr>'
                            +'<tr>'
                              +'<td>Smoker</td>'
                              +'<td>: '+userInfo['smoker']+'</td>'
                            +'</tr>'
                            +'<tr>'
                              +'<td height="4px"></td>'
                              +'<td height="4px"></td>'
                            +'</tr>'
                          +'</table>'
                          +'</td>'
                        +'</tr>'
                        +'<tr>'
                          +'<td>'
                          +'<table style="border-collapse: collapse;">'
                            +'<tr>'
                              +'<td height="10px"></td>'
                            +'</tr>'
                          +'</table>'
                          +'</td>'
                        +'</tr>'
                        +'<tr>'
                          +'<td valign="top" style="border-bottom:1px solid #ccc;"></td>'
                        +'</tr>'
                        +'<tr>'
                          +'<td>'
                          +'<table width="100%" style="border-collapse: collapse;">'
                            +'<tr>'
                              +'<td height="4px"></td>'
                            +'</tr>'
                            +'<tr>'
                              +'<td height="4px"></td>'
                            +'</tr>'
                          +'</table>'
                          +'</td>'
                        +'</tr>'
                        +'<tr>'
                          +'<td>'
                          +'<table width="100%" style="font-size: 9px;border-collapse: collapse;">'
                            +'<thead>'
                              +'<tr>'
                                +'<td height="20px"></td>'
                                +'<td height="20px"></td>'
                                +'<td height="20px"></td>'
                              +'</tr>'
                              +'<tr>'
                                +'<td style="color: #0000ff;">Insurer & Plan Name</td>'
                                +'<td style="color: #0000ff;">Sum Insured</td>'
                                +'<td style="color: #0000ff;">Premium</td>'
                              +'</tr>'
                              + quotesList
                            +'</thead>'
                          +'</table>'
                          +'</td>'
                        +'</tr>'
                        +'<tr>'
                          +'<td>'
                          +'<table width="100%" style="border-collapse: collapse;">'
                            +'<tr>'
                              +'<td height="40px"></td>'
                            +'</tr>'
                            +'<tr>'
                              +'<td style="font-size: 9px;">For any assistance, please feel free to contact us at ' + this.contactNumber + ' or email us at <a href="javascript:void(0)">' + this.infoEmail +'</a></td>'
                            +'</tr>'
                            +'<tr>'
                              +'<td height="20px"></td>'
                            +'</tr>'
                            +'<tr>'
                              +'<td style="font-size: 9px;border-collapse: collapse;">*Insurance is the subject matter of solicitation. IRDAI Insurance Broking License No. 1. License Code No. IRDA/DB 1/22 Valid till 01/01/2023</td>'
                            +'</tr>'
                          +'</table>'
                          +'</td>'
                        +'</tr>'
                      +'</table>'
                    +'</td>'
                    +'</tr>'
                  +'</table>'
                +'</td>'
              +'</tr>'
              +'</table>'
              +'<!--<![endif]--><!--[if gte mso 14]>'
              +'<table style="" class="main-table" align="center">'
              +'<tr>'
                +'<td style="background: #fff;" class="main-table-td">'
                  +'<table width="100%" style="background: #fff;border-collapse: collapse;" align="center" >'
                    +'<tr>'
                    +'<td >'
                      +'<table width="100%" style="border-collapse: collapse;">'
                        +'<tr>'
                          +'<td width="20%" class="mobile-td1" style="vertical-align: middle;">'
                          +'<table border="0">'
                            +'<tr>'                              
                              +'<td class="brand-logo"> <img src="https://avidcircle-prod.s3.ap-south-1.amazonaws.com/logo.png" alt="" style="width: 100%;"></td>'
                            +'</tr>'
                          +'</table>'
                          +'</td>'
                        +'</tr>'
                        +'<tr>'
                          +'<td height="10px" colspan="2"></td>'
                        +'</tr>'
                        +'<tr>'
                          +'<td height="10px" colspan="2"></td>'
                        +'</tr>'
                      +'</table>'
                    +'</td>'
                    +'</tr>'
                    +'<tr>'
                    +'<td>'
                      +'<table style="font-size: 9px;border-collapse: collapse;">'
                        +'<tr>'
                          +'<td>Hello '+userInfo['name']+',</td>'
                        +'</tr>'
                        +'<tr>'
                          +'<td height="10px"></td>'
                        +'</tr>'
                        +'<tr>'
                          +'<td>Thank you for your interest in our insurance product offering. Based on your enquiry, here are the best plans selected for your consideration.</td>'
                        +'</tr>'
                      +'</table>'
                    +'</td>'
                    +'</tr>'
                    +'<tr>'
                    +'<td>'
                      +'<table width="100%" style="border-collapse: collapse;">'
                        +'<tr>'
                          +'<td height="15px"></td>'
                        +'</tr>'
                        +'<tr>'
                          +'<td class="order-detl-mob" style="font-weight: bold;font-size: 10px;">Profile</td>'
                        +'</tr>'
                      +'</table>'
                    +'</td>'
                    +'</tr>'
                    +'<tr>'
                    +'<td height="15px"></td>'
                    +'</tr>'
                    +'<tr>'
                    +'<td>'
                      +'<table width="100%" class="order-details-table" style="font-size: 9px;border-collapse: collapse;">'
                        +'<tr>'
                          +'<td>Name</td>'
                          +'<td>: '+userInfo['name']+'</td>'
                        +'</tr>'
                        +'<tr>'
                          +'<td height="5px"></td>'
                          +'<td height="5px"></td>'
                        +'</tr>'
                        +'<tr>'
                          +'<td>Gender</td>'
                          +'<td>: '+userInfo['gender']+'</td>'
                        +'</tr>'
                        +'<tr>'
                          +'<td height="4px"></td>'
                          +'<td height="4px"></td>'
                        +'</tr>'
                        +'<tr>'
                          +'<td>Date of Birth</td>'
                          +'<td>: '+userInfo['dob']+'</td>'
                        +'</tr>'
                        +'<tr>'
                          +'<td height="4px"></td>'
                          +'<td height="4px"></td>'
                        +'</tr>'
                        +'<tr>'
                          +'<td>Occupation</td>'
                          +'<td>: '+userInfo['occupation']+'</td>'
                        +'</tr>'
                        +'<tr>'
                          +'<td height="4px"></td>'
                          +'<td height="4px"></td>'
                        +'</tr>'
                        +'<tr>'
                          +'<td>Pin</td>'
                          +'<td>: '+userInfo['pin']+'</td>'
                        +'</tr>'
                        +'<tr>'
                          +'<td height="4px"></td>'
                          +'<td height="4px"></td>'
                        +'</tr>'
                        +'<tr>'
                          +'<td>Smoker</td>'
                          +'<td>: '+userInfo['smoker']+'</td>'
                        +'</tr>'
                        +'<tr>'
                          +'<td height="4px"></td>'
                          +'<td height="4px"></td>'
                        +'</tr>'
                      +'</table>'
                    +'</td>'
                    +'</tr>'
                    +'<tr>'
                    +'<td>'
                      +'<table style="border-collapse: collapse;">'
                        +'<tr>'
                          +'<td height="10px"></td>'
                        +'</tr>'
                      +'</table>'
                    +'</td>'
                    +'</tr>'
                    +'<tr>'
                    +'<td valign="top" style="border-bottom:1px solid #ccc;"></td>'
                    +'</tr>'
                    +'<tr>'
                    +'<td>'
                      +'<table width="100%" style="border-collapse: collapse;">'
                        +'<tr>'
                          +'<td height="4px"></td>'
                        +'</tr>'
                        +'<tr>'
                          +'<td height="4px"></td>'
                        +'</tr>'
                      +'</table>'
                    +'</td>'
                    +'</tr>'
                    +'<tr>'
                    +'<td>'
                      +'<table width="100%" style="font-size: 9px;border-collapse: collapse;">'
                        +'<thead>'
                          +'<tr>'
                          +'<td height="20px"></td>'
                          +'<td height="20px"></td>'
                          +'<td height="20px"></td>'
                          +'</tr>'
                          +'<tr>'
                          +'<td style="color: #0000ff;">Insurer & Plan Name</td>'
                          +'<td style="color: #0000ff;">Sum Insured</td>'
                          +'<td style="color: #0000ff;">Premium</td>'
                          +'</tr>'
                          + quotesList
                        +'</thead>'
                      +'</table>'
                    +'</td>'
                    +'</tr>'
                    +'<tr>'
                    +'<td>'
                      +'<table width="100%" style="border-collapse: collapse;">'
                        +'<tr>'
                          +'<td height="40px"></td>'
                        +'</tr>'
                        +'<tr>'
                          +'<td style="font-size: 9px;">For any assistance, please feel free to contact us at ' + this.contactNumber +' or email us at <a href="javascript:void(0)">' + this.infoEmail +'</a></td>'
                        +'</tr>'
                        +'<tr>'
                          +'<td height="20px"></td>'
                        +'</tr>'
                        +'<tr>'
                          +'<td style="font-size: 9px;border-collapse: collapse;">*Insurance is the subject matter of solicitation. IRDAI Insurance Broking License No. 1. License Code No. IRDA/DB 1/22 Valid till 01/01/2023</td>'
                        +'</tr>'
                      +'</table>'
                    +'</td>'
                    +'</tr>'
                  +'</table>'
                +'</td>'
              +'</tr>'
              +'</table>'
              +'<![endif]-->'
            +'</body>'
          +'</html>';


        //document.getElementById("").innerHTML  = text;

        let qData = {
          "send_from":"customer.service@1silverbullet.tech",
          "subject":"Quotes",
          "text":text,
          "send_to":[this.quoteSendingEmails]      
        };
        var headers = {
          Authorization: "Basic " + window.btoa(process.env.VUE_APP_USERNPASS),
          "Content-Type": "application/json",
        };
        this.backendApi("sendMail", qData, headers)
          .then((response) => {
            //console.log('save quote response: ', response);
            if(response == 'error') {
              self.apiError = true;
            } else if(response && response.data){
              
              console.log('email send success: ', response.data)
            }
          })
          .catch((error) => {
            console.log('error msg block email', error);
          });
        }

    },
    checkMandatoryRiderFlag(dt) {
      // if(dt.individualDetails[0]?.rider[2]?.ridercode == 'CPRTTI' && dt.individualDetails[0]?.rider[2]?.varFields[0]?.fieldName == 'mandatoryRiderFlag'  && dt.individualDetails[0]?.rider[2]?.varFields[0]?.fieldValue == 'Yes') {
      //   return true;
      // }
      return false;
    },
    getSumAssuredAmtRiderSA(dt) {
      let riders =  dt.individualDetails[0]?.rider;
      let itemIndex = riders.findIndex(item => item.ridercode == 'CPRTTI');
      let sumAssAmt = (dt.individualDetails[0].sumAssured - riders[itemIndex].riderSA);
      let riderSA = riders[itemIndex].riderSA;
      return this.convertInLakh(sumAssAmt) + ' + ' + this.convertInLakh(riderSA);
    },
    getBreakUpMessage(dt) {
      if(dt.productDetails?.varFields) {
        let values = dt.productDetails?.varFields;
        let itemIndex = values.findIndex(item => item.fieldName == 'message1');
        if(itemIndex > -1) {
          return values[itemIndex]['fieldValue'];
        }
      }
    },
    updateRider(event, index, selInd) {
      //console.log('update rider called: ', event, index);
      if (event.target.checked == true) {
        // Adding flag for rider section
        this.responseData[index].individualDetails[0].rider[selInd].checked = true;
        this.updatePremiumValue2(1, index, selInd);
      } else {
        // Removing flag for rider section
        this.responseData[index].individualDetails[0].rider[selInd].checked = false;
        this.updatePremiumValue2(0, index, selInd);
      }
    },
    updatePremiumValue2(flag, index, selInd) {
      //console.log("flag: ", flag, "ind: ", index, "sel ind: ", selInd);
     
      let additionalPremiumValue =
        this.responseData[index].individualDetails[0].rider[selInd].premiumDetails  
      
      console.log('additionalPremiumValue 4: ', additionalPremiumValue);

      if (flag == 1) {

        additionalPremiumValue.forEach((element, ind2) => {
          let adMode = element.mode;
          let adAmount = element.totalRiderPremiumValue;
          let itemIndex = this.responseData[index].individualDetails[0].premiumDetails.findIndex(item => item.mode == adMode);
          //console.log('item index: ', itemIndex);
          if(itemIndex > -1) {
            let tmpAmt = this.responseData[index].individualDetails[0].premiumDetails[itemIndex].totalPremiumValue;
            let updatedAmt = (tmpAmt + adAmount);
            
            this.responseData[index].individualDetails[0].premiumDetails[itemIndex].totalPremiumValue = updatedAmt
            //console.log('upated amt: ', updatedAmt, this.responseData[index].individualDetails[0].premiumDetails[itemIndex].totalPremiumValue)
          }
        })
      } else {
        additionalPremiumValue.forEach((element, ind2) => {
          let adMode = element.mode;
          let adAmount = element.totalRiderPremiumValue;
          let itemIndex = this.responseData[index].individualDetails[0].premiumDetails.findIndex(item => item.mode == adMode);
          if(itemIndex > -1) {
            let tmpAmt = this.responseData[index].individualDetails[0].premiumDetails[itemIndex].totalPremiumValue;
            this.responseData[index].individualDetails[0].premiumDetails[itemIndex].totalPremiumValue = (tmpAmt - adAmount);
          }
        })
      }
    },
    checkOutOfBondCondition(value, dataArray) {
      //console.log('checkOutOfBondCondition called: ', value, dataArray);
      let itemIndex =  dataArray.findIndex(item => item.OutOfRangeFieldName == value);
      if(itemIndex > -1) {
        return 'err-text';
      } else {
        //return 'err-text';
      }
    }

    
   
  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/scss/components/policy-qoute.scss";
#errorList{
  color:red;
  font-size: 17px;
}
</style>