<template>
    <div class="loading_block_outer" v-if="pageloading">
        <Loading3 msg='Please wait while we retrieve pending documents to upload.'/>
        <div class="snippet" data-title=".dot-spin">
            <div class="stage">
                <div class="dot-spin"></div>
            </div>
        </div>
        </div>
    <div v-else>
    <div class="main-bg" ref="formContainer">
        <!-- <b-overlay :show="show" rounded="sm"> -->
            <div class="container" >
                <!-- <div class="back-link">
                    <router-link to="">
                        <img src="~@/assets/images/back-arrow.png" alt="" />Back To Policy Review
                    </router-link>
                </div> -->
                <div class="doc-upld-section">
                    <h4>Upload Documents</h4>
                    <p>To confirm you are you, please upload digital photos of the following documents</p>
                    <ul>
                        <template v-for="(item, ind) in requirementJSON" :key="ind">
                            <li class="active" v-if="checkStatus(item)">
                                <div class="d-flex justify-content-between align-items-center">
                                    <div class="doc-name d-flex justify-content-between align-items-center">
                                        <h4> {{ item.docCategoryDescription }} </h4>
                                        <img src="~@/assets/images/info-button.png" alt="" class="ml-1">
                                    </div>
                                    <!-- <div class="doc-file dashed-border">
                                        <select name="" id="">
                                            <option value="">option 2</option>
                                            <option value="">option 3</option>
                                        </select>
                                    </div> -->
                                </div>
                                <div class="d-flex justify-content-between align-items-center mt-3 bordered-section">
                                    <div class="w-30 file-icon-sec">
                                        <img src="~@/assets/images/businessman.png" alt="">
                                    </div>
                                    <div class="doc-upload d-flex w-70">
                                        <!-- <button class="flex-fill">
                                            <img src="~@/assets/images/camera.png" alt="">Camera
                                        </button> -->
                                        <div class="doc-upload-select"> 
                                            <select :name="item.SBDocCategory" :id="item.SBDocCategory"  v-model="docIds[item.SBDocCategory]" @change="resetErrorMsg($event, item.SBDocCategory)">
                                                <option value="">Select</option>
                                                <option v-for="(item2, ind2) in item.allowedSBDocId" :value="item2.SBDocId" :key="ind2">{{ item2.docIdDescription }} </option>
                                            </select>
                                        </div> <!--doc-upload-select-->
                                        <div class="upload-input">
                                            <input type="file" class="custom-file-input" :id="'file_' + item.SBDocCategory"  @change="previewFiles($event, item.SBDocCategory)">
                                        </div>
                                        <button type="button" class="btn btn-primary eyeicon-btn" data-toggle="modal" :data-target="'#imageModal_'+ item.SBDocCategory">
                                            <img src="~@/assets/images/eye.png" alt="" />
                                        </button>
                                    </div>
                                    
                                </div>
                                <p class="error_msg" :id="'error_'+item.SBDocCategory"></p>
                            </li>
                            <!-- Modal -->
                            <div class="modal fade" :id="'imageModal_' + item.SBDocCategory" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                <div class="modal-dialog modal-lg" role="document">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <h5 class="modal-title" id="exampleModalLabel">{{ item.docCategoryDescription }}</h5>
                                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div class="modal-body">
                                            <!-- <img class="image1" :id="'image1_' + item.SBDocCategory" :src="imagesObj[item.SBDocCategory]"> -->
                                            <embed
                                                class="doc_upload_block image1"
                                                v-if="imagesObj[item.SBDocCategory]"
                                                type="video/webm"
                                                :src="imagesObj[item.SBDocCategory]"
                                            />
                                            <div v-else  class="error_msg text-center doc_upload_block2">No Document Selected</div>
                                        </div>
                                        <div class="modal-footer">
                                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- ./End Modal -->
                        </template>
                    </ul>
                    <div class="button wrap d-flex justify-content-center" >
                        <router-link to="/dashboard">
                            <button type="submit" class="next-btn mb-4" >Proceed 
                            <i>
                                <img src="~@/assets/images/next-arrow.png" alt="">
                            </i>
                            </button>
                        </router-link>
                    </div>
                </div>
                
            </div>
        <!-- </b-overlay> -->
    </div>    
    </div>
</template>

<script>
import $ from "jquery";
import Uploading from "@/components/uploading.vue";
// Import the method.
import { useLoading } from 'vue3-loading-overlay';
// Import stylesheet
import 'vue3-loading-overlay/dist/vue3-loading-overlay.css';
import Loading3 from "@/components/loading3.vue";
export default {
    name:' uploaddoc',
    components: {
        Uploading,
        Loading3
    },
    mounted() {
        this.imagesObj = {};
        this.distributorID = this.$route.query.distributorID;
        this.insuranceCompanyCode = this.$route.query.insuranceCompanyCode;
        this.applicationNo = this.$route.query.applicationNo;

        console.log('this.distributorID: ', this.distributorID, 'this.insuranceCompanyCode: ', this.insuranceCompanyCode, 'this.applicationNo: ', this.applicationNo)

        this.testRequirementJSON = [
            {
                "status":"Pending",
                "manufacturerStatus":"",
                "SBDocCategory":"addrProof",
                "docCategoryDescription":"Address Proof",
                "allowedSBDocId":[
                {
                    "SBDocId":"addrProofPartnrFirm",
                    "docIdDescription":"Address proof of the Partnership Firm"
                },
                {
                    "SBDocId":"tcsBonafideLetter",
                    "docIdDescription":"TCS Bonafide Letter (Proof of Address)"
                }
                ],
                "description":"",
                "requestDate":"",
                "examDate":"",
                "receivedDate":"",
                "clientId":"",
                "firstName":"",
                "lastName":"",
                "manuRequirementNo":"",
                "varFields":[
                    {
                        "fieldName":"",
                        "fieldValue":""
                    }
                ]
            },
            {
                "status":"Pending",
                "manufacturerStatus":"",
                "SBDocCategory":"ageProof",
                "docCategoryDescription":"Age Proof",
                "allowedSBDocId":[
                {
                    "SBDocId":"aadhar",
                    "docIdDescription":"Aadhar ID Card - Non Standard"
                },
                {
                    "SBDocId":"baptismCert",
                    "docIdDescription":"Baptism Certificate"
                },
                {
                    "SBDocId":"defIdCard",
                    "docIdDescription":"Defense ID Card"
                },
                {
                    "SBDocId":"domicileCert",
                    "docIdDescription":"Domicile Certificate"
                }
                ],
                "description":"",
                "requestDate":"",
                "examDate":"",
                "receivedDate":"",
                "clientId":"",
                "firstName":"",
                "lastName":"",
                "manuRequirementNo":"",
                "varFields":[
                    {
                        "fieldName":"",
                        "fieldValue":""
                    }
                ]
            }
        ];

        // this.requirementJSON = this.testRequirementJSON;

        this.getDocRequirement(this.distributorID, this.insuranceCompanyCode, this.applicationNo);
    },
    props: {
        msg: String,
    },
    data() {
        return {
            loading: false,
            pageloading: false,
            requirementJSON: "",
            testRequirementJSON: "",
            distributorID: this.distributorID,
            insuranceCompanyCode: "",
            applicationNo: "",
            salesChannel: "Others",
            docIds: [],
            imagesObj: {},
            //loader: useLoading(),
            docCategoryDone:[],

        }
    },
    methods: {
        checkStatus(item)
        {
            let status = this.docCategoryDone.includes(item.SBDocCategory);
            return !status; 
        },
        previewFiles(event, sbDocCat) {
            let file = event.target.files[0];
            console.log('get file called: ', event.target.files[0]);

            console.log('sbDocCat: ', sbDocCat);

            console.log('docIds: ', this.docIds);
            if(!this.docIds[sbDocCat]){
                $("#error_" + sbDocCat).text('Please Select Doc Type First Then Select File');
                $('#file_' + sbDocCat).val('');
            } else {

                let fileType = file.type;
                console.log('file type: ', fileType);
                let extension = file.name.split('.').pop();
                console.log('extension: ', extension);

                const reader = new FileReader();
                let srcUrl = URL.createObjectURL(file);
                this.imagesObj[sbDocCat] = srcUrl;
                
                console.log('srcUrl: ', srcUrl);

                this.getBase64(file).then(
                    data => {  
                        this.uploadDoc(fileType, data, extension, sbDocCat, this.docIds[sbDocCat]);
                    } 
                )

            }
            
            

        },
        getBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = error => reject(error);
            });
        },
        uploadDoc(fileType, base64String, extension, sbDocCat, sbDocId) {

            let filledData;
            if (sessionStorage.getItem('filledData')) {
                filledData = JSON.parse(sessionStorage.getItem("filledData"));
            }
            var base64result = base64String.split(',')[1];
            let qData = 
            {
                "data":
                {
                    "distributor": {
                        "distributorID": this.distributorID,
                        "agentID": "8925",
                        "salesChannel": this.salesChannel,
                        "varFields": []
                    },
                    "insuranceCompanyCode": this.insuranceCompanyCode,
                    "applicationNo": this.applicationNo,
                    "policyNo": filledData.fieldGroups.traceInfo.fields.manuPolicyNo.input,
                    "quoteId": "",
                    "individualDetails": [
                        {
                            "memberType": "Life Assured",
                            "memberSequenceNumber": 1,
                            "relationWithFirstLifeAssured": "Self",
                            "fileDetails": [
                                {
                                    "SBDocCategory": sbDocCat,
                                    "SBDocId": sbDocId,
                                    //"SBDocCategory": sbDocCat,
                                    //"SBDocId": sbDocId,
                                    "documentId": "",
                                    "documentExpiryDate": "",
                                    "byteArray": base64result,
                                    "docFormat": extension,
                                    "manuRequirementNo": "",
                                    "varFields": [
                                        {
                                            "fieldName": "",
                                            "fieldValue": ""
                                        }
                                    ]
                                }
                            ]
                        }
                    ],
                    "varFields": [
                        {
                            "fieldName": "",
                            "fieldValue": ""
                        }
                    ]
                }
            };

            var  headers = {
                Authorization: "Basic " + window.btoa(process.env.VUE_APP_USERNPASS),
                "Content-Type": "application/json",
            };
            
            //this.loading = true;
            this.show = true;
            // this.loader.show({
            //     canCancel: false,
            // });
            this.backendApi("uploadDoc", qData, headers)
                .then((response) => {
                    //console.log('save quote response: ', response);
                    //this.loading = false;
                    //this.loader.hide();
                    if(response == 'error') {
                        self.apiError = true;
                    } else if(response.data.errors.length > 0) {
                        // let msg = '';
                        // response.data.errors.forEach((element, index) => {
                        // msg += element.errorMessage + '\n';
                        // });
                        let msg=[]
                        for(let i=0;i<response.data.errors.length;i++)
                        {
                            msg.push(response.data.errors[i]["errorMessage"])
                        }
                        
                        this.$router.push({ path: '/alert', query: {backUrl:'policy-quote', msg:msg  }});
                        // alert(msg);
                    } else if(response && response.data){
                        console.log('inside suuuuuu')
                        if(response.data.data.individualDetails[0]?.fileDetails[0]?.fileUploadStatus == 'Success') {
                            // alert('File Uploaded Successfully');
                            this.uploadDocumentStatus(this.applicationNo,sbDocCat, "Success");
                        }
                    
                    } 
                })
                .catch((error) => {
                    //this.loading = false;
                    //this.loader.hide()
                    console.log('error msg block doc uploading', error);
                });
        },
        resetErrorMsg(event, sbDocCat) {
            let val = event.target.value;
            console.log('event: ', event.target.value);
            console.log('sbDocCat: ', sbDocCat);

            if(val) {
                $("#error_"+sbDocCat).text('');
            }
        },
        getUploadRequiremnts(appnum,manufacturer,policyNumber){
            // console.log(requirement);
            let  headers = {};
            let qData = [appnum];

            this.backendApi("getUploadDocRequiremnts", qData, headers,"get")
                .then((response) => {
                    console.log(response);
                    this.docCategoryDone =[];
                    for(let i=0;i<response.data.length;i++)
                    {
                        this.docCategoryDone.push(response.data[i].category);
                    }
                    console.log(this.docCategoryDone);
                    for(let i=0;i<this.requirementJSON.length;i++)
                            {
                                this.uploadRequiremnts(this.requirementJSON[i], 
                                appnum,
                                manufacturer,
                                policyNumber);
                            }
                    this.pageloading = false;
                })
                .catch((error) => {
                    this.pageloading = false;
                    // this.show = false;
                    console.log('error msg block doc getUploadDocRequiremnts', error);
                });

        },
        uploadRequiremnts(requirement,appnum,manufacturer,policyno){
            console.log(requirement);
            let  headers = {};
            let qData = {
                "proposal_ui_id":"12345",
                "applicationNumber": appnum,
                "manufacturer":manufacturer,
                "insuranceCompanyCode":"",
                "productCode":"",
                "policyNumber":"",
                "insurance_company_status":"Pending",
                "manufacturerStatus":"Pending",
                "category":requirement.SBDocCategory,
                "name": requirement.name,
                "description": requirement.docCategoryDescription,
                "request_date":requirement.requestDate,
                "exam_date":requirement.examDate,
                "received_date": requirement.receivedDate,
                "clientId": requirement.clientId,
                "firstName":requirement.firstName,
                "lastName":requirement.lastName
                
            };

            this.backendApi("uploadDocRequiremnts", qData, headers)
                .then((response) => {
                    console.log(response);
                })
                .catch((error) => {
                    this.pageloading = false;
                    this.show = false;
                    console.log('error msg block doc requirement', error);
                });

        },
        uploadDocumentStatus(applicationNumber,category,status){
            
            let  headers = {};
            let qData = {
                "applicationNumber":applicationNumber,
                "category":category,
                "status":status};

            this.backendApi("uploadDocStatus", qData, headers)
                .then((response) => {
                    console.log(response);
                })
                .catch((error) => {
                    this.show = false;
                    console.log('error msg block doc requirement', error);
                });

        },
        getDocRequirement() {
            this.pageloading = true;
            let  headers = {};
            let qData = 
            {
                "data":
                {
                    "distributorID": this.distributorID,
                    "insuranceCompanyCode": this.insuranceCompanyCode,
                    "applicationNo": this.applicationNo
                }
            };
            //this.loading = true;
            this.show = true;
            this.backendApi("getDocRequirements", qData, headers)
                .then((response) => {
                    //console.log('save quote response: ', response);
                    //this.loading = false;
                    this.show = false;
                    if(response == 'error') {
                        console.log('error block for get requirement');
                    } else if(response && response.data) {
                        
                        if(response.data.data.manufacturer) {
                            this.requirementJSON = response.data.data.manufacturer.product[0].requirements;
                            this.getUploadRequiremnts(response.data.data.applicationNumber,
                            response.data.data.manufacturer.insuranceCompanyCode,
                            response.data.data.manufacturer.product[0].policyNo);
                            // for(let i=0;i<this.requirementJSON.length;i++)
                            // {
                            //     this.uploadRequiremnts(this.requirementJSON[i], 
                            //     response.data.data.applicationNumber,
                            //     response.data.data.manufacturer.insuranceCompanyCode,
                            //     response.data.data.manufacturer.product[0].policyNo);
                            // }
                            
                        } else {
                            this.requirementJSON = this.testRequirementJSON;
                        }
                        console.log('requirement response: ', response.data);
                    
                    }
                })
                .catch((error) => {
                    this.loading = false;
                    this.show = false;
                    console.log('error msg block doc requirement', error);
                });
        }
    }
}
</script>


<style scoped lang="scss">
@import '~@/assets/scss/components/uploaddoc.scss';
</style>