<template>
  <div class="landing-page-container">
    <!-- <headerComp /> -->
    <innerheader @clicked-rm-details="captureRmData"/>
    <div class="main-content">
      
      <div class="mobile-content">
        <span>Online Discount upto 5% off</span>
        <div class="mobile-heading-text">
          <h1>
            1 Crore life life cover at
            <span class="amt-per-mnth">&#8377;450/<i>month*</i></span>
          </h1>
          <span>Get insured from the comfort of your home</span>
        </div>
      </div>
      <div class="left-content">
        <div class="position-relative">
          <img src="~@/assets/images/family.png" alt="" />
          <div class="discount-badge">
            <img src="~@/assets/images/yellow-badge.png" alt="" />
            <div class="discount-badge-inner">
              <i
                >online <br />
                discount</i
              >
              <b>upto</b>
              <div class="discount-badge-amount">
                <strong>5</strong>
                <div class="discount-text-inner">
                  <span>%</span>
                  <span class="off-text">off</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="right-content">
        <div class="right-content-inner">
          <div class="text-center">
            <div class="text-light bg-danger mb-3" v-if="!rmData">RM data not available, please login first!</div>
            <h1>
              1 Crore life cover at
              <span class="amt-per-mnth">&#8377;450/<i>month*</i></span>
            </h1>
            <span>Get insured from the comfort of your home</span>
          </div>
         
          <div class="stepper"  v-show="isHidden">
            <ul>
              <li class="active-stepper">1</li>
              <!-- completed step html/css is here -->
              <!-- <li class="completed"><img src="~@/assets/images/checked.png"></li> -->
              <li>2</li>
            </ul>
          </div>
          <div class="stepper" v-show="!isHidden" >
            <ul>
              <li>1</li>
              <li class="active-stepper">2</li>
            </ul>
          </div>
          <form action="" novalidate>
            <!-- step 1 starts here -->
            <div class="step-1" v-show="isHidden">
              <div class="d-flex radio-btn-wrap">
                <div class="mr-15">
                  <input
                    class="checkbox-tools"
                    type="radio"
                    name="tools"
                    id="male"
                    v-model="userData.gender"
                    v-bind:value="'Male'"
                  />
                  <label class="for-checkbox-gender d-flex justify-content-start align-items-center" for="male">
                    <i class=""
                      ><img src="~@/assets/images/male.svg" alt=""
                    /></i>
                    <i class="inactive"
                      ><img src="~@/assets/images/male.svg" alt=""
                    /></i>
                    <span>  Male</span>
                  </label>
                </div>
                <!--apply active class here for active state img-->
                <div class="mr-15">
                  <input
                    class="checkbox-tools"
                    type="radio"
                    name="tools"
                    id="female"
                    v-model="userData.gender"
                    v-bind:value="'Female'"
                  />
                  <label class="for-checkbox-gender d-flex justify-content-start align-items-center" for="female">
                    <i class=""
                      ><img src="~@/assets/images/female.svg" alt=""
                    /></i>
                    <!-- <i class="inactive"
                      ><img src="~@/assets/images/female-inactive.svg" alt=""
                    /></i> -->
                    <span>  Female</span>
                  </label>
                </div>
                <div class="mr-0">
                  <input
                    class="checkbox-tools"
                    type="radio"
                    name="tools"
                    id="transgender"
                    v-model="userData.gender"
                    v-bind:value="'Other'"
                  />
                  <label class="for-checkbox-gender d-flex justify-content-start align-items-center" for="transgender">
                    <i class=""
                      ><img src="~@/assets/images/transgender.svg" alt=""
                    /></i>
                    <!-- <i class="inactive"
                      ><img
                        src="~@/assets/images/transgender-inactive.svg"
                        alt=""
                    /></i> -->
                   <span> Others</span>
                  </label>
                </div>
                  <p class="error-text" v-if="error.gender">
                    {{ error.gender }}
                  </p>
              </div>
              

              <div class="form-control1 label-clicked">
                <input
                  class="form-text"
                  type="text"
                  id="fullname"
                  name="fullname"
                  pattern="[A-Za-z]"
                  v-model.trim="userData.fullname"
                  @keypress="lettersSpaceOnly($event)"
                />
                <label for="fullname" class="form-item-label">Full Name</label>
                <p class="error-text" v-if="error.fullname">
                  {{ error.fullname }}
                </p>
              </div>
              <div class="d-flex form-control1-wrap">
                <div class="form-control1 label-clicked">
                  <input
                    class="form-text"
                    type="date"
                    id="dob"
                    name="dob"
                    v-on:input="calAge"
                    v-model="userData.dob"
                    @keypress="validateDate($event)"
                  />
                  <label for="dob" class="form-item-label">Date Of Birth</label>
                  <span v-if="userData.age" class="blue-bg-span"
                    >{{ userData.age }} Years</span
                  >
                  <p class="error-text" v-if="error.dob">{{ error.dob }}</p>
                </div>
                <div class="form-control1 label-clicked">
                  <select name="country-code" id="country-code" disabled>
                    <option value="+91">+91</option>
                  </select>
                  <span></span>
                  <input
                    class="form-text"
                    type="text"
                    id="mob-no"
                    name="mob-no"
                    maxlength="10"
                    v-model.trim="userData.mobile"
                    @keypress="isNumber($event)"
                  />
                  <label for="mob-no" class="form-item-label"
                    >Mobile Number</label
                  >
                  <p class="error-text" v-if="error.mobile">
                    {{ error.mobile }}
                  </p>
                </div>
              </div>
              <div class="form-control1 zip-code-input label-clicked">
                <input
                  class="form-text"
                  type="text"
                  id="zip-code"
                  name="zip-code"
                  pattern="\d*"
                  maxlength="6"
                  v-model.trim="userData.zipcode"
                  @keypress="isNumber($event)"
                />
                <label for="zip-code" class="form-item-label">Pin Code</label>
                <!--<span class="blue-bg-span">Mumbai</span>-->
                <p class="error-text" v-if="error.zipcode">
                  {{ error.zipcode }}
                </p>
              </div>
              <!-- <div v-if="userData2">
                {{ userData2 }}
              </div> -->
              <!-- <div class="text-center"> -->
              <div class="text-center" v-if="rmData">
                <button type="button" class="next-btn" v-on:click="getData1">
                  Next <i><img src="~@/assets/images/next-arrow.png" /></i>
                </button>
              </div>
            </div>
            <!-- step 1 ends here -->
            <!-- step 2 starts here -->
            <div class="step-2" id="step2" v-show="!isHidden">
              <div class="form-control1 label-clicked">
                <input
                  class="form-text"
                  type="text"
                  id="income"
                  name="income"
                  v-on:input="getInLakhs"
                  v-model.trim="userData.annualincome"
                  @keypress="isNumber($event)"
                />
                <label for="income" class="form-item-label">
                  Annual Income
                </label >
                <p class="error-text" v-if="error.annualincome">
                  {{ error.annualincome }}
                </p>
                <span class="blue-bg-span" v-if="userData.inlakhs">&#8377; {{userData.inlakhs}} </span>
              </div>
              <div class="d-flex form-control1-wrap">
                <div class="form-control1 label-clicked">
                  <select
                    name="employee"
                    id="employee"
                    v-model="userData.employmenttype"
                  >
                    <option value="Salaried">Salaried</option>
                    <option value="Unemployed">Unemployed</option>
                    <option value="Self-employed">Self-Employed</option>
                  </select>
                  <label for="employee" class="form-item-label"
                    >Employment Type</label
                  >
                  <p class="error-text" v-if="error.employmenttype">
                    {{ error.employmenttype }}
                  </p>
                </div>
                <div class="form-control1 label-clicked">
                  <select
                    name="course"
                    id="course"
                    v-model="userData.education"
                  >
                    <option value="graduate">College graduate & above</option>
                    <option value="12">12th Pass</option>
                    <option value="10">10th Pass & below</option>
                  </select>
                  <label for="mob-no" class="form-item-label">Education</label>
                  <p class="error-text" v-if="error.education">
                    {{ error.education }}
                  </p>
                </div>
              </div>
              <div class="radio-question">
                <h4>Do You Smoke Or Chew Tobacco?</h4>
                <div>
                  <div class="mr-15">
                    <input
                      class="checkbox-tools"
                      type="radio"
                      name="smoke"
                      id="yes"
                      v-model="userData.smoke"
                      v-bind:value="'Yes'"
                    />
                    <label class="for-checkbox-gender" for="yes">
                      <i class="">
                        <!-- <img src="~@/assets/images/smoking.png" alt=""/> -->
                        <img src="~@/assets/images/smoking.svg" alt="" />
                      </i>
                      Yes
                    </label>
                  </div>
                  <div class="mr-15">
                    <input
                      class="checkbox-tools"
                      type="radio"
                      name="smoke"
                      id="no"
                      v-model="userData.smoke"
                      v-bind:value="'No'"
                    />
                    <label class="for-checkbox-gender" for="no">
                      <i class="">
                        <img src="~@/assets/images/no-smoking.png" alt=""/>
                      </i>
                      No
                    </label>
                  </div>
                  <p class="error-text" v-if="error.smoke">
                  {{ error.smoke }}
                  </p>
                </div>
             </div>
              <!-- <div v-if="userData3">
                {{ userData3 }}
              </div> -->
              <div class="text-center">
                <button type="button" class="back-btn" v-on:click="getback">
                  <i><img src="~@/assets/images/back-btn.png" /></i>Back
                </button>
                <!-- <button type="submit" class="next-btn">
                  Get Quote <i><img src="~@/assets/images/next-arrow.png" /></i>
                </button> -->
                <button type="button" v-on:click="cpatureData" class="next-btn">
                  Get Quote <i><img src="~@/assets/images/next-arrow.png" /></i>
                </button>

              </div>

              <div class="get-updates">
                <div class="get-updates-text">
                  <img src="~@/assets/images/whatsapp.png" />
                  Get updates on WhatsApp
                </div>
                <label class="toggleSwitch nolabel" onclick="">
                  <input type="checkbox" checked v-model="userData.whatsapp" />
                  <span> </span>
                  <a></a>
                </label>
              </div>
            </div>
          </form>
        </div>
        <footerComp />
      </div>
    </div>
  </div>

  <div class="version-dtls">
    <span>Version 1.1.48.0</span> 
  </div>
</template>
<script>

import innerheader from "@/components/innerheader/innerheader.vue";
import footerComp from "@/components/footer/footer-comp.vue";
//import Multiselect from 'vue-multiselect'
import Multiselect from '@vueform/multiselect'
// register globally
export default {
  name: "index",
  props: {
    msg: String,
  },
  components: {
    footerComp,
    //headerComp,
    innerheader,
    Multiselect 
  },
  mounted() {
    // Removing Flags
    sessionStorage.removeItem('quote_request_flag');
    sessionStorage.removeItem('quote_request_checkout_flag');
    sessionStorage.removeItem('gateCriteriaData');

    if (localStorage.getItem("userProfile")){
      this.userData = JSON.parse(localStorage.getItem("userProfile"))
    }

    if (sessionStorage.getItem("rm_data")) {
      this.rmData = JSON.parse(sessionStorage.getItem("rm_data"))
      //console.log('rm data found');
    } else {
      //console.log('rm data not found')
    }

    if (sessionStorage.getItem("quote_request")){
      sessionStorage.removeItem("quote_request");
    }
    this.getInLakhs();
  },
  data() {
    return {
      isHidden: true,
      error: [],
      userData2: null,
      userData: {
        gender: null,
        fullname: null,
        dob: null,
        age: null,
        mobile: null,
        zipcode: null,
        annualincome: null,
        inlakhs: null,
        employmenttype: null,
        education: null,
        smoke: null,
        whatsapp: false,
      },
      rmData: "",
      terms: "",
      selected: [],
      value: "",
      //options: ['Laravel', 'Laravel 8', 'Vue JS', 'codeplaners.com']
      options: [
        { name: "Vue.js", language: "JavaScript" },
        { name: "Adonis", language: "JavaScript" },
        { name: "Rails", language: "Ruby" },
        { name: "Sinatra", language: "Ruby" },
        { name: "Laravel", language: "PHP" },
        { name: "Phoenix", language: "Elixir" }
      ],
    };
  },
  methods: {
    // Validation Rules
    // Numbers only
    isNumber: function(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      console.log(charCode);
      //if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode == 46) {
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    toPaymentPage(){
      // alert(document.getElementById('MyId').value);
      window.location.href = document.getElementById('MyId').value;
    },
    lettersOnly(evt) {
      evt = (evt) ? evt : event;
      var charCode = (evt.charCode) ? evt.charCode : ((evt.keyCode) ? evt.keyCode : ((evt.which) ? evt.which : 0));
      if (charCode > 31 && (charCode < 65 || charCode > 90) && (charCode < 97 || charCode > 122)) {
        evt.preventDefault();
      }
      return true;
    },
    lettersSpaceOnly(evt) {
      evt = (evt) ? evt : event;
      var charCode = (evt.charCode) ? evt.charCode : ((evt.keyCode) ? evt.keyCode : ((evt.which) ? evt.which : 0));
      if (charCode > 32 && (charCode < 65 || charCode > 90) && (charCode < 97 || charCode > 122)) {
        evt.preventDefault();
      }
      return true;
    },
    
    getData1() {
      
      if (
        this.userData.gender &&
        this.userData.fullname.trim().length > 0 &&
        this.userData.dob &&
        this.userData.zipcode &&
        this.userData.mobile
      ) {
        if(this.userData.fullname.trim().length > 1) {
            if(this.getAge(this.userData.dob) > 0){
            this.error = [];
            if(this.userData.mobile.toString().length == '10'  && this.checkSameString(this.userData.mobile.toString()) == false) {
              this.error = [];
              if(this.userData.zipcode.toString().length == '6' && this.checkSameString(this.userData.zipcode.toString()) == false) {
                this.error = [];
                this.userData.age = this.getAge(this.userData.dob);
                this.isHidden = false;
                console.log("data one called");
                this.userData2 = this.userData;
                console.log(this.userData);
              } else {
                this.error["zipcode"] = "Please Enter Valid Pincode";
              }
            } else {
              this.error["mobile"] = "Please Enter Valid Mobile Number";
            }
          } else {
            this.error["dob"] = "Please Enter Valid Date of Birth";
          }
        } else {
          this.error["fullname"] = "Please Enter Valid Name";
        }
        
        
      } else {
        this.error = [];
        if (!this.userData.gender) {
          this.error["gender"] = "Please Select Your Gender";
        }
        if (!this.userData.fullname.trim().length) {
          this.error["fullname"] = "Please Enter Your Full Name";
        }
        if (!this.userData.dob) {
          this.error["dob"] = "Please Enter Your Date of Birth";
        }
        if (!this.userData.mobile) {
          this.error["mobile"] = "Please Enter Your Mobile Number";
        }
        if (!this.userData.zipcode) {
          this.error["zipcode"] = "Please Enter Pincode";
        }
        console.log(this.error);
      }
    },

    cpatureData() {
      if (
        this.userData.annualincome &&
        this.userData.employmenttype &&
        this.userData.education &&
        this.userData.smoke
      ) {
        if(this.validateAnnualIncome() == true) {
          this.error = [];

          //console.log("capture data function called");
          //let userData3 = JSON.stringify(this.userData);
          console.log('collected user data: ', this.userData);
          localStorage.setItem('userProfile',JSON.stringify(this.userData));
          sessionStorage.setItem('user_profile',JSON.stringify(this.userData));
          //this.$router.push({path: '/policy-quote', query:{ gender: this.userData.gender, fullname: this.userData.fullname, dob: this.userData.dob, age: this.userData.age, mobile: this.userData.mobile, zipcode: this.userData.zipcode, annualincome: this.userData.annualincome, inlakhs: this.userData.inlakhs, employmenttype: this.userData.employmenttype, education: this.userData.education, smoke: this.userData.smoke, whatsapp: this.userData.whatsapp}})
          //this.$router.push({path: '/policy-quote', query:{ gender: this.userData.gender, fullname: this.userData.fullname, dob: this.userData.dob, age: this.userData.age, mobile: this.userData.mobile, zipcode: this.userData.zipcode, annualincome: this.userData.annualincome, inlakhs: this.userData.inlakhs, employmenttype: this.userData.employmenttype, education: this.userData.education, smoke: this.userData.smoke, whatsapp: this.userData.whatsapp}})
          this.$router.push('/policy-quote');
          
        }
        
      } else {
        this.error = [];
        if (!this.userData.annualincome) {
          this.error["annualincome"] = "Please Enter Your Annual Income";
        }
        if (!this.userData.employmenttype) {
          this.error["employmenttype"] = "Please Select Your Employment Type";
        }
        if (!this.userData.education) {
          this.error["education"] = "Please Select Your Educational Qualification";
        }
        if (!this.userData.smoke) {
          this.error["smoke"] = "This field is required";
        }
      }
    },

    getback() {
      console.log("get back called");
      this.isHidden = true;
    },

    getAge(dateString) {
      var today = new Date();
      var birthDate = new Date(dateString);
      var age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      if (age < 99) {
        return age;
      }
    },

    calAge() {
      this.userData.age = this.getAge(this.userData.dob);
      this.error["dob"] = null;
    },

    getInLakhs() {
      let anuInc =  this.userData.annualincome / 100000;
      if(anuInc < 100) {
        this.userData.inlakhs = (this.userData.annualincome / 100000) + ' Lacs';
      } else {
        this.userData.inlakhs = (this.userData.annualincome / 10000000) + ' Cr.';
      }
    },
    captureRmData(val) {
      //console.log('capture rm data called', val)
      this.rmData = val;
    },
    validateAnnualIncome() {

      console.log('validateAnnualIncome called');
      if(this.userData.annualincome == 0 && this.userData.employmenttype == 'unemployed') {
        return true;
      } else if(this.userData.annualincome > 0 && this.userData.annualincome <= 50000000) {
        return true;
      } else if(this.userData.annualincome > 0 && this.userData.annualincome > 50000000) {
        this.error["annualincome"] = "Annual Income Must be Upto 5 Cr.";
        return false;
      } else if(this.userData.annualincome == 0 && this.userData.employmenttype != 'unemployed') {
        this.error["annualincome"] = "Please Enter Your Annual Income";
        return false;
      } else {
        return false;
      }
    },
    checkSameString(data) {
      //console.log('check same string called');
      let firstChar = data[0];
      let len = data.length;
      let flag = true;
      for(let i = 1; i < len; i++) {
        if(firstChar != data[i]) {
          flag = false;
          break;
        }
      }
      return flag;
    },
    validateDate(event) {
      let val = event.target.value;
      console.log('val: ', val)
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import "~@/assets/scss/components/index.scss";
@import "~@/assets/scss/fonts.scss";

</style>
<!--<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>-->