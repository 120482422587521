import axios from "axios";
import { getRequestData } from "./utils";

const instance = axios.create({});
instance.defaults.withCredentials = false;
export default function backendApi(api, data, headers = {}, method = "post") {
  const request = getRequestData(api, data);
  //instance.defaults.timeout =10000;
  const body = request.body;
  let url = request.url;
  const baseURL = request.baseURL;
  instance.defaults.baseURL = baseURL;
  //console.log('method: ', method)

  // if (method == "get") {
    //console.log('get condition')
    let i = 1;
    for (const key in data) {
      url = url.replace("param" + i, data[key]);
      i++;
    }
    let token = sessionStorage.getItem("authtoken");
    console.log(token);
    headers['Authorization'] = `Bearer ${token}`;
    console.log(headers);
    // Add Get Headers
    instance.defaults.headers.common['Authorization'] = headers['Authorization'];
  // }

  return instance[method](url, body, { headers: headers })
    .then(response => {
      //console.log(api, "SUCCESS", response);
      return response;
    })
    .catch(error => {
      console.log(api, "ERROR ee", error.response);
      let data;
      if(error.response) {
        data = {
          "status": 'error',
          "data": error.response.data
        }
        
      } else {
        data = {
          "status": 'error'
        }
      }

      return data;
      //return error.response;
      
    });
}
