<template>
  <div>
    <div class="container">
        <div class="success-img-holder text-center mt-5 mb-3">
          <figure>
            <img src="~@/assets/images/payment_scuccessful.png" alt="" />
          </figure>
          <figcaption class="mb-4">
            <!-- <h1>Payment Successful!</h1> -->
            <span>Your proposal is submitted successfully to insurance company</span><br>
            <span>HDFC LIFE will send payment link to customer</span>
          </figcaption>
        </div>
    </div>
  </div>
</template>


<script>
export default {
  name: "paymnt",
  props: {  },
  components: {},
  data() {
    return {
    };
  },
  mounted() {
    // this.paymentResponse();
    //this.getPayment()
  
  },
  methods: {
    
  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/scss/components/paymentsuccess.scss";
// @import "~@/assets/scss/components/paymentfailed.scss";
</style>